const REQUIRED_MESSAGE = 'Required'

export default ({ role, ...values }) => {
  const errors = {}
  if (!values.name) {
    errors.name = REQUIRED_MESSAGE
  }
  if (!values.phone_number) {
    errors.phone_number = REQUIRED_MESSAGE
  }
  if (!values.email) {
    errors.email = REQUIRED_MESSAGE
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (role === 'employer') {
    if (!values.address) {
      errors.address = REQUIRED_MESSAGE
    }
    if (!values.company_name) {
      errors.company_name = REQUIRED_MESSAGE
    }
    if (!values.post_code) {
      errors.post_code = REQUIRED_MESSAGE
    }
  }

  return errors
}
