import _ from 'lodash'

const equalError = 'Values must be equal'
const blankFieldError = 'Field cannot be blank'
const passwordWrongLength = 'From 6 to 72 symbols, at least 1 uppercase and 1 lowercase letter, at least 1 number'

export const isEmailEqual = (email, confirm) => {
  return email === confirm
}
export const isPasswordsEqual = (password, confirm) => {
  return password === confirm
}
export const isPasswordLengthCorrect = (password) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,72}$/
  return re.test(password)
}

export default (values, meta) => {
  const initialEmail = meta.initialValues && meta.initialValues.email ? meta.initialValues.email : null
  const { email, reEmail, password, password_confirmation } = values
  const errors = {}

  const emailWasChanged = initialEmail === email

  const trimmedEmail = _.trim(email)

  const trimmedReEmail = _.trim(reEmail)

  const trimmedPasswordConfirm = _.trim(password_confirmation)

  const trimmedPassword = _.trim(password)

  if (!isEmailEqual(trimmedEmail, trimmedReEmail) && !emailWasChanged) {
    errors.reEmail = equalError
  }

  if (!trimmedPasswordConfirm) {
    errors.password_confirmation = blankFieldError
  } else if (!isPasswordLengthCorrect(trimmedPasswordConfirm)) {
    errors.password_confirmation = passwordWrongLength
  } else if (!isPasswordsEqual(password, password_confirmation)) {
    errors.password_confirmation = equalError
  }

  if (!trimmedPassword) {
    errors.password = blankFieldError
  } else if (!isPasswordLengthCorrect(trimmedPassword)) {
    errors.password = passwordWrongLength
  }

  return errors
}
