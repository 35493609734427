/* eslint-disable */
//import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { any, func, bool, objectOf, string } from 'prop-types';

import { ROUTE_TO_EMPLOYERS, ROUTE_TO_ROOT } from '../../../constants/routes';
import { getEmployerByIdAction } from '../actions';
import BreadCrumbs from '../../../components/BreadCrumbs';
import ProfileMenu from '../../../components/ProfileMenu';
import StaticPage from '../../../components/StaticPage';
import SiteHeader from '../../../components/SiteHeader';
import navData from '../../../constants/navData';

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Employers',
    link: ROUTE_TO_EMPLOYERS
  }
];

const Header = ({ role, currentUser }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role}/>
      <ProfileMenu />
    </SiteHeader>
  );
};

Header.propTypes = {
  role: string
};

const EmployerSingle = ({ employer, inProgress, getEmployer, role, match, currentUser }) => {
  const { id } = match.params;
  useEffect(() => {
    getEmployer(id);
  }, []);
  if(!employer.attributes){ return false }
  const {
    company_name,
    address,
    reference_id,
    post_code,
    name,
    phone_number,
    email,
    created_at
  } = employer.attributes;


  return (
    <StaticPage sidebarData={navData[role]} header={<Header role='Employer' currentUser={ currentUser }/>}>
      <div className='customer__page pToppBottom'>
        <section className='customer__page--section section__customer section__border customer'>
          <div className='formDefault fullWidth viewForm'>
            <div className='formDefault__header clearfix'>
              <h1 className='formDefault__header--title'>Employer View</h1>
            </div>
            {!inProgress ? (
              <div class="formDefault__body">
                <div class="formDefault__body--columns clearfix">
                  <div class="formDefault__body--column column2">
                    <div class="formDefault__title">Company  info</div>
                    <div class="formDefault__bg">
                      <div class="formDefault__row">
                        <label class="formDefault__label">Company name</label>
                        <div class="formDefault__text">{company_name || '-'}</div>
                      </div>
                      <div class="formDefault__row">
                        <label class="formDefault__label">Address</label>
                        <div class="formDefault__text">{address || '-'}</div>
                      </div>
                      <div class="formDefault__row">
                        <label class="formDefault__label">Postcode</label>
                        <div class="formDefault__text">{post_code || '-'}</div>
                      </div>
                      <div class="formDefault__row">
                        <label class="formDefault__label">Prefix Reference</label>
                        <div class="formDefault__text">{reference_id || '-'}</div>
                      </div>
                    </div>
                  </div>
                  <div class="formDefault__body--column column2">
                    <div class="formDefault__title">Contact  info</div>
                    <div class="formDefault__bg">
                      <div class="formDefault__row">
                        <label class="formDefault__label">Contact Name</label>
                        <div class="formDefault__text">{name || '-'}</div>
                      </div>
                      <div class="formDefault__row">
                        <label class="formDefault__label">Contact Phone Number</label>
                        <div class="formDefault__text">{phone_number || '-'}</div>
                      </div>
                      <div class="formDefault__row">
                        <label class="formDefault__label">Contact Email</label>
                        <div class="formDefault__text">{email || '-'}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          </div>
          {/* <div className='customer__page--created'>
            <strong>Created</strong> At {moment(created_at).format('DD/MM/YYYY')}
          </div> */}
        </section>
      </div>
    </StaticPage>
  );
};

EmployerSingle.propTypes = {
  employer: objectOf(any),
  currentUser: objectOf(any),
  inProgress: bool,
  role: string,
  getEmployer: func.isRequired,
  match: objectOf(any)
};

const mapStateToProps = ({ employers, auth }) => {
  return {
    employer: employers.employer,
    inProgress: employers.inProgress,
    role: auth.user && auth.user.role,
    currentUser: auth.user
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEmployer: (id) => dispatch(getEmployerByIdAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerSingle);
