import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { any, arrayOf, bool, func, object, objectOf, string } from 'prop-types'

import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_JOBS } from '../../../constants/routes'
import TimePicker from '../../../components/TimePicker'
import DatePicker from '../../../components/DatePicker'
import fieldTrimNormalizer from '../../../utils/fieldTrimNormalizer';

const JobFormEmployer = ({
  handleSubmit,
  onSubmit,
  submitting,
  error,
  customers,
  engineers,
  initialValues,
  getCustomerDetails,
  getEngineers,
  getCustomers,
  onDelete,
  isEdit
}) => {
  const [engineersList, setEngineersList] = useState([])
  const [customersList, setCustomersList] = useState([])
  const [showCustomerDetails, setShowCustomerDetails] = useState(false)

  useEffect(() => {
    getEngineers()
    getCustomers()
  }, [])

  useEffect(() => {
    if (engineers && engineers.length) {
      setEngineersList(engineers.map((item) => ({ id: item.id, name: item.attributes.name })))
    }
  }, [engineers])

  useEffect(() => {
    if (customers && customers.length) {
      setCustomersList(customers.map((item) => ({ id: item.id, name: item.attributes.full_name })))
    }
  }, [customers])

  const customerDetailsHandler = (id) => {
    getCustomerDetails(id)
    setShowCustomerDetails(true)
  }

  return (
    <form className='formDefault fullWidth' onSubmit={handleSubmit(onSubmit)}>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>{isEdit ? 'Edit Job' : 'Create Job'}</h1>
        <div className='formDefault__header--btns'>
          {isEdit ? (
            <button
              className='btns btn-i btn-delete'
              data-toggle='class'
              data-target='#popups'
              data-classes='userdelete'
              type='button'
              onClick={onDelete}>
              Delete this Job
            </button>
          ) : null}
        </div>
      </div>
      <div className='formDefault__body'>
        <div className='formDefault__body--columns clearfix'>
          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Customer Info</div>
            <div className='formDefault__row custom-select '>
              {isEdit ? (
                <>
                  <label className='form__label formDefault__label not-allowed'>
                    Full Customer Name (Private or Company)
                  </label>
                  <div className='formDefault__input not-allowed'>{initialValues.full_name}</div>
                </>
              ) : (
                <Field
                  component={ReduxFormField}
                  label='Full Customer Name (Private or Company)'
                  type='select'
                  className='formDefault__select'
                  name='customer_id'
                  errorNoSpace
                  onChangeHandler={customerDetailsHandler}>
                  <option disabled value=''>
                    Select Name
                  </option>
                  {customersList.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              )}

            </div>
            {showCustomerDetails || isEdit ? (
              <div className='not-allowed'>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Contact Name</label>
                  <div className='formDefault__input'>{initialValues.contact_name}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Contact Phone Number</label>
                  <div className='formDefault__input'>{initialValues.contact_phone_number}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Customer Email</label>
                  <div className='formDefault__input'>{initialValues.contact_email}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Customer Postcode</label>
                  <div className='formDefault__input'>{initialValues.post_code}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Customer Address</label>
                  <div className='formDefault__input'>{initialValues.address}</div>
                </div>
              </div>
            ) : null}
          </div>
          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Job Info</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Job Reference Number'
                className='formDefault__input'
                type='text'
                name='job_reference'
                id='engineer_job_reference'
                normalize={fieldTrimNormalizer}
                placeholder='Job Reference Number'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Job Description'
                className='formDefault__textarea'
                type='textarea'
                name='description'
                id='engineer_description'
                normalize={fieldTrimNormalizer}
                placeholder='Job Description'
                errorNoSpace
              />
            </div>
          </div>
          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>&nbsp;</div>

            <div className='formDefault__row custom-select'>
              <label className='formDefault__label'>Assign to Engineer</label>
              <Field component='select' className='formDefault__select' name='engineer' errorNoSpace>
                <option value=''>Own job</option>
                {engineersList.map(({ id, name }) => {
                  return (
                    <option key={id} value={`${id}`}>
                      {name}
                    </option>
                  )
                })}
              </Field>
            </div>
            <div className='formDefault__title' />
            <div className='formDefault__row'>
              <Field
                component={DatePicker}
                label='Job Date'
                className='formDefault__input'
                defaultValue={initialValues.start_date}
                name='start_date'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={TimePicker}
                label='Job Time'
                className='formDefault__input time'
                defaultValue={initialValues.start_time}
                name='start_time'
                id='timepicker'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row custom-select'>
              <label className='formDefault__label'>Job STATUS</label>
              <Field component='select' className='formDefault__select' name='is_completed' errorNoSpace>
                <option disabled='disabled'>Select Status</option>
                <option value={false}>IN PROGRESS</option>
                <option value>COMPLETED</option>
              </Field>
            </div>
          </div>
        </div>
        {error ? <p className='validation-error-message'>{error}</p> : null}
        <div className='formDefault__row--btns'>
          <button
            className={`btns btn-i btn-yellow ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Save'}
          </button>
          <Link className='btns btn-i btn-trns' to={ROUTE_TO_JOBS}>
            Cancel
          </Link>
        </div>
      </div>
    </form>
  )
}

JobFormEmployer.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  onDelete: func.isRequired,
  getCustomerDetails: func.isRequired,
  getCustomers: func.isRequired,
  getEngineers: func.isRequired,
  submitting: bool.isRequired,
  isEdit: bool.isRequired,
  error: string,
  initialValues: objectOf(any),
  customers: arrayOf(object).isRequired,
  engineers: arrayOf(object)
}

export default JobFormEmployer
