import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { string, number, bool, func, arrayOf, object } from 'prop-types'
import Popup from 'reactjs-popup'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import {
  ROUTE_TO_ROOT,
  routeToAdminSingle
} from '../../../constants/routes'
import {
  errorNotificationAction,
  successNotificationAction
} from '../../Notifications/actions'
import HeaderSearch from '../../../components/HeaderSearch'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import tableHeaders from '../../../utils/tableHeaders'
import navData from '../../../constants/navData'
import { getAdminsAction, createAdminAction } from '../actions'
import Table from '../../../components/Table'
// import history from '../../../history'
import AddAdminForm from '../AddForm'
import validate from '../AddForm/validate'
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const Header = ({ onSearch }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current='Admins' />
      <HeaderSearch onSubmit={onSearch} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  onSearch: func.isRequired
}

const Admins = ({
  role,
  admins,
  inProcess,
  getAdmins,
  createAdmin,
  count,
  handleSubmit,
  error,
  errorNotification,
  successNotification
}) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [addAdminPopupShow, setAddAdminPopupShow] = useState(false)

  useEffect(() => {
    getAdmins({ page, per_page: perPage })
  }, [page, perPage])

  const addAdminHandler = async (values) => {
    console.log('addAdminHandler -> values', values)
    const res = await createAdmin(values)
    if (res.value.status === 200) {
      successNotification('Admin was added.')
      getAdmins({ page, per_page: perPage })
      setAddAdminPopupShow(false)
    } else {
      errorNotification('Something went wrong')
    }
  }

  // const onRowClickHandler = ({ id }) => history.push(routeToAdminSingle(id))
  const onRowClickHandler = ({ id }) => navigate(routeToAdminSingle(id))

  return (
    <StaticPage sidebarData={navData[role]} header={<Header onSearch={getAdmins} />}>
      <div className='customers__page pTop'>
        <div className='page__header clearfix'>
          <div className='page__btns'>
            <button button type='button' onClick={() => setAddAdminPopupShow(true)} className='btns btn-i btn-yellow'>
              ADD NEW Admin
            </button>
            <Popup open={addAdminPopupShow} onClose={() => setAddAdminPopupShow(false)} modal closeOnDocumentClick>
              <AddAdminForm
                title='Add new Admin'
                handleSubmit={handleSubmit}
                onSubmit={addAdminHandler}
                error={error}
              />
            </Popup>
          </div>
          <h1 className='page__title'>Admins</h1>
        </div>
      </div>
      <Table
        onChangePage={setPage}
        per_page={perPage}
        page={page}
        changePerPageHandler={setPerPage}
        data={admins}
        columns={tableHeaders.admins}
        inProcess={inProcess}
        count={count}
        onClickHandler={onRowClickHandler}
      />
    </StaticPage>
  )
}

Admins.propTypes = {
  role: string,
  count: number.isRequired,
  inProcess: bool,
  admins: arrayOf(object),
  getAdmins: func.isRequired,
  createAdmin: func.isRequired,
  handleSubmit: func.isRequired,
  errorNotification: func,
  successNotification: func,
  error: string
}

const mapStateToProps = ({ auth, admins }) => {
  const data = {
    admins: admins.admins,
    count: admins.count,
    inProcess: admins.inProcess
  }

  const { user } = auth
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getAdmins: (params) => dispatch(getAdminsAction(params)),
  createAdmin: (data) => dispatch(createAdminAction(data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'createAdminForm',
    enableReinitialize: true,
    validate
  })
)(Admins)
