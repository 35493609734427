import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import React, { useEffect } from 'react'
import { objectOf, bool, any, func, string, arrayOf } from 'prop-types';

import { errorNotificationAction, successNotificationAction } from '../../Notifications/actions'
import { createCustomerAction, getCustomerByIdAction, updateCustomerAction } from '../actions'
import { ROUTE_TO_CUSTOMERS, ROUTE_TO_ROOT } from '../../../constants/routes'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import Spinner from '../../../components/Spinner'
import navData from '../../../constants/navData'
import CustomerForm from './CustomerForm'
import history from '../../../history'
import validate from './validate'
import { getEmployersAction } from '../../Employers/actions'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Customers',
    link: ROUTE_TO_CUSTOMERS
  }
]

const Header = ({ customer }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={customer} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  customer: string.isRequired
}
// One component for create and edit customer
const CustomerUpdate = ({
  match,
  getCustomer,
  inProcess,
  successNotification,
  errorNotification,
  role,
  createCustomer,
  updateCustomer,
  updateInProcess,
  initialValues,
  getEmployers,
  employers,
  ...formProps
}) => {
  const { id } = match.params

  useEffect(() => {
    getCustomer(id)
    getEmployers()
  }, [])

  const onSubmit = async ({ id: customerId, employer, created_at, updated_at, ...values }) => {
    const data = { customer: { ...values, user_id: employer } }
    // eslint-disable-next-line no-unused-expressions
    customerId ? await updateCustomer(customerId, data) : await createCustomer(data)
    successNotification(`Customer successfully ${customerId ? 'updated' : 'created'}`)
    history.push(ROUTE_TO_CUSTOMERS)
  }

  return (
    <StaticPage sidebarData={navData[role]} header={<Header customer={id ? initialValues.contact_name : 'Add New'} />}>
      <div className='customer__page pToppBottom'>
        <section className='customer__page--section section__customer section__border customer'>
          {inProcess ? (
            <Spinner />
          ) : (
            <div>
              <CustomerForm
                onSubmit={onSubmit}
                {...formProps}
                initialValues={initialValues}
                isEdit={!!id}
                employers={employers}
                role={role}
              />
              {initialValues.created_at ? (
                <div className='customer__page--created'>
                  <strong>Created</strong> At {moment(initialValues.created_at).format('DD/MM/YYYY')}
                </div>
              ) : null}
            </div>
          )}
        </section>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({ customers, auth, employers: { employers } }) => ({
  initialValues: customers.customer,
  inProcess: customers.inProcess,
  employers: employers.data || [],
  updateInProcess: customers.updateInProcess,
  role: auth.user && auth.user.role
})

const mapDispatchToProps = (dispatch) => ({
  getCustomer: (id) => dispatch(getCustomerByIdAction(id)),
  getEmployers: (params) => dispatch(getEmployersAction(params)),
  createCustomer: (data) => dispatch(createCustomerAction(data)),
  updateCustomer: (id, data) => dispatch(updateCustomerAction(id, data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message))
})

CustomerUpdate.propTypes = {
  match: objectOf(any),
  initialValues: objectOf(any),
  employers: arrayOf(any),
  getCustomer: func.isRequired,
  getEmployers: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  inProcess: bool.isRequired,
  role: string,
  createCustomer: func,
  updateCustomer: func,
  updateInProcess: bool
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'customer_update',
    shouldError: () => true,
    enableReinitialize: true,
    validate
  })
)(CustomerUpdate)
