import React from 'react'
import 'rc-time-picker/assets/index.css'
import TimePickerLib from 'rc-time-picker'
import moment from 'moment'
import { any, bool, objectOf, shape, string } from 'prop-types'

const TimePicker = ({ input, className, label, id, errorNoSpace, meta: { touched, error }, defaultValue }) => {
  return (
    <div>
      <label className='form__label formDefault__label' htmlFor={id}>
        {label}
      </label>
      <div className='form-field-wrapper'>
        <TimePickerLib
          className={className}
          showSecond={false}
          minuteStep={15}
          use12Hours={false}
          defaultValue={defaultValue && moment(defaultValue, 'HH:mm')}
          inputIcon={<div className='rc-time-picker-input-icon' />}
          onChange={(time) => input.onChange(time.format('LT'))}
        />
        <p className={`validation-error-message ${touched && error ? '' : `${errorNoSpace ? 'hidden' : 'invisible'}`}`}>
          {error}
        </p>
      </div>
    </div>
  )
}

TimePicker.propTypes = {
  input: objectOf(any).isRequired,
  label: string.isRequired,
  id: string,
  defaultValue: string,
  className: string,
  errorNoSpace: bool,
  meta: shape({
    touched: bool,
    error: string,
    warning: string
  })
}

export default TimePicker
