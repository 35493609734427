import React from 'react'
import { Link } from 'react-router-dom'
import { arrayOf, object, string } from 'prop-types'

const BreadCrumbs = ({ current, data }) => {
  return (
    <div className='header__main--breadcrumbs'>
      {data.map(({ title, link }) => (
        <Link key={title} className='breadcrumbs__link' to={link}>
          {current ? `${title} / ` : title}
        </Link>
      ))}
      <span className='breadcrumbs__current'>{current}</span>
    </div>
  )
}

BreadCrumbs.propTypes = {
  current: string,
  data: arrayOf(object)
}

export default BreadCrumbs
