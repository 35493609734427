const REQUIRED_MESSAGE = 'Required'

export default (values) => {
  const errors = {}
  if (!values.customer_id) {
    errors.customer_id = REQUIRED_MESSAGE
  }
  if (!values.survey_reference?.trim()) {
    errors.survey_reference = REQUIRED_MESSAGE
  }
  if (!values.description?.trim()) {
    errors.description = REQUIRED_MESSAGE
  }
  if (!values.start_date) {
    errors.start_date = REQUIRED_MESSAGE
  }
  if (!values.start_time) {
    errors.start_time = REQUIRED_MESSAGE
  }
  return errors
}
