import _ from 'lodash'

const blankFieldError = 'Field cannot be blank'
const incorrectEmailError = 'Email is incorrect'

export const isEmailCorrect = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export default ({ forgot_email }) => {
  const errors = {}

  const trimmedEmail = _.trim(forgot_email)
  if (!trimmedEmail) {
    errors.forgot_email = blankFieldError
  } else if (!isEmailCorrect(trimmedEmail)) {
    errors.forgot_email = incorrectEmailError
  }

  return errors
}
