import React from 'react'
import { Field } from 'redux-form'
import { func } from 'prop-types'
import ReduxFormField from '../ReduxFormField'

const EngineerInfoForm = ({ handleSubmit, error }) => {
  return (
    <form onSubmit={handleSubmit} className='formDefault'>
      <div className='formDefault__body'>
        <div className='col-half'>
          <div className='formDefault__header clearfix'>
            <h1 className='formDefault__header--title'>My Profile</h1>
          </div>
          <div className='formDefault__body--column column2'>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='name'
                id='name'
                placeholder='Contact Name'
                label='Contact Name'
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='phone_number'
                id='phone_number'
                placeholder='Phone Number'
                label='Phone Number'
              />
            </div>
          </div>
        </div>

        <div className='col-half'>
          <div className='formDefault__header clearfix'>
            <h1 className='formDefault__header--title'>Change email</h1>
          </div>
          <div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='email'
                id='email'
                label='Email'
                placeholder='Email'
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='reEmail'
                id='reEmail'
                label='Repeat Email'
                placeholder='Repeat Email'
              />
            </div>
          </div>
        </div>
      </div>
      <p className='validation-error-message'>{error}</p>
      <div className='formDefault__row--btns'>
        <input className='btns btn-i btn-yellow' type='submit' name='submit' value='SAVE CHANGES' />
        <input className='btns btn-i btn-trns' type='reset' name='cencel' value='Cancel' />
      </div>
    </form>
  )
}

EngineerInfoForm.propTypes = {
  handleSubmit: func.isRequired
  // error: string
}

export default EngineerInfoForm
