import { createAction } from 'redux-actions'

import {
  GET_SURVEYS,
  GET_SURVEY_BY_ID,
  DELETE_SURVEY_BY_ID,
  CREATE_SURVEY,
  UPDATE_SURVEY
} from './constants'

import {
  createSurveyApi,
  deleteSurveyByIdApi,
  getSurveyByIdApi,
  getSurveysApi,
  updateSurveyApi
} from './api'

export const getSurveysAction = createAction(GET_SURVEYS, async (params) => getSurveysApi(params))
export const getSurveyByIdAction = createAction(GET_SURVEY_BY_ID, async (id) => getSurveyByIdApi(id))
export const deleteSurveyByIdAction = createAction(DELETE_SURVEY_BY_ID, async (id) => deleteSurveyByIdApi(id))
export const createSurveyAction = createAction(CREATE_SURVEY, async (data) => createSurveyApi(data))

export const updateSurveyAction = createAction(
  UPDATE_SURVEY,
  async (id, data) => {
    const response = await updateSurveyApi(id, data)
    return response
  }
)
