import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { ROUTE_TO_SURVEYS } from '../../../constants/routes'
import ReduxFormField from '../../../components/ReduxFormField'
import fieldTrimNormalizer from '../../../utils/fieldTrimNormalizer'
import validate from '../Update/validate'
import epcData from '../../../constants/epcData'
import { updateSurveyAction } from '../actions'
import {
  errorNotificationAction,
  successNotificationAction
} from '../../Notifications/actions'
import { useNavigate } from 'react-router-dom-v5-compat'

const UpdateEPCForm = ({ survey, epcId, epcMetrics }) => {
  console.log("Update EPC Form")
  const navigate = useNavigate()

  const generateFormData = (event, epcMetrics) => {
    const formData = new FormData()
    formData.append('survey[user_id]', survey.user_id)
    formData.append('epc[epc_id]', epcId)

    // TODO: Nested block i.e. epcMetrics && epcMetrics is redundant
    {epcMetrics && epcMetrics.map((metric, index) => {
      formData.append('epc[data][]', JSON.stringify({
        'question_id': metric['question_id'],
        'answer': event.target[index].value
      }))
      return formData
    })}
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = generateFormData(event, epcMetrics)

    if (data && survey.id) {
      const response = await updateSurveyAction(survey.id, data)
      if (response.status === 200)
        successNotificationAction(`EPC successfully ${survey.id} updated`)
      else
        errorNotificationAction('Something went wrong')
    }

    navigate(ROUTE_TO_SURVEYS)
  }

  const renderEPCMetricOptions = (metric) => {
   return (
    epcData.metricData.map((metricData, index)=> {
      if(metricData.question_id === metric.question_id) {
        return (
          metricData?.options?.map((option, index)=> {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            )
          })
        )
      } else {
        return (
          <option key={index} value='' />
        )
      }

    }))
  }

  return (
    <form className="formDefault fullwidth" onSubmit={handleSubmit}>
      <div className='formDefault__body--column clearfix'>
        <div className='formDefault__title'>EPC Details</div>
        <div className='formDefault__row custom-select'>
          {epcMetrics && epcMetrics.map((metric, index) => {
            if(metric["type"] === '1') {
              return (
                <Field
                  key={index}
                  component={ReduxFormField}
                  label={metric["name"]}
                  className='formDefault__input'
                  type='text'
                  name={metric["name"]}
                  normalize={fieldTrimNormalizer}
                  placeholder={metric["answer"]}
                  errorNoSpace
                />
              )
            } else if (metric["type"] === '2') {
                return (
                  <Field
                    key={index}
                    component={ReduxFormField}
                    label={metric["name"]}
                    type='select'
                    className='formDefault__select'
                    name={metric["name"]}
                    errorNoSpace>
                    <option key={index} value={metric["answer"]}>{metric["answer"]}</option>
                      {renderEPCMetricOptions(metric)}
                  </Field>
                )
            } else if (metric["type"] === '3') {
              return (
                <Field
                  key={index}
                  component={ReduxFormField}
                  label={metric["name"]}
                  className='formDefault__input'
                  type='text'
                  name={metric["name"]}
                  normalize={fieldTrimNormalizer}
                  placeholder={metric["answer"]}
                  errorNoSpace
                  input={{ disabled: true, }}
                />
              )
            } else if (metric["type"] === '4') {
              return (
                <Field
                  key={index}
                  component={ReduxFormField}
                  label={metric["name"]}
                  className='formDefault__input'
                  type='textarea'
                  name={metric["name"]}
                  normalize={fieldTrimNormalizer}
                  placeholder={metric["answer"]}
                  errorNoSpace
                />
              )
            } else {
              return (
                <Field />
              )
            }
          })}
          <button
            type="submit"
            className="btns btn-b btn-yellow pdf-upload-btn">
            Update
          </button>
          <Link className='btns btn-i btn-trns' to={ROUTE_TO_SURVEYS}>
            Cancel
          </Link>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = (dispatch) => ({})

const mapDispatchToProps = (dispatch) => ({
  updateSurvey: (id, data) => dispatch(updateSurveyAction(id, data))
})

UpdateEPCForm.propTypes = {
  updateSurvey: func
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'epc_update',
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate
  })
)(UpdateEPCForm)
