/* eslint-disable */
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup'
import { any, func, bool, objectOf, string } from 'prop-types';

import { ROUTE_TO_ROOT, ROUTE_TO_SURVEYS, routeToSurveyUpdate } from '../../../constants/routes';
import { getSurveyByIdAction, deleteSurveyByIdAction } from '../actions';
import BreadCrumbs from '../../../components/BreadCrumbs';
import ProfileMenu from '../../../components/ProfileMenu';
import StaticPage from '../../../components/StaticPage';
import SiteHeader from '../../../components/SiteHeader';
import navData from '../../../constants/navData';
import AuditItem from './AuditItem';
import TreatmentPlan from './TreatmentPlan';
import InviteForm from '../../Engineers/InviteForm';
import config from '../../../config';
import DeletePopup from '../../../components/DeletePopup';
import WaterTestUpload from './WaterTestUpload';
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Surveys',
    link: ROUTE_TO_SURVEYS
  }
];

const Header = ({ role }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role}/>
      <ProfileMenu />
    </SiteHeader>
  );
};

Header.propTypes = {
  role: string
};

const SurveyDetails = ({ survey, inProgress, deleteInProgress, getSurvey, deleteSurvey, role, match }) => {
  const navigate = useNavigate()
  const [planPopupShow, setPlanPopupShow] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const closePlanPopupHandler = () => setPlanPopupShow(false)

  const { id } = match.params;
  useEffect(() => {
    getSurvey(id);
  }, []);

  const onDeleteHandler = async () => {
    try {
      await deleteSurvey(id);
      navigate(ROUTE_TO_SURVEYS);
    } catch (e) {
      console.log(e);
    }
  };

  if (!survey.id) return (<div/>)

  const {
    customer,
    description,
    survey_reference,
    is_completed,
    is_reports,
    start_date,
    start_time,
    created_data,
    engineer,
    employer,
    audits,
    pdf_url,
    treatment_plan
  } = survey;

  const {
    full_name,
    contact_name,
    contact_phone_number,
    contact_email,
    post_code,
    reference_id,
    address
  } = customer || "";

  return (
    <div className="formDefault fullWidth viewForm">
      <div className="formDefault__body">
        <div className="survey__page--audits">
          <div className="audits__list">
            <div className='formDefault__header clearfix'>
              { survey && survey.is_reports ?
                  (
                    <h1 className="formDefault__header--title">
                      Water Test Evaluation
                    </h1>
                  )
                :
                  (
                    <h1 className="formDefault__header--title">
                      Needs to perform Water Test Evaluation!!!
                    </h1>
                  )
              }
            </div>

            <WaterTestUpload survey={survey} />
          </div>
        </div>
      </div>
    </div>
  );
};

SurveyDetails.propTypes = {
  survey: objectOf(any),
  inProgress: bool,
  deleteInProgress: bool,
  role: string,
  getSurvey: func.isRequired,
  deleteSurvey: func.isRequired,
  match: objectOf(any)
};

const mapStateToProps = ({ surveys, auth }) => {
  return {
    survey: surveys.survey,
    inProgress: surveys.inProgress,
    deleteInProgress: surveys.deleteInProgress,
    role: auth.user && auth.user.role
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSurvey: (id) => dispatch(getSurveyByIdAction(id)),
  deleteSurvey: (id) => dispatch(deleteSurveyByIdAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetails);
