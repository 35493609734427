import { createAction } from 'redux-actions'

import { DELETE_JOB_BY_ID, GET_JOB_BY_ID, GET_JOBS, CREATE_JOB, UPDATE_JOB } from './constants'
import { getJobsApi, deleteJobByIdApi, getJobByIdApi, createJobApi, updateJobApi } from './api'

export const getJobsAction = createAction(GET_JOBS, async (params) => getJobsApi(params))
export const getJobByIdAction = createAction(GET_JOB_BY_ID, async (id) => getJobByIdApi(id))
export const deleteJobByIdAction = createAction(DELETE_JOB_BY_ID, async (id) => deleteJobByIdApi(id))
export const createJobAction = createAction(CREATE_JOB, async (data) => createJobApi(data))
export const updateJobAction = createAction(UPDATE_JOB, async (id, data) => updateJobApi(id, data))
