import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { string, bool, func, arrayOf, object } from 'prop-types'
import { compose } from 'redux'
import Popup from 'reactjs-popup'
import { reduxForm } from 'redux-form'
import { ROUTE_TO_ROOT, routeToEmployerSingle } from '../../../constants/routes'
import HeaderSearch from '../../../components/HeaderSearch'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import tableHeaders from '../../../utils/tableHeaders'
import navData from '../../../constants/navData'
import { getEmployersAction, addEmployerAction } from '../actions'
import Table from '../../../components/Table'
import AddForm from '../AddForm'
// import history from '../../../history'
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const Header = ({ role, onSearch }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role} />
      <HeaderSearch onSubmit={onSearch} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  role: string,
  onSearch: func.isRequired
}

const Employers = ({
  role,
  employers,
  inProcess,
  getEmployers,
  user,
  handleSubmit,
  notificationData,
  addEmployer,
  error
}) => {
  const navigate = useNavigate()
  const { successNotification, errorNotification } = notificationData

  const [employerPopupShow, setEmployerPopupShow] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    getEmployers({ page, per_page: perPage })
  }, [page, perPage])

  const addEmployerHandler = async (values) => {
    const res = await addEmployer(values)
    if (res.value.status === 200) {
      successNotification('Employer was added.')
      getEmployers()
      setEmployerPopupShow(false)
    } else {
      errorNotification('Something went wrong')
    }
  }
  // const onRowClickHandler = ({ id }) => history.push(routeToEmployerSingle(id))
  const onRowClickHandler = ({ id }) => navigate(routeToEmployerSingle(id))

  return (
    <StaticPage sidebarData={navData[role]} header={<Header role={role} currentUser={user} onSearch={getEmployers} />}>
      <div className='customers__page pTop'>
        <div className='page__header clearfix'>
          <div className='page__btns'>
            <button type='button' className='btns btn-i btn-yellow' onClick={() => setEmployerPopupShow(true)}>
              ADD NEW Employer
            </button>
            <Popup open={employerPopupShow} modal closeOnDocumentClick onClose={() => setEmployerPopupShow(false)}>
              <AddForm
                handleSubmit={handleSubmit}
                onSubmit={addEmployerHandler}
                title='Add new Employer'
                subtitle='You have received an activation code in your email. Enter it to start working with a new employer.'
                error={error}
              />
            </Popup>
          </div>
          <h1 className='page__title'>Employers</h1>
        </div>
      </div>
      <Table
        data={employers}
        columns={tableHeaders.employers}
        inProcess={inProcess}
        onClickHandler={onRowClickHandler}
        onChangePage={setPage}
        count={employers ? employers.length : 0}
        per_page={perPage}
        page={page}
        changePerPageHandler={setPerPage}
      />
    </StaticPage>
  )
}

Employers.propTypes = {
  role: string,
  // eslint-disable-next-line react/forbid-prop-types
  user: object,
  inProcess: bool,
  employers: arrayOf(object),
  getEmployers: func.isRequired,
  addEmployer: func.isRequired,
  handleSubmit: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  notificationData: object,
  error: string
}

const mapStateToProps = ({ auth, employers }) => {
  const data = {
    employers: employers.employers.data,
    inProcess: employers.inProcess
  }

  const { user } = auth
  if (user) data.user = user
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getEmployers: (params) => dispatch(getEmployersAction(params)),
  addEmployer: (data) => dispatch(addEmployerAction(data))
})

// export default connect(mapStateToProps, mapDispatchToProps)(Employers)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'addEmployer',
    enableReinitialize: true
    // validate
  })
)(Employers)
