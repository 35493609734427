import { createAction } from 'redux-actions'
import {
  CREATE_ENGENEER,
  DELETE_ENGINEER_BY_ID,
  GET_ENGENEERS,
  GET_ENGINEER_BY_ID,
  UPDATE_ENGINEER
} from './constants'
import {
  getEngeneersApi,
  deleteEngineerByIdApi,
  createEngineerApi,
  getEngineerByIdApi,
  updateEngineerApi
} from './api'

export const getEngeneersAction = createAction(
  GET_ENGENEERS,
  async (params) => getEngeneersApi(params)
)

export const getEngineerByIdAction = createAction(
  GET_ENGINEER_BY_ID,
  async (id) => getEngineerByIdApi(id)
)

export const deleteEngineerByIdAction = createAction(
  DELETE_ENGINEER_BY_ID,
  async (params) => deleteEngineerByIdApi(params)
)

export const createEngineerAction = createAction(
  CREATE_ENGENEER,
  async (data) => createEngineerApi(data)
)

export const updateEngineerAction = createAction(
  UPDATE_ENGINEER,
  async (id, data) => {
    console.log("UPDATE ENGINEER ACTION", id, data)
    updateEngineerApi(id, data)
  })
