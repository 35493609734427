import React from 'react'
import { func, bool } from 'prop-types'
import { Field } from 'redux-form'
import ReduxFormField from '../../../../components/ReduxFormField'

const CreateAccountForm = ({ handleSubmit, onChangeWatcher, onSubmit, showReferenceField, error }) => {
  return (
    <>
      <div className='signin__page--title'>Create new Account</div>
      <form onSubmit={handleSubmit(onSubmit)} className='signin__page--form'>
        <div className='form__row row__input custom-select'>
          <label className='form__label' htmlFor='id'>
            Choose the role. Employer or Engineer
          </label>
          <Field component='select' name='role' id='role' className='select-selected' onChange={onChangeWatcher}>
            <option value='0'>Employer</option>
            <option value='1'>Engineer</option>
          </Field>
        </div>
        <div className='form__row row__input'>
          <Field
            component={ReduxFormField}
            className='form__input'
            type='text'
            name='email'
            id='email'
            placeholder='Email'
            label='Email'
          />
        </div>
        <div className='form__row row__input'>
          <Field
            component={ReduxFormField}
            className='form__input'
            type='password'
            name='password'
            id='password'
            placeholder='************'
            label='*Password'
          />
        </div>
        <div className='form__row row__input'>
          <Field
            component={ReduxFormField}
            className='form__input'
            type='Password'
            name='password_confirmation'
            id='password_confirmation'
            placeholder='************'
            label='*Confirm Password'
          />
        </div>
        {showReferenceField ? (
          <div className='form__row row__input'>
            <Field
              component={ReduxFormField}
              className='form__input'
              type='text'
              name='reference_id'
              id='reference_id'
              placeholder='Reference ID'
              label='Reference ID'
            />
            <p className='form__hint'>
              Compose your unique id code to be used for reports identification. <br />
              Hint: use your company abbreviation.
            </p>
          </div>
        ) : null}
        <p className='validation-error-message'>
          {error
            ? Object.keys(error).map(function (key) {
                return (
                  <p className='mt-20' value={key}>
                    {error[key]}
                  </p>
                )
              })
            : null}
        </p>
        <div className='form__row row__submit'>
          <input className='form__button btns btn-i' type='submit' name='submit' value='Create a new Account' />
        </div>
      </form>
    </>
  )
}

CreateAccountForm.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  showReferenceField: bool,
  onChangeWatcher: func
}

export default CreateAccountForm
