import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as notifications } from 'react-notification-system-redux'

import customersReducer from './containers/Customers/reducer'
import authReducer from './containers/Auth/reducer'
import jobsReducer from './containers/Jobs/reducer'
import surveysReducer from './containers/Surveys/reducer'
import usersReducer from './containers/Users/reducer'
import engeneersReducer from './containers/Engineers/reducer'
import employersReducer from './containers/Employers/reducer'
import dashboardReducer from './containers/Dashboard/reducer'
import systemSettingsReducer from './containers/SystemSettings/reducer'
import adminsReducer from './containers/Admins/reducer'
import epcSurveysReducer from './containers/EPCSurveys/reducer'

export default combineReducers({
  auth: authReducer,
  customers: customersReducer,
  jobs: jobsReducer,
  epc_surveys: epcSurveysReducer,
  surveys: surveysReducer,
  users: usersReducer,
  form: formReducer,
  engeneers: engeneersReducer,
  employers: employersReducer,
  dashboard: dashboardReducer,
  systemSettings: systemSettingsReducer,
  admins: adminsReducer,
  notifications
})
