import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { string, func, objectOf, any } from 'prop-types'
import DashboardGrid from '../DashboardGrid'
import { getDashboardDataAdminAction } from '../actions'

const SuperAdminDashboard = ({ name, getData, dashboardData }) => {
  useEffect(() => {
    getData()
  }, [])

  const ownDashboard =
    dashboardData.dashboard && dashboardData.dashboard.data.attributes ? dashboardData.dashboard.data.attributes : null

  const employersDashboards =
    dashboardData.user_employers && dashboardData.user_employers.data ? dashboardData.user_employers.data : null

  return (
    <>
      <div className='page__header clearfix'>
        <h1 className='page__title'>
          <strong>Hello</strong>, Welcome Back {name}!
        </h1>
      </div>
      {ownDashboard ? <DashboardGrid data={ownDashboard} /> : null}
      {employersDashboards
        ? employersDashboards.map((item) => {
            return (
              <>
                <section className='section__overview--title'>Statistics of work with {item.attributes.name}</section>
                <DashboardGrid data={item.attributes} />
              </>
            )
          })
        : null}
    </>
  )
}

SuperAdminDashboard.propTypes = {
  name: string,
  getData: func.isRequired,
  dashboardData: objectOf(any)
}

const mapStateToProps = ({ auth, dashboard }) => {
  const data = {
    dashboardData: dashboard.statistic
  }

  const { user } = auth
  if (user) data.name = user.name
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getDashboardDataAdminAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminDashboard)
