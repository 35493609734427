import { createAction } from 'redux-actions'

import { GET_ADMINS, GET_ADMIN_BY_ID, DELETE_ADMIN_BY_ID, CREATE_ADMIN, UPDATE_ADMIN } from './constants'
import { getAdminsApi, getAdminByIdApi, deleteAdminByIdApi, createAdminApi, updateAdminApi } from './api'

export const getAdminsAction = createAction(GET_ADMINS, async (params) => getAdminsApi(params))
export const getAdminByIdAction = createAction(GET_ADMIN_BY_ID, async (id) => getAdminByIdApi(id))
export const deleteAdminByIdAction = createAction(DELETE_ADMIN_BY_ID, async (id) => deleteAdminByIdApi(id))
export const createAdminAction = createAction(CREATE_ADMIN, async (data) => createAdminApi(data))
export const updateAdminAction = createAction(UPDATE_ADMIN, async (id, data) => updateAdminApi(id, data))
