import typeToReducer from 'type-to-reducer'
import { DELETE_SURVEY_BY_ID, GET_SURVEY_BY_ID, GET_SURVEYS } from './constants'

export const initialState = {
  inProcess: false,
  deleteInProgress: false,
  updateInProgress: false,
  per_page: null,
  total_pages: null,
  count: 0,
  survey: {},
  surveys: [],
  errors: null,
  category: null
}

export default typeToReducer(
  {
    [GET_SURVEYS]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          surveys: action.payload.data.surveys.data,
          per_page: action.payload.data.per_page,
          total_pages: action.payload.data.total_pages,
          count: action.payload.data.count,
          inProcess: false,
          category : action.payload.data.category
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [GET_SURVEY_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          inProcess: false,
          survey: action.payload.data.survey && action.payload.data.survey.data.attributes,
          category: action.payload.data.category
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          survey: {},
          inProcess: false
        }
      }
    },
    [DELETE_SURVEY_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          deleteInProgress: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          deleteInProgress: false,
          category: action.payload.data.category
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          deleteInProgress: false
        }
      }
    }
  },
  initialState
)
