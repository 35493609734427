import typeToReducer from 'type-to-reducer'
import { ADD_EMPLOYER, GET_EMPLOYERS, GET_EMPLOYER_BY_ID } from './constants'

export const initialState = {
  inProcess: false,
  per_page: null,
  employers: [],
  employer: [],
  errors: null
}

export default typeToReducer(
  {
    [GET_EMPLOYERS]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          employers: action.payload.data.employers,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [GET_EMPLOYER_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          employer: action.payload.data.user.data,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [ADD_EMPLOYER]: {
      START: (state) => {
        return {
          ...state,
          updateInProgress: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          updateInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          updateInProgress: false
        }
      }
    }
  },
  initialState
)
