import _ from 'lodash'

const blankFieldError = 'Field cannot be blank'
const passwordWrongLength = 'From 6 to 72 symbols, at least 1 uppercase and 1 lowercase letter, at least 1 number'
const passwordConfirmMatch = 'Values must be equal'

export const isPasswordLengthCorrect = (password) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,72}$/
  return re.test(password)
}
export const isPasswordsEqual = (password, confirm) => {
  return password === confirm
}

export default (values) => {
  const { email, password, password_confirmation } = values
  const errors = {}

  const trimmedEmail = _.trim(email)
  if (!trimmedEmail) {
    errors.email = blankFieldError
  }

  const trimmedPasswordConfirm = _.trim(password_confirmation)
  const trimmedPassword = _.trim(password)

  if (!trimmedPasswordConfirm) {
    errors.password_confirmation = blankFieldError
  } else if (!isPasswordLengthCorrect(trimmedPasswordConfirm)) {
    errors.password_confirmation = passwordWrongLength
  } else if (!isPasswordsEqual(password, password_confirmation)) {
    errors.password_confirmation = passwordConfirmMatch
  }

  if (!trimmedPassword) {
    errors.password = blankFieldError
  } else if (!isPasswordLengthCorrect(trimmedPassword)) {
    errors.password = passwordWrongLength
  }

  return errors
}
