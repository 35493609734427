import { createAction } from 'redux-actions'

import {
  GET_STATISTIC,
  GET_STATISTIC_ADMIN,
  GET_STATISTIC_ADMIN_USER
} from './constants'

import {
  getDashboardDataApi,
  getDashboardDataAdminApi,
  getDashboardDataAdminUserApi
} from './api'

export const getDashboardDataAction = createAction(
  GET_STATISTIC, async () => getDashboardDataApi()
)

export const getDashboardDataAdminAction = createAction(
  GET_STATISTIC_ADMIN, async () => getDashboardDataAdminApi()
)

export const getDashboardDataAdminUserAction = createAction(
  GET_STATISTIC_ADMIN_USER, async (id) => getDashboardDataAdminUserApi(id)
)
