import React from 'react'
import { Field } from 'redux-form'
import { func } from 'prop-types'
import ReduxFormField from '../ReduxFormField'

const EmployerInfoForm = ({ handleSubmit, error }) => {
  return (
    <form onSubmit={handleSubmit} className='formDefault fullWidth'>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>My Profile</h1>
      </div>
      <div className='formDefault__body'>
        <div className='formDefault__body--columns clearfix'>
          <div className='formDefault__body--column col-half column2'>
            <div className='formDefault__title'>Contact Info</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='name'
                id='name'
                label='Contact Name'
                placeholder='Contact Name'
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='number'
                name='phone_number'
                id='phone_number'
                placeholder='Phone Number'
                label='Phone Number'
              />
            </div>
            <div>
              <div className='formDefault__row'>
                <Field
                  component={ReduxFormField}
                  className='formDefault__input'
                  type='text'
                  name='email'
                  id='email'
                  label='Email'
                  placeholder='Email'
                />
              </div>
              <div className='formDefault__row'>
                <Field
                  component={ReduxFormField}
                  className='formDefault__input'
                  type='text'
                  name='reEmail'
                  id='reEmail'
                  label='Repeat Email'
                  placeholder='Repeat Email'
                />
              </div>
            </div>
          </div>
          <div className='formDefault__body--column col-half column2'>
            <div className='formDefault__title'>Company Info</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='company_name'
                id='company_name'
                placeholder='Company Name'
                label='Company Name'
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='address'
                id='address'
                placeholder='Address'
                label='Address'
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='post_code'
                id='post_code'
                placeholder='Post Code'
                label='Post Code'
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                className='formDefault__input'
                type='text'
                name='reference_id'
                id='referencreference_ide_id'
                placeholder='Reference ID'
                label='Reference ID'
              />
              <div className='formDefault__hint'>
                Compose your unique id code to be used for reports identification. Hint: use your company abbreviation.
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className='validation-error-message'>{error}</p>
      <div className='formDefault__row--btns'>
        <input className='btns btn-i btn-yellow' type='submit' name='submit' value='SAVE CHANGES' />
        <input className='btns btn-i btn-trns' type='reset' name='cencel' value='Cancel' />
      </div>
    </form>
  )
}

EmployerInfoForm.propTypes = {
  handleSubmit: func.isRequired
}

export default EmployerInfoForm
