import React, { useState } from 'react'
import { connect } from 'react-redux'
import { func, arrayOf, any } from 'prop-types'
import { compose } from 'redux'
import Popup from 'reactjs-popup'
import { reduxForm, change } from 'redux-form'
import AccountFormWrapper from '../../../components/AccountFormWrapper'
import CreateAccountForm from './CreateAccountForm'
import { createAccountAction } from '../actions'
import validate from './validate'
import AnimatedCheck from '../../../components/AnimatedCheck'

const CreateAccount = ({ onSubmitForm, resetField, handleSubmit, error, handleChange }) => {
  const [showReferenceField, setShowReferenceField] = useState(true)
  const [successShow, setSuccessShow] = useState(false)
  const [newUserEmail, setNewUserEmail] = useState('N/A')

  const onSubmitHandler = async (values) => {
    const res = await onSubmitForm({ user: values })
    if (res.value.status === 200) {
      setNewUserEmail(values.email)
      setSuccessShow(true)
    }
  }
  const onChangeWatcher = (e) => {
    const role = e.target.value
    console.log("onChangeWatcher -> role", role)
    if (role === 1) {
      setShowReferenceField(false)
      resetField('reference_id')
    } else {
      setShowReferenceField(true)
    }
  }

  return (
    <AccountFormWrapper>
      <Popup modal open={successShow} closeOnDocumentClick>
        <div className='sucessPopup'>
          {successShow ? <AnimatedCheck /> : null}
          <h2>Almost done!</h2> <br />
          <p>
            Confirmation email was sent to: <strong>{newUserEmail}</strong>
          </p>
          <p>Please, confirm your email address and login to your account.</p>
        </div>
      </Popup>
      <CreateAccountForm
        onChangeWatcher={onChangeWatcher}
        showReferenceField={showReferenceField}
        onSubmit={onSubmitHandler}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        error={error}
      />
    </AccountFormWrapper>
  )
}

const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: (data) => dispatch(createAccountAction(data)),
  resetField: (field) => dispatch(change('newAccount', field))
})

CreateAccount.propTypes = {
  onSubmitForm: func.isRequired,
  resetField: func,
  handleSubmit: func.isRequired,
  handleChange: func.isRequired,
  error: arrayOf(any)
}

export default compose(
  reduxForm({
    form: 'newAccount',
    initialValues: {
      role: 0
    },
    shouldError: () => true,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate
  }),
  connect(null, mapDispatchToProps)
)(CreateAccount)
