import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import React, { useEffect } from 'react'
import { objectOf, bool, any, func, string, arrayOf, object } from 'prop-types'
import handleSubmit from 'redux-form/lib/handleSubmit'

import {
  errorNotificationAction,
  successNotificationAction
} from '../../Notifications/actions'

import {
  createSurveyAction,
  deleteSurveyByIdAction,
  getSurveyByIdAction,
  updateSurveyAction
} from '../actions'

import { ROUTE_TO_SURVEYS, ROUTE_TO_ROOT } from '../../../constants/routes'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import Spinner from '../../../components/Spinner'
import navData from '../../../constants/navData'
import validate from './validate'
import SurveyFormEngineer from './SurveyFormEngineer'
import { getEmployerByIdAction, getEmployersAction } from '../../Employers/actions'
import { getCustomerByIdAction, getCustomersAction } from '../../Customers/actions'
import { getEngeneersAction } from '../../Engineers/actions'
import SurveyFormEmployer from './SurveyFormEmployer'
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Surveys',
    link: ROUTE_TO_SURVEYS
  }
]

const Header = ({ customer }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={customer} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  customer: string.isRequired
}

// One component for create and edit customer
const SurveyUpdate = ({
  match,
  employers,
  engineers,
  customers,
  getSurvey,
  getEmployers,
  getEngineers,
  getCustomers,
  getCustomerDetails,
  inProcess,
  successNotification,
  errorNotification,
  role,
  selectedEmployer,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  updateInProcess,
  initialValues,
  createSurveyValues,
  currentUser,
  getEmployer,
  error,
  setFieldVal,
  ...formProps
}) => {
  const navigate = useNavigate()
  const { id } = match.params
  const survey_reference = currentUser && currentUser.role !== 'engineer' ? currentUser.reference_id : ''
  const start_date = new Date()
  useEffect(() => {
    if (id) {
      getSurvey(id)
    } else {
      // Reset to fix bug with filled fields on creating
      setFieldVal('survey_reference', survey_reference)
      setFieldVal('description', '')
    }
  }, [currentUser])

  const getCustomerDetailsHandler = async (value) => await getCustomerDetails(value)

  const getCustomersHandler = async () => await getCustomers(
    { employer_id: selectedEmployer }
  )
  const onSubmit = async ({
    engineer,
    employer,
    description,
    survey_reference,
    customer_id,
    is_completed,
    start_date,
    start_time,
    thermal,
    epc,
    ...notEditable
  }) => {
    const formattedDate = moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
    const formattedTime = moment(start_time, 'LT').format('HH:mm:ss')
    const date = `${formattedDate}T${formattedTime}`
    let category = ''

    if (thermal === true && epc === true)
      category = 'both'
    else if (thermal === true)
      category = 'thermal'
    else if (epc === true)
      category = 'epc'

    const data = {
      survey: {
        description,
        survey_reference,
        customer_id,
        is_completed,
        is_assigned: role === 'engineer' ? true : !!engineer,
        start_date: date,
        employer_id: role === 'engineer' ? employer : currentUser.id,
        user_id: role === 'engineer' ? currentUser.id : engineer || currentUser.id,
        category: category
      }
    }
    // eslint-disable-next-line no-unused-expressions
    id ? await updateSurvey(id, data) : await createSurvey(data)
    successNotification(`Survey successfully ${id ? 'updated' : 'created'}`)
    navigate(ROUTE_TO_SURVEYS)
  }

  const onDeleteHandler = async () => {
    try {
      await deleteSurvey(id)
      navigate(ROUTE_TO_SURVEYS)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <StaticPage
      sidebarData={navData[role]}
      header={<Header customer={id ? initialValues.contact_name : 'Add New'} />}
    >
      <div className='survey__page pToppBottom'>
        <section className='survey__page--section section__survey section__border survey'>
          {/* eslint-disable-next-line no-nested-ternary */}
          {inProcess ? (
            <Spinner />
          ) : role === 'engineer' ? (
            <div>
              <SurveyFormEngineer
                onDelete={onDeleteHandler}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                {...formProps}
                initialValues={id ? initialValues : createSurveyValues}
                employers={employers}
                customers={customers}
                getCustomerDetails={getCustomerDetailsHandler}
                getCustomers={getCustomersHandler}
                getEmployers={getEmployers}
                isEdit={!!id}
                getEmployer={getEmployer}
                error={error}
                setFieldVal={setFieldVal}
              />
              {initialValues.created_at ? (
                <div className='customer__page--created'>
                  <strong>Created</strong> At {moment(initialValues.created_at).format('DD/MM/YYYY')}
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <SurveyFormEmployer
                onDelete={onDeleteHandler}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                {...formProps}
                initialValues={id ? initialValues : createSurveyValues}
                engineers={engineers}
                customers={customers}
                getCustomerDetails={getCustomerDetailsHandler}
                getCustomers={getCustomersHandler}
                getEngineers={getEngineers}
                isEdit={!!id}
                error={error}
              />
              {initialValues.created_at ? (
                <div className='customer__page--created'>
                  <strong>Created</strong> At {moment(initialValues.created_at).format('DD/MM/YYYY')}
                </div>
              ) : null}
            </div>
          )}
        </section>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({
  surveys,
  auth,
  form,
  employers: { employers, employer },
  engeneers: { engeneers },
  customers: { customers, customer: customerDetails }
}) => {
  const {
    survey: {
      customer,
      start_time,
      start_date,
      survey_reference,
      description,
      engineer,
      is_completed
    },
    inProcess,
    updateInProcess
  } = surveys

  let selectedCustomerDetails

  if (customerDetails.id) {
    const { user, employers, employer, surveys, ...data } = customerDetails
    selectedCustomerDetails = data
  } else {
    selectedCustomerDetails = customer || {}
  }

  // initial values to update survey
  const initialValues = {
    ...selectedCustomerDetails,
    is_completed,
    start_time,
    start_date,
    // take survey_reference if it is edit, or take employer's reference_id, if user has selected employer
    survey_reference: survey_reference || (form.survey_update?.values?.employer && employer?.attributes?.reference_id),
    description,
    engineer: engineer && engineer.id.toString(),
    thermal: true,
    epc: true
  }

  // initial values to create survey
  const createSurveyValues = {
    ...selectedCustomerDetails,
    is_completed,
    // take survey_reference if it is edit, or take employer's reference_id, if user has selected employer
    survey_reference: survey_reference || (form.survey_update?.values?.employer && employer?.attributes?.reference_id),
    description,
    engineer: engineer && engineer.id.toString(),
    thermal: true,
    epc: true
  }

  return {
    initialValues,
    createSurveyValues,
    inProcess,
    updateInProcess,
    employers: employers.data,
    engineers: engeneers.data,
    selectedEmployer: form.survey_update && form.survey_update.values && form.survey_update.values.employer,
    customers,
    customerDetails,
    role: auth.user && auth.user.role,
    currentUser: auth.user
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSurvey: (id) => dispatch(getSurveyByIdAction(id)),
  createSurvey: (data) => dispatch(createSurveyAction(data)),
  updateSurvey: (id, data) => dispatch(updateSurveyAction(id, data)),
  deleteSurvey: (id) => dispatch(deleteSurveyByIdAction(id)),
  getEmployers: (params) => dispatch(getEmployersAction(params)),
  getEngineers: (params) => dispatch(getEngeneersAction(params)),
  getCustomers: (params) => dispatch(getCustomersAction(params)),
  getCustomerDetails: (id) => dispatch(getCustomerByIdAction(id)),
  getEmployer: (id) => dispatch(getEmployerByIdAction(id)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
  setFieldVal: (field, val) => dispatch(change('survey_update', field, val))
})

SurveyUpdate.propTypes = {
  match: objectOf(any),
  currentUser: objectOf(any),
  initialValues: objectOf(any),
  employers: arrayOf(object),
  engineers: arrayOf(object),
  customers: arrayOf(object),
  getSurvey: func.isRequired,
  getEmployers: func.isRequired,
  getEngineers: func.isRequired,
  getEmployer: func.isRequired,
  getCustomers: func.isRequired,
  deleteSurvey: func.isRequired,
  getCustomerDetails: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  inProcess: bool.isRequired,
  role: string,
  selectedEmployer: string,
  createSurvey: func,
  updateSurvey: func,
  setFieldVal: func,
  updateInProcess: bool,
  error: string
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'survey_update',
    //shouldError: () => true,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate
  })
)(SurveyUpdate)
