import typeToReducer from 'type-to-reducer'
import { GET_USER_BY_ID, GET_USERS, UPDATE_USER } from './constants'
// import { CREATE_USER, DELETE_USER_BY_ID } from './constants'

export const initialState = {
  inProcess: false,
  deleteInProgress: false,
  updateInProgress: false,
  per_page: null,
  total_pages: null,
  count: 0,
  user: {},
  users: [],
  errors: null
}

export default typeToReducer(
  {
    [GET_USERS]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          users: action.payload.data.users.data,
          per_page: action.payload.data.per_page,
          total_pages: action.payload.data.total_pages,
          count: action.payload.data.count,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [GET_USER_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          inProcess: false,
          user: action.payload.data.patient.data.attributes
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          user: {},
          inProcess: false
        }
      }
    },
    // [DELETE_USER_BY_ID]: {
    //   START: (state, action) => {
    //     return {
    //       ...state,
    //       deleteInProgress: true
    //     }
    //   },
    //   SUCCESS: (state, action) => {
    //     return {
    //       ...state,
    //       deleteInProgress: false
    //     }
    //   },
    //   FAIL: (state, action) => {
    //     return {
    //       ...state,
    //       errors: action.payload.errors,
    //       deleteInProgress: false
    //     }
    //   }
    // },
    // [CREATE_USER]: {
    //   START: (state, action) => {
    //     return {
    //       ...state,
    //       updateInProgress: true
    //     }
    //   },
    //   SUCCESS: (state, action) => {
    //     return {
    //       ...state,
    //       updateInProgress: false
    //     }
    //   },
    //   FAIL: (state, action) => {
    //     return {
    //       ...state,
    //       errors: action.payload.errors,
    //       updateInProgress: false
    //     }
    //   }
    // },
    [UPDATE_USER]: {
      START: (state, action) => {
        return {
          ...state,
          updateInProgress: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          updateInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          updateInProgress: false
        }
      }
    }
  },
  initialState
)
