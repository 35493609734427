import React, { useState } from 'react'
import { instanceOf } from 'prop-types'
import Tab from './Tab'
import SurveyDetails from '../containers/Surveys/Single/SurveyDetails'
import ThermalDetails from '../containers/Surveys/Single/ThermalDetails'
import EPCDetails from '../containers/Surveys/Single/EPCDetails'
import WaterTestDetails from '../containers/Surveys/Single/WaterTestDetails'
import EngineerDetails from '../containers/Engineers/Single/EngineerDetails'

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label || 'Info')

  const onClickTabHandler = (tab) => {
    setActiveTab(tab)
  }

  const params = {params: {id: children[0].id}, currentTab: {activeTab}}

  return (
    <div className='pTopBottom' id='tabs'>
      <div className='tabs'>
        <ul className='tabs__list'>
          {children.map((child, index) => {
            const { label } = child.props

            return (
              <Tab activeTab={activeTab} key={index} label={label} onClick={onClickTabHandler} />
            )
          })}
        </ul>
      </div>
      <section className='profile__page--section section__profile section__border '>
        {children.map((child, key) => {
          if (child.props.label !== activeTab) return undefined
          if (child.props.component === 'Engineer')
            if (activeTab === 'Info') return (<EngineerDetails key={child.props.label} match={params} />)
          if (child.props.component === 'Survey')
            if (activeTab === 'Info') return (<SurveyDetails key={child.props.label} match={params} />)
            if (activeTab === 'Thermal') return (<ThermalDetails key={child.props.label} match={params} />)
            if (activeTab === 'EPC') return (<EPCDetails key={child.props.label} match={params} />)
            if (activeTab === 'Water Test') return (<WaterTestDetails key={child.props.label} match={params} />)
            // return <SurveySingle match={params} />
          return child.props.children
        })}
      </section>
    </div>
  )
}

Tabs.propTypes = {
  children: instanceOf(Array).isRequired
}

export default Tabs
