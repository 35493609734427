import React from 'react'
import DataTable from 'react-data-table-component'
import { arrayOf, bool, func, number, object } from 'prop-types'
import PaginationCustom from './PaginationCustom'

import Spinner from './Spinner'

const Table = ({
  columns,
  data,
  inProcess,
  onClickHandler,
  per_page,
  count,
  onChangePage,
  changePerPageHandler,
  page
}) => {
  return (
    <section className='customers__page--section section__customers section__border'>
      <div className='section__customers--table customers__table table__default'>
        <DataTable
          columns={columns}
          data={data}
          pagination
          className='dataTable'
          paginationServer
          pointerOnHover
          persistTableHead
          paginationComponent={() => (
            <PaginationCustom
              rowsPerPage={per_page}
              rowCount={count}
              onChangePage={onChangePage}
              onChangeRowsPerPage={changePerPageHandler}
              currentPage={page}
            />
          )}
          progressPending={inProcess}
          onRowClicked={onClickHandler}
          rowsPerPage={per_page}
          paginationTotalRows={count}
          onChangePage={onChangePage}
          onChangeRowsPerPage={changePerPageHandler}
          progressComponent={<Spinner />}
        />
      </div>
    </section>
  )
}

Table.propTypes = {
  columns: arrayOf(object),
  data: arrayOf(object),
  inProcess: bool,
  onClickHandler: func.isRequired,
  changePerPageHandler: func.isRequired,
  onChangePage: func.isRequired,
  per_page: number.isRequired,
  count: number.isRequired,
  page: number.isRequired
}

export default Table
