import React from 'react'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { any, arrayOf, bool, func, object, objectOf, string } from 'prop-types'
import moment from 'moment'
import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_ENGINEERS } from '../../../constants/routes'
import fieldTrimNormalizer from '../../../utils/fieldTrimNormalizer'

const EngineerForm = ({
  handleSubmit,
  onSubmit,
  submitting,
  engineer,
  initialValues,
  isEdit,
  error
}) => {

  const {
    name,
    address,
    post_code,
    phone_number,
    email,
    contact_email,
    added,
    logined_at,
    surveys_count,
    is_active
  } = initialValues

  console.log("ENGINEER EDIT FORM", isEdit)

  return (
    <form className='formDefault fullWidth viewForm' onSubmit={handleSubmit(onSubmit)}>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>
          {isEdit ? 'Edit Engineer' : 'Create Engineer'}
        </h1>
      </div>

      <div className='formDefault__body'>
        <div className='formDefault__body--columns clearfix'>
          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Engineer Info</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Name'
                className='formDefault__input'
                type='text'
                name='name'
                id='engineer_name'
                defaultValue={name}
                normalize={fieldTrimNormalizer}
                placeholder='Name'
                errorNoSpace
              />
            </div>

            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Address'
                className='formDefault__textarea'
                type='textarea'
                name='address'
                id='engineer_address'
                defaultValue={address}
                normalize={fieldTrimNormalizer}
                placeholder='Address'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Post Code'
                className='formDefault__input'
                type='text'
                name='post_code'
                id='engineer_post_code'
                defaultValue={post_code}
                normalize={fieldTrimNormalizer}
                placeholder='Post Code'
                errorNoSpace
              />
            </div>
          </div>

          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Contact Details</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Phone'
                className='formDefault__input'
                type='text'
                name='phone_number'
                id='engineer_phone_number'
                defaultValue={phone_number}
                normalize={fieldTrimNormalizer}
                placeholder='Phone Number'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <label className='formDefault__label'>
                <strong>Emails</strong>
              </label>
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Apps Login'
                className='formDefault__input'
                type='text'
                name='email'
                id='engineer_email'
                defaultValue={email}
                normalize={fieldTrimNormalizer}
                placeholder='Apps Login'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Contact Email'
                className='formDefault__input'
                type='text'
                name='contact_email'
                id='engineer_contact_email'
                defaultValue={contact_email}
                normalize={fieldTrimNormalizer}
                placeholder='Contact Email'
                errorNoSpace
              />
            </div>
          </div>

          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Additional Info</div>
            <div className='formDefault__row'>
              <label className='formDefault__label'>Date Added</label>
              <div className='formDefault__text'>{moment(added).format('DD/MM/YYYY')}</div>
            </div>
            <div className='formDefault__row'>
              <label className='formDefault__label'>Last Active</label>
              <div className='formDefault__text'>{moment(logined_at).format('DD/MM/YYYY')}</div>
            </div>
            <div className='formDefault__row'>
              <label className='formDefault__label'>Number of Surveys</label>
              <div className='formDefault__text'>{surveys_count}</div>
            </div>
            <div className='formDefault__row'>
              <label className='formDefault__label'>Account Status</label>
              <div className='formDefault__text'>{is_active===true ? 'Active' : 'Suspended'}</div>
            </div>
          </div>
        </div>

        {error ? <p className='validation-error-message'>{error}</p> : null}

        <div className='formDefault__row--btns'>
          <button
            className={`btns btn-i btn-yellow ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Save'}
          </button>
          <Link className='btns btn-i btn-trns' to={ROUTE_TO_ENGINEERS}>
            Cancel
          </Link>
        </div>
      </div>
    </form>
  )
}

EngineerForm.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  getCustomers: func.isRequired,
  getEmployers: func.isRequired,
  getEmployer: func.isRequired,
  onDelete: func.isRequired,
  getCustomerDetails: func.isRequired,
  submitting: bool.isRequired,
  isEdit: bool.isRequired,
  error: string,
  initialValues: objectOf(any),
  employers: arrayOf(object),
  customers: arrayOf(object),
  setFieldVal: func
}

export default EngineerForm
