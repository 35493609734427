import React from 'react'
import { Field } from 'redux-form'
import { func, string } from 'prop-types'
import ReduxFormField from '../ReduxFormField'

const ChangePasswordForm = ({ handleSubmit, onSubmit, error }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className='formDefault'>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>Change password</h1>
      </div>
      <div className='formDefault__body'>
        <div className='col-half'>
          <div className='formDefault__row'>
            <Field
              component={ReduxFormField}
              className='formDefault__input'
              type='password'
              name='old_password'
              id='old_password'
              label='Old Password'
              placeholder='*********'
            />
          </div>
          <div className='formDefault__row'>
            <Field
              component={ReduxFormField}
              className='formDefault__input'
              type='password'
              name='password'
              id='password'
              label='Password'
              placeholder='*********'
            />
          </div>
          <div className='formDefault__row'>
            <Field
              component={ReduxFormField}
              className='formDefault__input'
              type='password'
              name='password_confirmation'
              id='password_confirmation'
              label='Repeat Password'
              placeholder='*********'
            />
          </div>
          <p className='validation-error-message'>{error}</p>
          <div className='formDefault__row--btns'>
            <input className='btns btn-i btn-yellow' type='submit' name='submit' value='SAVE CHANGES' />
          </div>
        </div>
      </div>
    </form>
  )
}

ChangePasswordForm.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  error: string
}

export default ChangePasswordForm
