import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import React, { useEffect, useState } from 'react'
import { objectOf, bool, any, func, string } from 'prop-types'
import Popup from 'reactjs-popup'
import PopupLogo from '../../../components/PopupLogo'
import { errorNotificationAction, successNotificationAction } from '../../Notifications/actions'
import { getAdminByIdAction, updateAdminAction, deleteAdminByIdAction } from '../actions'
import { ROUTE_TO_ADMINS } from '../../../constants/routes'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import Spinner from '../../../components/Spinner'
import navData from '../../../constants/navData'
import AdminForm from './AdminForm'
import history from '../../../history'
import validate from './validate'

const breadCrumbsData = [
  {
    title: 'Admins',
    link: ROUTE_TO_ADMINS
  }
]

const Header = ({ user }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={user} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  user: string.isRequired
}
// One component for create and edit user
const AdminUpdate = ({
  match,
  getAdmin,
  inProcess,
  successNotification,
  errorNotification,
  role,
  createUser,
  updateAdmin,
  updateInProcess,
  initialValues,
  deleteAdmin,
  ...formProps
}) => {
  const { id } = match.params

  const [deletePopupShow, setDeletePopupShow] = useState(false)

  const [idToDelete, setIdToDelete] = useState('')

  useEffect(() => {
    getAdmin(id)
  }, [])

  const onSubmit = async ({ id: userId, name, email, password, password_confirmation }) => {
    const data = { admin: { name, email, password, password_confirmation } }
    // eslint-disable-next-line no-unused-expressions
    await updateAdmin(userId, data)
    successNotification('Admin successfully updated')
    history.push(ROUTE_TO_ADMINS)
  }

  const deleteMaybe = (idVal) => {
    setIdToDelete(idVal)
    setDeletePopupShow(true)
  }

  const deleteAdminHandler = async () => {
    const res = await deleteAdmin(idToDelete)
    if (res.value.status === 200) {
      errorNotification('User was deleted.')
      setIdToDelete(0)
      history.push(ROUTE_TO_ADMINS)
    }
  }

  return (
    <StaticPage sidebarData={navData[role]} header={<Header user={initialValues.name} />}>
      <div className='admin__page pToppBottom'>
        <section className='admin__page--section section__user section__border user'>
          {inProcess ? (
            <Spinner />
          ) : (
            <div>
              <AdminForm onDelete={deleteMaybe} onSubmit={onSubmit} {...formProps} initialValues={initialValues} />
              {initialValues.created_at ? (
                <div className='admin__page--created'>
                  <strong>Created</strong> At {moment(initialValues.created_at).format('DD/MM/YYYY')}
                </div>
              ) : null}
            </div>
          )}
        </section>
        <Popup modal open={deletePopupShow} closeOnDocumentClick>
          <div className='popup__block popup__engineerdelete'>
            <PopupLogo />
            <div className='popup__title'>Delete Admin</div>
            <div className='formPopup'>
              <div className='formPopup__row formPopup__row--btns'>
                <button type='button' className='btns btn-i btn-red btn-delete' onClick={() => deleteAdminHandler()}>
                  Delete
                </button>
                <button
                  onClick={() => setDeletePopupShow(false)}
                  type='button'
                  className='btns btn-i btn-trns btn-cencel'>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    </StaticPage>
  )
}

// TODO table height bug

const mapStateToProps = ({ admins, auth }) => ({
  initialValues: admins.admin,
  inProcess: admins.inProcess,
  updateInProcess: admins.updateInProcess,
  role: auth.user && auth.user.role
})

const mapDispatchToProps = (dispatch) => ({
  getAdmin: (id) => dispatch(getAdminByIdAction(id)),
  deleteAdmin: (id) => dispatch(deleteAdminByIdAction(id)),
  updateAdmin: (id, data) => dispatch(updateAdminAction(id, data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message))
})

AdminUpdate.propTypes = {
  match: objectOf(any),
  initialValues: objectOf(any),
  getAdmin: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  inProcess: bool.isRequired,
  role: string,
  createUser: func,
  updateAdmin: func,
  deleteAdmin: func,
  updateInProcess: bool
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'adminUpdate',
    shouldError: () => true,
    enableReinitialize: true,
    validate
  })
)(AdminUpdate)
