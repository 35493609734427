import { createAction } from 'redux-actions'

import { CREATE_USER, DELETE_USER_BY_ID, GET_USER_BY_ID, GET_USERS, UPDATE_USER } from './constants'
import { createUserApi, deleteUserByIdApi, getUserByIdApi, getUsersApi, updateUserApi } from './api'

export const getUsersAction = createAction(GET_USERS, async (params) => getUsersApi(params))
export const getUserByIdAction = createAction(GET_USER_BY_ID, async (id) => getUserByIdApi(id))
export const deleteUserByIdAction = createAction(DELETE_USER_BY_ID, async (id) => deleteUserByIdApi(id))
export const createUserAction = createAction(CREATE_USER, async (data) => createUserApi(data))
export const updateUserAction = createAction(UPDATE_USER, async (id, data) => updateUserApi(id, data))
