import React from 'react'
import { Link } from 'react-router-dom'

import { ROUTE_TO_PRIVACY_POLICY, ROUTE_TO_ROOT, ROUTE_TO_TERMS_AND_CONDITIONS } from '../constants/routes'

export default function AccountFooter() {
  return (
    <footer className='footer__main'>
      <div className='site__width'>
        <p>
          <a target='_blank' rel='noopener noreferrer' href={ROUTE_TO_TERMS_AND_CONDITIONS}>
            Term & Conditions
          </a>
          <span> | </span>
          <a target='_blank' rel='noopener noreferrer' href={ROUTE_TO_PRIVACY_POLICY}>
            Privacy Policy
          </a>
        </p>
        <p>
          <span>© 2019 </span>
          <Link to={ROUTE_TO_ROOT} className='yellow'>
            THERMAFY
          </Link>
          <span> All rights reserved</span>
        </p>
      </div>
    </footer>
  )
}
