import React, { useState } from 'react'
import { any, objectOf } from 'prop-types'
import axios from 'axios';
import config from '../../../config';

const WaterTestUpload = ({ survey }) => {
  const [file, setFile] = useState()
  const [revisionNumber, setRevisionNumber] = useState()
  const [surveyId, setSurveyId] = useState()
  const [isReports, setIsReports] = useState()
  //const [pdfUrl, setPdfUrl] = useState()
  //const { water_test[0] } = survey;
  const pdfUrl = survey.water_test[0]?.data?.attributes?.pdf_url
  const water_test_report = survey.water_test[0]?.data?.attributes?.is_reports

  const handleChange = (event) => {
    setSurveyId(survey.id)
    setRevisionNumber("1")
    setIsReports("false")
    setFile(event.target.files[0])
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const url = 'http://admin.thermafyeco.com/api/v1/managers/water_tests';

    const formData = new FormData();
    formData.append('water_test[revision_number]', revisionNumber);
    formData.append('water_test[survey_id]', surveyId);
    formData.append('water_test[is_reports]', isReports);
    formData.append('water_test[report_attachment]', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      setFile([]);
      //setPdfUrl(response.data.water_test.data.attributes.pdf_url);
      setIsReports(response.data.water_test.data.attributes.is_reports);
    });

    //setPdfUrl(survey.water_test[0].data.attributes.pdf_url);
  }

  return (
    <form onSubmit={handleSubmit}>
      <h3>Water Test Upload</h3>
      <div className="water-test">
        <input type="file" name="file" onChange={handleChange} />
        { water_test_report ?
          (
            <a className="formDefault__bg btns btn-b btn-plan" href={config.REACT_APP_UPLOADS_URL + pdfUrl}>
              <span className="formDefault__label">Download Report</span>
              <span className="formDefault__text">available</span>
            </a>
          )
          :
          <button type="submit" className="btns btn-b btn-yellow pdf-upload-btn">Upload</button>
        }

      </div>
    </form>
  )
}

WaterTestUpload.propTypes = {
  survey: objectOf(any)
}

export default WaterTestUpload
