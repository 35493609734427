import React from 'react'
import Popup from 'reactjs-popup'

import { bool, func, string } from 'prop-types'
import PopupLogo from './PopupLogo'

const DeletePopup = ({ onDelete, onCancel, isOpen, target, descr }) => {
  return (
    <Popup modal open={isOpen} closeOnDocumentClick>
      <div className='popup__block popup__engineerdelete'>
        <PopupLogo />
        <div className='popup__title'>Delete {target}</div>
        {descr ? <div className='popup__subtitle'>{descr}</div> : null}
        <div className='formPopup'>
          <div className='formPopup__row formPopup__row--btns'>
            <button type='button' className='btns btn-i btn-red btn-delete' onClick={onDelete}>
              Delete
            </button>
            <button onClick={onCancel} type='button' className='btns btn-i btn-trns btn-cencel'>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Popup>
  )
}
DeletePopup.propTypes = {
  onDelete: func.isRequired,
  onCancel: func.isRequired,
  isOpen: bool.isRequired,
  target: string,
  descr: string
}

export default DeletePopup
