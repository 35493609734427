import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import React, { useEffect } from 'react'
import { objectOf, bool, any, func, string, arrayOf, object } from 'prop-types'

import handleSubmit from 'redux-form/lib/handleSubmit'
import { errorNotificationAction, successNotificationAction } from '../../Notifications/actions'
import { createJobAction, deleteJobByIdAction, getJobByIdAction, updateJobAction } from '../actions'
import { ROUTE_TO_JOBS, ROUTE_TO_ROOT } from '../../../constants/routes'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import Spinner from '../../../components/Spinner'
import navData from '../../../constants/navData'
import validate from './validate'
import JobFormEngineer from './JobFormEngineer'
import { getEmployerByIdAction, getEmployersAction } from '../../Employers/actions'
import { getCustomerByIdAction, getCustomersAction } from '../../Customers/actions'
import history from '../../../history'
import { getEngeneersAction } from '../../Engineers/actions'
import JobFormEmployer from './JobFormEmployer'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Jobs',
    link: ROUTE_TO_JOBS
  }
]

const Header = ({ customer }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={customer} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  customer: string.isRequired
}
// One component for create and edit job
const JobUpdate = ({
  match,
  employers,
  engineers,
  customers,
  getJob,
  getEmployers,
  getEngineers,
  getCustomers,
  getCustomerDetails,
  inProcess,
  successNotification,
  errorNotification,
  role,
  selectedEmployer,
  createJob,
  updateJob,
  deleteJob,
  updateInProcess,
  initialValues,
  currentUser,
  getEmployer,
  setFieldVal,
  error,
  ...formProps
}) => {
  const { id } = match.params

  useEffect(() => {
    if (id) {
      getJob(id)
    } else {
      // Reset to fix bug with filled fields on creating
      setFieldVal('job_reference', currentUser && currentUser.role !== 'engineer' ? currentUser.reference_id : '')
      setFieldVal('description', '')
    }
  }, [currentUser])

  const getCustomerDetailsHandler = async (value) => await getCustomerDetails(value)

  const getCustomersHandler = async () => await getCustomers({ employer_id: selectedEmployer })

  const onSubmit = async ({
    engineer,
    employer,
    description,
    job_reference,
    customer_id,
    is_completed,
    start_date,
    start_time,
    ...notEditable
  }) => {
    const formattedDate = moment(start_date).format('YYYY-MM-DD')
    const formattedTime = moment(start_time, 'LT').format('hh:mm:ss')
    const date = `${formattedDate}T${formattedTime}`

    const data = {
      job: {
        description,
        job_reference,
        customer_id,
        is_completed,
        is_assigned: role === 'engineer' ? true : !!engineer,
        start_date: date,
        employer_id: role === 'engineer' ? employer : currentUser.id,
        user_id: role === 'engineer' ? currentUser.id : engineer || currentUser.id
      },
      customer: { id: customer_id }
    }

    // eslint-disable-next-line no-unused-expressions
    id ? await updateJob(id, data) : await createJob(data)
    successNotification(`Job successfully ${id ? 'updated' : 'created'}`)
    // history.push(ROUTE_TO_CUSTOMERS)
  }

  const onDeleteHandler = async () => {
    await deleteJob(id)
    history.push(ROUTE_TO_JOBS)
  }

  return (
    <StaticPage sidebarData={navData[role]} header={<Header customer={id ? initialValues.contact_name : 'Add New'} />}>
      <div className='job__page pToppBottom'>
        <section className='job__page--section section__job section__border job'>
          {/* eslint-disable-next-line no-nested-ternary */}
          {inProcess ? (
            <Spinner />
          ) : role === 'employer' ? (
            <div>
              <JobFormEmployer
                onDelete={onDeleteHandler}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                {...formProps}
                initialValues={initialValues}
                engineers={engineers}
                customers={customers}
                getCustomerDetails={getCustomerDetailsHandler}
                getCustomers={getCustomersHandler}
                getEngineers={getEngineers}
                isEdit={!!id}
                error={error}
              />
              {initialValues.created_at ? (
                <div className='customer__page--created'>
                  <strong>Created</strong> At {moment(initialValues.created_at).format('DD/MM/YYYY')}
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <JobFormEngineer
                onDelete={onDeleteHandler}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                {...formProps}
                initialValues={initialValues}
                employers={employers}
                customers={customers}
                getCustomerDetails={getCustomerDetailsHandler}
                getCustomers={getCustomersHandler}
                getEmployers={getEmployers}
                isEdit={!!id}
                getEmployer={getEmployer}
                setFieldVal={setFieldVal}
                error={error}
              />
              {initialValues.created_at ? (
                <div className='customer__page--created'>
                  <strong>Created</strong> At {moment(initialValues.created_at).format('DD/MM/YYYY')}
                </div>
              ) : null}
            </div>
          )}
        </section>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({
  jobs,
  auth,
  form,
  employers: { employers, employer },
  engeneers: { engeneers },
  customers: { customers, customer: customerDetails }
}) => {
  const {
    job: { customer, start_time, start_date, job_reference, description, engineer, is_completed },
    inProcess,
    updateInProcess
  } = jobs
  let selectedCustomerDetails

  if (customerDetails.id) {
    const { user, employers, employer, surveys, ...data } = customerDetails
    selectedCustomerDetails = data
  } else {
    selectedCustomerDetails = customer || {}
  }

  const initialValues = {
    ...selectedCustomerDetails,
    is_completed,
    start_time,
    start_date,
    // take job_reference if it is edit, or take employer's reference_id, if user has selected employer
    job_reference: job_reference || (form.job_update?.values?.employer && employer?.attributes?.reference_id),
    description,
    engineer: engineer && engineer.id.toString()
  }

  return {
    initialValues,
    inProcess,
    updateInProcess,
    employers: employers.data,
    engineers: engeneers.data,
    selectedEmployer: form.job_update && form.job_update.values && form.job_update.values.employer,
    customers,
    customerDetails,
    role: auth.user && auth.user.role,
    currentUser: auth.user
  }
}

const mapDispatchToProps = (dispatch) => ({
  getJob: (id) => dispatch(getJobByIdAction(id)),
  createJob: (data) => dispatch(createJobAction(data)),
  updateJob: (id, data) => dispatch(updateJobAction(id, data)),
  deleteJob: (id) => dispatch(deleteJobByIdAction(id)),
  getEmployers: (params) => dispatch(getEmployersAction(params)),
  getEngineers: (params) => dispatch(getEngeneersAction(params)),
  getCustomers: (params) => dispatch(getCustomersAction(params)),
  getCustomerDetails: (id) => dispatch(getCustomerByIdAction(id)),
  getEmployer: (id) => dispatch(getEmployerByIdAction(id)),

  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),

  setFieldVal: (field, val) => dispatch(change('job_update', field, val))
})

JobUpdate.propTypes = {
  match: objectOf(any),
  currentUser: objectOf(any),
  initialValues: objectOf(any),
  employers: arrayOf(object),
  engineers: arrayOf(object),
  customers: arrayOf(object),
  getJob: func.isRequired,
  getEmployers: func.isRequired,
  getEmployer: func.isRequired,
  getEngineers: func.isRequired,
  getCustomers: func.isRequired,
  deleteJob: func.isRequired,
  getCustomerDetails: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  inProcess: bool.isRequired,
  role: string,
  selectedEmployer: string,
  createJob: func,
  updateJob: func,
  updateInProcess: bool,
  setFieldVal: func.isRequired,
  error: string
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'job_update',
    //shouldError: () => true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
  })
)(JobUpdate)
