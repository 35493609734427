import React from 'react'
import { reduxForm } from 'redux-form'
import { object, func, string } from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import StaticPage from '../../../components/StaticPage'
import navData from '../../../constants/navData'
import SiteHeader from '../../../components/SiteHeader'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import Tabs from '../../../components/Tabs'
import EmployerInfoForm from '../../../components/EmployerInfoForm'
import EngineerInfoForm from '../../../components/EngineerInfoForm'
import ChangePasswordForm from '../../../components/ChangePasswordForm'
import validate from './validate'
import { updateUserAction, updatePasswordAction, updateAdminAction } from '../actions'
import { ROUTE_TO_ROOT } from '../../../constants/routes'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const ProfilePage = ({
  currentUserData,
  updateUser,
  notificationData,
  updatePassword,
  error,
  handleSubmit,
  updateAdmin
}) => {
  if (!currentUserData.user) return false
  const { successNotification, errorNotification } = notificationData
  const currentUser = currentUserData.user
  const ROLE = currentUser.role

  // Relate required header parts
  const Header = () => {
    return (
      <SiteHeader>
        <BreadCrumbs data={breadCrumbsData} current={ROLE} />
        <ProfileMenu currentUser={currentUser} />
      </SiteHeader>
    )
  }

  // Set form according to user role
  const profileForm = (ROLE === 'employer' ? EmployerInfoForm : EngineerInfoForm)

  // Connect reduxForm to form that was choosen above
  const ProfileInfoReduxFrom = reduxForm({
    form: 'profileInfo',
    validate,
  })(profileForm)

  const onInfoSubmitHandler = async (values) => {
    const res =
      ROLE === 'super_admin'
        ? await updateAdmin(currentUser.id, { user: values })
        : await updateUser(currentUser.id, { user: values })
    if (res.value.status === 200) {
      successNotification('User was updated')
    } else {
      errorNotification('Something went wrong.')
    }
  }

  const onPasswordSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    const body = new FormData()

    body.append('user[old_password]', values.old_password)
    body.append('user[password]', values.password)
    body.append('user[password_confirmation]', values.password_confirmation)

    const res = await updatePassword(body)
    if (res.value.status === 200) {
      successNotification('Password was updated')
    } else {
      errorNotification('Something went wrong.')
    }
  }

  return (
    <StaticPage sidebarData={navData[ROLE]} header={<Header />} pageClass='profile__page'>
      <Tabs>
        <div className='tabs__content' label='My profile'>
          <ProfileInfoReduxFrom initialValues={currentUser} onSubmit={onInfoSubmitHandler} error={error} />
        </div>
        <div label='Change password'>
          <ChangePasswordForm error={error} handleSubmit={handleSubmit} onSubmit={onPasswordSubmitHandler} />
        </div>
      </Tabs>
    </StaticPage>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, data) => dispatch(updateUserAction(id, data)),
  updateAdmin: (id, data) => dispatch(updateAdminAction(id, data)),
  updatePassword: (data) => dispatch(updatePasswordAction(data)),
})

ProfilePage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentUserData: object,
  // eslint-disable-next-line react/forbid-prop-types
  notificationData: object,
  updateUser: func.isRequired,
  updateAdmin: func,
  updatePassword: func.isRequired,
  handleSubmit: func.isRequired,
  error: string
}

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'changePassword',
    enableReinitialize: true,
    validate
  })
)(ProfilePage)
