import React from 'react'
import moment from 'moment'
import { func, string, arrayOf, object, number, oneOfType } from 'prop-types'

const Select = ({ onChange, selected, data = [], title }) => {
  const onChangeHandler = ({ target }) => onChange(target.value)
  return (
    <div className='table__cell cell__employer'>
      <form className='tableheaderForm custom-select table__header--filter'>
        <select defaultValue={selected} className='tableHeaderSelect' onChange={onChangeHandler}>
          <option value=''>All</option>
          {data.map(({ attributes }) => {
            return (
              <option key={attributes.id} value={attributes.id}>
                {attributes.name}
              </option>
            )
          })}
          {title ? <option value='own'>{title}</option> : null}
        </select>
      </form>
    </div>
  )
}

Select.propTypes = {
  onChange: func.isRequired,
  data: arrayOf(object),
  selected: oneOfType([number, string]),
  title: string
}

export default {
  customers: {
    employer: [
      {
        name: 'Customer Name',
        selector: 'attributes.full_name',
        sortable: false
      },
      {
        name: 'Contact Name',
        selector: 'attributes.contact_name',
        sortable: true,
        right: false
      },
      {
        name: 'Date',
        selector: 'attributes.created_at',
        cell: (row) => {
          const date = moment(row.attributes.start_date).format('DD/MM/YYYY')
          return <div>{date}</div>
        },
        sortable: true,
        left: true
      },
      {
        name: 'Contact Phone Number',
        selector: 'attributes.contact_phone_number',
        sortable: true,
        right: false
      },
      {
        name: 'Contact Email',
        selector: 'attributes.contact_email',
        sortable: false,
        right: true
      },
      {
        name: 'Thermal',
        selector: 'attributes.thermal',
        sortable: false,
        center: true
      },
      {
        name: 'EPC',
        selector: 'attributes.epc',
        sortable: false,
        center: true
      },
      {
        name: 'Jobs',
        selector: 'attributes.jobs',
        sortable: false,
        right: true
      }
    ],
    engineer: (onChange, selected, employers) => {
      return [
        {
          name: 'Customer Name',
          selector: 'attributes.full_name',
          sortable: true
        },
        {
          name: <Select onChange={onChange} selected={selected} data={employers} title='Own customers' />,
          selector: 'attributes.employer',
          right: false
        },
        {
          name: 'Date',
          selector: 'attributes.created_at',
          cell: (row) => {
            const date = moment(row.attributes.created_at).format('DD/MM/YYYY')
            return <div>{date}</div>
          },
          sortable: true,
          left: true
        },
        {
          name: 'Contact Phone Number',
          selector: 'attributes.contact_phone_number',
          sortable: false,
          right: false
        },
        {
          name: 'Contact Email',
          selector: 'attributes.contact_email',
          sortable: false,
          right: true
        },
        {
          name: 'Thermal',
          selector: 'attributes.thermal',
          sortable: false,
          center: true
        },
        {
          name: 'EPC',
          selector: 'attributes.epc',
          sortable: false,
          center: true
        },
        {
          name: 'Jobs',
          selector: 'attributes.jobs',
          sortable: false,
          right: true
        }
      ]
    }
  },
  jobs: {
    employer: [
      {
        name: 'Job No.',
        selector: 'attributes.job_reference',
        sortable: true
      },
      {
        name: 'Customer',
        selector: 'attributes.customer.full_name',
        sortable: true,
        right: false
      },
      {
        name: 'Date',
        selector: 'attributes.start_date',
        // cell: (row) => {
        //   const date = moment(row.attributes.start_date).format('DD/MM/YYYY')
        //   return <div>{date}</div>
        // },
        sortable: true,
        left: true
      },
      {
        name: 'Time',
        selector: 'attributes.start_time',
        // cell: (row) => {
        //   const date = moment(row.attributes.start_date).format('HH:MM')
        //   return <div>{date}</div>
        // },
        sortable: false,
        left: true
      },
      {
        name: 'Assigned',
        selector: 'attributes.is_assigned',
        cell: (row) => {
          const isAssigned = row.attributes.is_assigned
          return (
            <div className='table__cell cell__assigned cell-center'>
              <i className={`icons i17x17 icon-checkbox ${isAssigned ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      },
      {
        name: 'Completed',
        selector: 'attributes.is_completed',
        cell: (row) => {
          const isCompleted = row.attributes.is_completed
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${isCompleted ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      },
      {
        name: 'Reports',
        selector: 'attributes.is_reports',
        cell: (row) => {
          const isReports = row.attributes.is_reports
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${isReports ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      }
    ],
    engineer: (onChange, selected, employers) => {
      return [
        {
          name: 'Job No.',
          selector: 'attributes.job_reference',
          sortable: true
        },
        {
          name: <Select onChange={onChange} selected={selected} data={employers} title='Own jobs' />,
          cell: (row) => {
            const employer = row.attributes.employer && row.attributes.employer.name
            return <div>{employer || 'Own job'}</div>
          },
          right: false
        },
        {
          name: 'Customer',
          selector: 'attributes.customer.contact_name',
          sortable: true,
          right: false
        },
        {
          name: 'Date',
          selector: 'attributes.start_date',
          // cell: (row) => {
          //   const date = moment(row.attributes.start_date).format('DD/MM/YYYY')
          //   return <div>{date}</div>
          // },
          sortable: true,
          left: true
        },
        {
          name: 'Time',
          selector: 'attributes.start_time',
          // cell: (row) => {
          //   const date = moment(row.attributes.start_date).format('HH:MM')
          //   return <div>{date}</div>
          // },
          sortable: false,
          left: true
        },
        {
          name: 'Completed',
          selector: 'attributes.is_completed',
          cell: (row) => {
            const isCompleted = row.attributes.is_completed
            return (
              <div className='table__cell cell__assigned'>
                <i className={`icons i17x17 icon-checkbox ${isCompleted ? 'checked' : ''}`} />
              </div>
            )
          },
          sortable: true,
          center: true
        },
        {
          name: 'Reports',
          selector: 'attributes.is_reports',
          cell: (row) => {
            const isReports = row.attributes.is_reports
            return (
              <div className='table__cell cell__assigned'>
                <i className={`icons i17x17 icon-checkbox ${isReports ? 'checked' : ''}`} />
              </div>
            )
          },
          sortable: true,
          right: true
        }
      ]
    }
  },
  surveys: {
    employer: [
      {
        name: 'Survey  No.',
        selector: 'attributes.survey_reference',
        sortable: true
      },
      {
        name: 'Customer',
        selector: 'attributes.customer.full_name', // TODO: wait until backend will fix it
        // selector: 'attributes.survey_reference',
        sortable: true,
        right: false
      },
      {
        name: 'Date',
        selector: 'attributes.start_date',
        cell: (row) => {
          const date = moment(row.attributes.start_date).format('DD/MM/YYYY')
          return <div>{date}</div>
        },
        sortable: true,
        left: true
      },
      {
        name: 'Time',
        selector: 'attributes.start_time',
        // cell: (row) => {
        //   const date = moment(row.attributes.start_date).format('HH:MM')
        //   return <div>{date}</div>
        // },
        sortable: false,
        left: true
      },
      {
        name: 'Audit',
        selector: 'attributes.audits_count',
        sortable: true,
        center: true
      },
      {
        name: 'Thermal',
        selector: 'attributes.category',
        cell: (row) => {
          const category = row.attributes.category
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${category ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      },
      {
        name: 'EPC',
        selector: 'attributes.category',
        cell: (row) => {
          const category = row.attributes.category
          const epc = category === "epc" || category === "both" ? true : false
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${epc ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      },
      // {
      //   name: 'Assigned',
      //   selector: 'attributes.is_assigned',
      //   cell: (row) => {
      //     const isAssigned = row.attributes.is_assigned
      //     return (
      //       <div className='table__cell cell__assigned'>
      //         <i className={`icons i17x17 icon-checkbox ${isAssigned ? 'checked' : ''}`} />
      //       </div>
      //     )
      //   },
      //   sortable: true,
      //   center: true
      // },
      {
        name: 'DEA',
        selector: 'attributes.engineer.name',
        sortable: true,
        center: true
      },
      {
        name: 'Status',
        selector: 'attributes.status',
        cell: (row) => {
          const accepted = row.attributes.status === 'accepted'
          const rejected = row.attributes.status === 'rejected'
          const status = accepted ? 'accept' : (rejected ? 'decline' : 'no-response')
          console.log('STATUS', status, accepted)
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i24x24 icon-${status}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      },
      {
        name: 'Completed',
        selector: 'attributes.is_completed',
        cell: (row) => {
          const isCompleted = row.attributes.is_completed
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${isCompleted ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      },
      {
        name: 'Reports',
        selector: 'attributes.is_reports',
        cell: (row) => {
          const isReports = row.attributes.is_reports
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${isReports ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        right: true
      }
    ],
    engineer: (onChange, selected, employers) => {
      return [
        {
          name: 'Survey No.',
          selector: 'attributes.survey_reference',
          sortable: true
        },
        {
          name: <Select onChange={onChange} selected={selected} data={employers} title='Own surveys' />,
          cell: (row) => {
            const employer = row.attributes.employer && row.attributes.employer.name
            return <div>{employer || 'Own survey'}</div>
          },
          right: false
        },
        {
          name: 'Customer',
          selector: 'attributes.customer.contact_name',
          sortable: true,
          right: false
        },
        {
          name: 'Date',
          selector: 'attributes.start_date',
          cell: (row) => {
            const date = moment(row.attributes.start_date).format('DD/MM/YYYY')
            return <div>{date}</div>
          },
          sortable: true,
          left: true
        },
        {
          name: 'Time',
          selector: 'attributes.start_time',
          // cell: (row) => {
          //   const date = moment(row.attributes.start_date).format('HH:MM')
          //   return <div>{date}</div>
          // },
          sortable: false,
          left: true
        },
        {
          name: 'Audit',
          selector: 'attributes.audits_count',
          sortable: true,
          center: true
        },
        {
          name: 'Thermal',
          selector: 'attributes.category',
          cell: (row) => {
            const category = row.attributes.category
            return (
              <div className='table__cell cell__assigned'>
                <i className={`icons i17x17 icon-checkbox ${category ? 'checked' : ''}`} />
              </div>
            )
          },
          sortable: true,
          center: true
        },
        {
          name: 'EPC',
          selector: 'attributes.category',
          cell: (row) => {
            const category = row.attributes.category
            const epc = category === "epc" || category === "both" ? true : false
            return (
              <div className='table__cell cell__assigned'>
                <i className={`icons i17x17 icon-checkbox ${epc ? 'checked' : ''}`} />
              </div>
            )
          },
          sortable: true,
          center: true
        },
        {
          name: 'Completed',
          selector: 'attributes.is_completed',
          cell: (row) => {
            const isCompleted = row.attributes.is_completed
            return (
              <div className='table__cell cell__assigned'>
                <i className={`icons i17x17 icon-checkbox ${isCompleted ? 'checked' : ''}`} />
              </div>
            )
          },
          sortable: true,
          center: true
        },
        {
          name: 'Reports',
          selector: 'attributes.is_reports',
          cell: (row) => {
            const isReports = row.attributes.is_reports
            return (
              <div className='table__cell cell__assigned'>
                <i className={`icons i17x17 icon-checkbox ${isReports ? 'checked' : ''}`} />
              </div>
            )
          },
          sortable: true,
          right: true
        }
      ]
    }
  },
  epc_surveys: {
    employer: [
      {
        name: 'Survey  No.',
        selector: 'attributes.survey_reference',
        sortable: true
      },
      {
        name: 'Customer',
        selector: 'attributes.customer.full_name', // TODO: wait until backend will fix it
        // selector: 'attributes.survey_reference',
        sortable: true,
        right: false
      },
      {
        name: 'Date',
        selector: 'attributes.start_date',
        // cell: (row) => {
        //   const date = moment(row.attributes.start_date).format('DD/MM/YYYY')
        //   return <div>{date}</div>
        // },
        sortable: true,
        left: true
      },
      {
        name: 'Time',
        selector: 'attributes.start_time',
        // cell: (row) => {
        //   const date = moment(row.attributes.start_date).format('HH:MM')
        //   return <div>{date}</div>
        // },
        sortable: false,
        left: true
      },
      {
        name: 'Audit',
        selector: 'attributes.audits_count',
        sortable: true,
        center: true
      },
      {
        name: 'Assigned',
        selector: 'attributes.is_assigned',
        cell: (row) => {
          const isAssigned = row.attributes.is_assigned
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${isAssigned ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      },
      {
        name: 'Completed',
        selector: 'attributes.is_completed',
        cell: (row) => {
          const isCompleted = row.attributes.is_completed
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${isCompleted ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        center: true
      },
      {
        name: 'Reports',
        selector: 'attributes.is_reports',
        cell: (row) => {
          const isReports = row.attributes.is_reports
          return (
            <div className='table__cell cell__assigned'>
              <i className={`icons i17x17 icon-checkbox ${isReports ? 'checked' : ''}`} />
            </div>
          )
        },
        sortable: true,
        right: true
      }
    ],
    engineer: (onChange, selected, employers) => {
      return [
        {
          name: 'Survey No.',
          selector: 'attributes.survey_reference',
          sortable: true
        },
        {
          name: <Select onChange={onChange} selected={selected} data={employers} title='Own surveys' />,
          cell: (row) => {
            const employer = row.attributes.employer && row.attributes.employer.name
            return <div>{employer || 'Own survey'}</div>
          },
          right: false
        },
        {
          name: 'Customer',
          selector: 'attributes.customer.contact_name',
          sortable: true,
          right: false
        },
        {
          name: 'Date',
          selector: 'attributes.start_date',
          // cell: (row) => {
          //   const date = moment(row.attributes.start_date).format('DD/MM/YYYY')
          //   return <div>{date}</div>
          // },
          sortable: true,
          left: true
        },
        {
          name: 'Time',
          selector: 'attributes.start_time',
          // cell: (row) => {
          //   const date = moment(row.attributes.start_date).format('HH:MM')
          //   return <div>{date}</div>
          // },
          sortable: false,
          left: true
        },
        {
          name: 'Audit',
          selector: 'attributes.audits_count',
          sortable: true,
          center: true
        },
        {
          name: 'Completed',
          selector: 'attributes.is_completed',
          cell: (row) => {
            const isCompleted = row.attributes.is_completed
            return (
              <div className='table__cell cell__assigned'>
                <i className={`icons i17x17 icon-checkbox ${isCompleted ? 'checked' : ''}`} />
              </div>
            )
          },
          sortable: true,
          center: true
        },
        {
          name: 'Reports',
          selector: 'attributes.is_reports',
          cell: (row) => {
            const isReports = row.attributes.is_reports
            return (
              <div className='table__cell cell__assigned'>
                <i className={`icons i17x17 icon-checkbox ${isReports ? 'checked' : ''}`} />
              </div>
            )
          },
          sortable: true,
          right: true
        }
      ]
    }
  },
  engineers: [
    {
      name: 'Engineer',
      selector: 'attributes.name',
      sortable: true
    },
    {
      name: 'Phone Number',
      selector: 'attributes.phone_number',
      sortable: false
    },
    {
      name: 'Work Email',
      selector: 'attributes.email',
      sortable: false,
      minWidth: '350px'
    },
    {
      name: 'Added',
      selector: 'attributes.added',
      cell: (row) => {
        const date = moment(row.attributes.added).isValid()
          ? moment(row.attributes.added).format('DD/MM/YYYY')
          : row.attributes.added
        return <div>{date}</div>
      },
      sortable: false,
      left: true
    },
    {
      name: 'Last active',
      selector: 'attributes.last_active',
      cell: (row) => {
        const date = moment(row.attributes.logined_at).isValid()
          ? moment(row.attributes.logined_at).format('DD/MM/YYYY')
          : row.attributes.logined_at
        return <div>{date}</div>
      },
      sortable: true,
      left: true
    },
    {
      name: 'Report count',
      selector: 'attributes.surveys_count',
      sortable: true
    }
  ],
  employers: [
    {
      name: 'Company',
      selector: 'attributes.company_name',
      sortable: true
    },
    {
      name: 'Contact',
      selector: 'attributes.name',
      sortable: false
    },
    {
      name: 'Phone Number',
      selector: 'attributes.phone_number',
      sortable: false
    },
    {
      name: 'Email',
      selector: 'attributes.email',
      sortable: false
    },
    {
      name: 'Added',
      selector: 'attributes.added',
      cell: (row) => {
        const date = moment(row.attributes.added).isValid()
          ? moment(row.attributes.added).format('DD/MM/YYYY')
          : row.attributes.added
        return <div>{date}</div>
      },
      sortable: false,
      left: true
    }
  ],
  users: (onChange, selected) => {
    return [
      {
        name: 'User',
        selector: 'attributes.name',
        sortable: false
      },
      {
        name: 'Email',
        selector: 'attributes.email',
        sortable: false
      },
      {
        name: 'Company',
        selector: 'attributes.company_name',
        sortable: true
      },
      {
        name: (
          <Select
            onChange={onChange}
            selected={selected}
            data={[
              { attributes: { id: 'employer', name: 'Employers' } },
              { attributes: { id: 'engineer', name: 'Engineers' } }
            ]}
          />
        ),
        cell: ({attributes}) => {
          return <div className='capitalize'>{attributes.role}</div>
        },
        right: false
      },
      {
        name: 'Last Active',
        cell: ({ attributes }) => (
          <div>
            {attributes.logined_at && moment(attributes.logined_at).format('DD/MM/YYYY')}
          </div>
        ),
        sortable: false,
        left: true
      },
      {
        name: 'Report Count',
        selector: 'attributes.reports_overall',
        sortable: false,
        left: true
      }
    ]
  },
  admins: [
    {
      name: 'Full Name',
      selector: 'attributes.name',
      sortable: true
    },
    {
      name: 'Email',
      selector: 'attributes.email',
      sortable: false
    },
    {
      name: 'Created',
      selector: 'attributes.created_data',
      sortable: true
    }
  ]
}
