import React from 'react'
import { arrayOf, any, element, object, func, string } from 'prop-types'
import { connect } from 'react-redux'
import Footer from './Footer'
import logo from '../assets/img/logo_side.png'
import logo_2x from '../assets/img/logo_side@2x.png'
import logo_3x from '../assets/img/logo_side@3x.png'
import SidebarItem from './SidebarItem'
import { ROUTE_TO_LOGIN } from '../constants/routes'
import { removeToken } from '../utils/tokenCRUD'
import { removeUser } from '../utils/currentUserCRUD'
import { logoutAction } from '../containers/Auth/actions'
import { useNavigate } from "react-router-dom-v5-compat"

const StaticPage = ({ children, sidebarData, header, onLogout, pageClass }) => {
  const navigate = useNavigate()

  const onLogoutHandler = () => {
    onLogout()
      .then(() => {
        removeToken()
        removeUser()
        navigate(ROUTE_TO_LOGIN)
      })
      .catch((e) => console.error(e))
  }
  return (
    <>
      {header}
      <div className={`site__width ${pageClass}`}>
        <div className='content__wrapper sidebar'>
          <div className='content__main'>{children}</div>
        </div>
      </div>
      <aside className='sidebar__main'>
        <div className='sidebar__logo'>
          <a className='btns btn-i' href='/'>
            <img src={logo} srcSet={`${logo} 1x, ${logo_2x} 2x, ${logo_3x} 3x`} alt='ThermaFy' />
          </a>
        </div>
        {sidebarData ? (
          <nav className='sidebar__nav'>
            <ul className='sidebar__nav--list'>
              {sidebarData.map((item) => (
                <SidebarItem item={item} key={item.title} />
              ))}
              <li className='sidebar__nav--item'>
                <a href={ROUTE_TO_LOGIN} className='sidebar__nav--link btns btn-b' onClick={onLogoutHandler}>
                  <i className='icons i24x24 icon-logout' />
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        ) : null}
      </aside>
      <Footer />
    </>
  )
}

StaticPage.propTypes = {
  children: arrayOf(any),
  sidebarData: arrayOf(object),
  header: element,
  onLogout: func.isRequired,
  pageClass: string
}

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(logoutAction())
})

export default connect(null, mapDispatchToProps)(StaticPage)
