import typeToReducer from 'type-to-reducer'
import { GET_ADMINS, GET_ADMIN_BY_ID, DELETE_ADMIN_BY_ID, CREATE_ADMIN, UPDATE_ADMIN } from './constants'

export const initialState = {
  inProcess: false,
  deleteInProgress: false,
  updateInProgress: false,
  per_page: null,
  total_pages: null,
  count: 0,
  admin: {},
  admins: [],
  errors: null
}

export default typeToReducer(
  {
    [GET_ADMINS]: {
      START: (state, action) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          admins: action.payload.data.admins.data,
          per_page: action.payload.data.per_page,
          total_pages: action.payload.data.total_pages,
          count: action.payload.data.count,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [GET_ADMIN_BY_ID]: {
      START: (state, action) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          inProcess: false,
          admin: action.payload.data.admin.data.attributes
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          admin: {},
          inProcess: false
        }
      }
    },
    [DELETE_ADMIN_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          deleteInProgress: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          deleteInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          deleteInProgress: false
        }
      }
    },
    [CREATE_ADMIN]: {
      START: (state) => {
        return {
          ...state,
          updateInProgress: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          updateInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          updateInProgress: false
        }
      }
    },
    [UPDATE_ADMIN]: {
      START: (state) => {
        return {
          ...state,
          updateInProgress: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          updateInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          updateInProgress: false
        }
      }
    }
  },
  initialState
)
