import { createAction } from 'redux-actions'
import {
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  RECOVERY_PASSWORD,
  GET_CURRENT_USER
} from './constants'

import {
  loginApi,
  logoutApi,
  forgotPasswordApi,
  recoveryPasswordApi,
  getCurrentUserApi
} from './api'

export const loginAction = createAction(LOGIN, async (data) => loginApi(data))
export const logoutAction = createAction(LOGOUT, async () => logoutApi())
export const getCurrentUserAction = createAction(GET_CURRENT_USER, async () => getCurrentUserApi())
export const forgotPasswordAction = createAction(FORGOT_PASSWORD, async (email) => forgotPasswordApi(email))
export const recoveryPasswordAction = createAction(RECOVERY_PASSWORD, async (data) => recoveryPasswordApi(data))
