import api from '../../utils/api'
import apiSubmissionError from '../../utils/apiSubmissionError'

export const getEngeneersApi = (params) => api.get('/managers/employer_engineers', { params })

export const getEngineerByIdApi = (id) => api.get(`/managers/employer_engineers/${id}`)

export const deleteEngineerByIdApi = (params) => api.delete('managers/associations/remove', { params }).catch(apiSubmissionError)

export const createEngineerApi = (data) => api.post('/managers/associations', data).catch(apiSubmissionError)

export const updateEngineerApi = (id, data) => api.put(`/managers/employer_engineers/${id}`, data).catch(apiSubmissionError)
