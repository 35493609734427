import React from 'react'
import { func, objectOf, any, string } from 'prop-types'

const ChangeSettingsForm = ({ handleSubmit, value, title, name, handleChange }) => {
  return (
    <div className='tabs__content ui-tabs-panel ui-corner-bottom ui-widget-content' label='Privacy policy'>
      <form
        className='formDefault'
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}>
        <div className='formDefault__header clearfix'>
          <h1 className='formDefault__header--title'>{title}</h1>
        </div>
        <div className='formDefault__body'>
          <div className='formDefault__row'>
            <textarea
              className='formDefault__textarea'
              type='text'
              name={name}
              rows='10'
              placeholder='Start type here…'
              onChange={(e) => handleChange(e.target.value)}
              value={value && value.length ? value : ''}
            />
          </div>
          <div className='formDefault__row--btns'>
            <input className='btns btn-i btn-yellow' type='submit' name='submit' value='SAVE CHANGES' />
            <input className='btns btn-i btn-trns' type='reset' name='cencel' value='Cancel' />
          </div>
        </div>
      </form>
    </div>
  )
}

ChangeSettingsForm.propTypes = {
  handleSubmit: func.isRequired,
  handleChange: func.isRequired,
  value: objectOf(any),
  title: string,
  name: string
}

export default ChangeSettingsForm
