import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import React, { useEffect, useState } from 'react'
import { objectOf, bool, any, func, string } from 'prop-types'
import {
  errorNotificationAction,
  successNotificationAction
} from '../../Notifications/actions'
import {
  createUserAction,
  deleteUserByIdAction,
  getUserByIdAction,
  updateUserAction
} from '../actions'
import {
  ROUTE_TO_CUSTOMERS,
  ROUTE_TO_ROOT,
  ROUTE_TO_USERS
} from '../../../constants/routes'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import Spinner from '../../../components/Spinner'
import navData from '../../../constants/navData'
import UserForm from './UserForm'
import history from '../../../history'
import validate from './validate'
import DeletePopup from '../../../components/DeletePopup'
import UserDashboard from '../../Dashboard/UserDashboard'
// import DashboardGrid from '../../Dashboard/DashboardGrid'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Users',
    link: ROUTE_TO_CUSTOMERS
  }
]

const Header = ({ user }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={user} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  user: string.isRequired
}
// One component for create and edit user
const UserUpdate = ({
  match,
  getUser,
  inProcess,
  successNotification,
  errorNotification,
  role,
  createUser,
  updateUser,
  updateInProcess,
  deleteInProgress,
  initialValues,
  deleteUser,
  ...formProps
}) => {
  const { id, name } = match.params
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  useEffect(() => {
    getUser(id)
  }, [])

  const onDeleteHandler = async () => {
    try {
      await deleteUser(id)
      history.push(ROUTE_TO_USERS)
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit = async ({ id: userId, name, email, phone_number, company_name, address, post_code }) => {
    const data = { user: { name, email, phone_number, company_name, address, post_code } }
    // eslint-disable-next-line no-unused-expressions
    await updateUser(userId, data)
    successNotification('User successfully updated')
    history.push(ROUTE_TO_USERS)
  }

  return (
    <StaticPage sidebarData={navData[role]} header={<Header user={initialValues.contact_name} />}>
      {/*<DashboardGrid data={} />*/}
      <div className='user__page pToppBottom'>
        <section className='user__page--section section__user section__border user'>
          {inProcess ? (
            <Spinner />
          ) : (
            <div>
              <UserDashboard userId={id} name={name} />

              <UserForm
                onSubmit={onSubmit}
                {...formProps}
                initialValues={initialValues}
                deleteInProgress={deleteInProgress}
                showDeletePopup={showDeletePopup}
                setShowDeletePopup={setShowDeletePopup}
              />

              {initialValues.created_at ? (
                <div className='user__page--created'>
                  <strong>Created</strong> At {moment(initialValues.created_at).format('DD/MM/YYYY')}
                </div>
              ) : null}
            </div>
          )}
        </section>
      </div>
      <DeletePopup
        onDelete={onDeleteHandler}
        onCancel={() => setShowDeletePopup(false)}
        isOpen={showDeletePopup}
        target='User'
      />
    </StaticPage>
  )
}

const mapStateToProps = ({ users, auth }) => ({
  initialValues: users.user,
  inProcess: users.inProcess,
  updateInProcess: users.updateInProcess,
  deleteInProgress: users.deleteInProgress,
  role: auth.user && auth.user.role
})

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(getUserByIdAction(id)),
  createUser: (data) => dispatch(createUserAction(data)),
  updateUser: (id, data) => dispatch(updateUserAction(id, data)),
  deleteUser: (id) => dispatch(deleteUserByIdAction(id)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message))
})

UserUpdate.propTypes = {
  match: objectOf(any),
  initialValues: objectOf(any),
  getUser: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  inProcess: bool.isRequired,
  deleteInProgress: bool.isRequired,
  role: string,
  createUser: func,
  deleteUser: func,
  updateUser: func,
  updateInProcess: bool
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'user_update',
    shouldError: () => true,
    enableReinitialize: true,
    validate
  })
)(UserUpdate)
