import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { any, func, bool, objectOf, string } from 'prop-types'
import {
  ROUTE_TO_ROOT,
  ROUTE_TO_SURVEYS
} from '../../../constants/routes'
import {
  getSurveyByIdAction,
  deleteSurveyByIdAction
} from '../actions'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import SiteHeader from '../../../components/SiteHeader'
import AuditItem from './AuditItem'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Surveys',
    link: ROUTE_TO_SURVEYS
  }
];

const Header = ({ role }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role}/>
      <ProfileMenu />
    </SiteHeader>
  );
};

Header.propTypes = {
  role: string
};

const SurveyDetails = ({ survey, inProgress, deleteInProgress, getSurvey, deleteSurvey, role, match }) => {
  const { id } = match.params

  useEffect(() => {
    getSurvey(id)
  }, [])

  if (!survey.id) return (<div/>)

  const {
    customer,
    audits,
  } = survey

  return (
    <div className="formDefault fullWidth viewForm">
      <div className="formDefault__body">
        <div className="survey__page--audits">
          <div className="audits__list">
            <div className='formDefault__header clearfix'>
              { audits && audits.length!==0 ?
                  (
                    <h1 className="formDefault__header--title">
                      Audit Evaluation
                    </h1>
                  )
                :
                  (
                    <h1 className="formDefault__header--title">
                      Needs to perform Audit Evaluation!!!
                    </h1>
                  )
              }
            </div>

            { audits && audits.map((item, index) =>
              <AuditItem key={index} auditNo={index + 1} audit={item}  />)
            }
          </div>
        </div>
      </div>
    </div>
  )
}

SurveyDetails.propTypes = {
  survey: objectOf(any),
  inProgress: bool,
  deleteInProgress: bool,
  role: string,
  getSurvey: func.isRequired,
  deleteSurvey: func.isRequired,
  match: objectOf(any)
}

const mapStateToProps = ({ surveys, auth }) => {
  return {
    survey:           surveys.survey,
    inProgress:       surveys.inProgress,
    deleteInProgress: surveys.deleteInProgress,
    role:             auth.user && auth.user.role
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSurvey:    (id) => dispatch(getSurveyByIdAction(id)),
  deleteSurvey: (id) => dispatch(deleteSurveyByIdAction(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyDetails)
