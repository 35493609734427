import React from 'react'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { bool, func, string } from 'prop-types'

import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_CUSTOMERS } from '../../../constants/routes'
import fieldTrimNormalizer from '../../../utils/fieldTrimNormalizer';

const CustomerForm = ({ handleSubmit, onSubmit, submitting, error, isEdit, initialValues, employers, role }) => {
  return (
    <form className='formDefault fullWidth' onSubmit={handleSubmit(onSubmit)}>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>Add new Customer</h1>
      </div>
      <div className='formDefault__body'>
        <div className='formDefault__body--columns clearfix'>
          <div className='formDefault__body--column column2'>
            <div className='formDefault__title'>Customer Info</div>
            {role === 'engineer' ? (
              <div className='formDefault__row custom-select'>
                {isEdit ? (
                  <>
                    <label className='form__label formDefault__label'>Employer</label>
                    <div className='formDefault__input not-allowed'>
                      {(initialValues.employer && initialValues.employer.name) || 'Own job'}
                    </div>
                  </>
                ) : (
                  <Field
                    component={ReduxFormField}
                    type='select'
                    label='Choose Employer'
                    className='formDefault__select'
                    name='employer'
                    defaultValue={initialValues.id}
                    errorNoSpace>
                    <option value=''>Own customer</option>
                    {employers.map(({ attributes: { id, name } }) => {
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      )
                    })}
                  </Field>
                )}
              </div>
            ) : null}
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Full Customer Name (Private or Company)'
                className='formDefault__input'
                type='text'
                name='full_name'
                id='customerFullName'
                placeholder='Full Customer Name (Private or Company)'
                errorNoSpace
                normalize={fieldTrimNormalizer}
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Customer Address'
                className='formDefault__input'
                type='text'
                name='address'
                id='customerAddress'
                placeholder='Customer Address'
                normalize={fieldTrimNormalizer}
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Customer PostCode'
                className='formDefault__input'
                type='text'
                name='post_code'
                id='customerPostCode'
                placeholder='Customer Post Code'
                errorNoSpace
              />
            </div>
          </div>
          <div className='formDefault__body--column column2'>
            <div className='formDefault__title'>Contact Info</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Contact Name'
                className='formDefault__input'
                type='text'
                name='contact_name'
                id='customerContactName'
                placeholder='Contact Name'
                normalize={fieldTrimNormalizer}
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Contact Phone Number'
                className='formDefault__input'
                type='number'
                name='contact_phone_number'
                id='customerContactPhone'
                placeholder='Contact Phone Number'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Contact Email '
                className='formDefault__input'
                type='email'
                name='contact_email'
                id='customerContactEmail'
                placeholder='Contact Email'
                errorNoSpace
              />
            </div>
          </div>
        </div>
        {error ? <p className='validation-error-message'>{error}</p> : null}
        <div className='formDefault__row--btns'>
          <button
            className={`btns btn-i btn-yellow ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Save'}
          </button>
          <Link className='btns btn-i btn-trns' to={ROUTE_TO_CUSTOMERS}>
            Cancel
          </Link>
        </div>
      </div>
    </form>
  )
}

CustomerForm.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  submitting: bool.isRequired,
  error: string,
  role: string
}

export default CustomerForm
