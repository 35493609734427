import React from 'react'
import { arrayOf, element } from 'prop-types'

import AccountHeader from './AccountHeader'
import Footer from './Footer'

import logo_icon from '../assets/img/logo_icon.jpg'
import logo_icon_2x from '../assets/img/logo_icon@2x.jpg'
import logo_icon_3x from '../assets/img/logo_icon@3x.jpg'

export default function AccountFormWrapper({ children }) {
  return (
    <>
      <AccountHeader />
      <div className='signin__page pToppBottom'>
        <div className='signin__page--content'>
          <div className='signin__page--logo'>
            <img src={logo_icon} srcSet={`${logo_icon} 1x, ${logo_icon_2x} 2x, ${logo_icon_3x} 3x`} alt='ThermaFy' />
          </div>
          {children}
        </div>
      </div>
      <Footer />
    </>
  )
}

AccountFormWrapper.propTypes = {
  children: arrayOf(element).isRequired
}
