export const ROUTE_TO_ROOT = '/'

// auth
export const ROUTE_TO_LOGIN = '/auth/login'
export const ROUTE_TO_LOGOUT = '/auth/logout'
export const ROUTE_TO_FORGOT_PASSWORD = '/auth/forgot_password'
export const ROUTE_TO_RECOVERY_PASSWORD = '/auth/recovery'

// info
export const ROUTE_TO_TERMS_AND_CONDITIONS = 'https://thermafy.com/terms-conditions/'
export const ROUTE_TO_PRIVACY_POLICY = 'https://thermafy.com/privacy-policy/'

// managers
export const ROUTE_TO_CREATE_ACCOUNT = '/create_account'
export const ROUTE_TO_PROFILE = '/profile'

// customers
export const ROUTE_TO_CUSTOMERS = '/customers'
export const ROUTE_TO_CUSTOMER_SINGLE = '/customers/:id'
export const routeToCustomerSingle = (id) => `/customers/${id}`
export const ROUTE_TO_CUSTOMER_UPDATE = '/customer/:id?'
export const routeToCustomerUpdate = (id) => (id ? `/customer/${id}` : '/customer')

// jobs
export const ROUTE_TO_JOBS = '/jobs'
export const ROUTE_TO_JOB_SINGLE = '/jobs/:id'
export const routeToJobSingle = (id) => `/jobs/${id}`
export const ROUTE_TO_JOB_UPDATE = '/job/:id?'
export const routeToJobUpdate = (id) => (id ? `/job/${id}` : '/job')

// Thermal surveys
export const ROUTE_TO_SURVEYS = '/surveys'
export const ROUTE_TO_SURVEY_SINGLE = '/surveys/:id'
export const routeToSurveySingle = (id) => `/surveys/${id}`
export const ROUTE_TO_SURVEY_UPDATE = '/survey/:id?'
export const routeToSurveyUpdate = (id) => (id ? `/survey/${id}` : '/survey')

// EPC surveys
export const ROUTE_TO_EPC_SURVEYS = '/epc_surveys'
export const ROUTE_TO_EPC_SURVEY_SINGLE = '/epc_surveys/:id'
export const routeToEPCSurveySingle = (id) => `/epc_surveys/${id}`
export const ROUTE_TO_EPC_SURVEY_UPDATE = '/epc_survey/:id'
export const routeToEPCSurveyUpdate = (id) => (id ? `/epc_survey/${id}` : '/epc_survey')
export const ROUTE_TO_UPDATE_EPC_DETAILS = (id) => '/epc_survey/:id'
export const routeToUpdateEPCDetails = (id) => (id ? `/survey/${id}` : '/survey')

// engineers
export const ROUTE_TO_ENGINEERS = '/employer_engineers'
export const ROUTE_TO_ENGINEER_SINGLE = '/employer_engineers/:id'
export const routeToEngineerSingle = (id) => `/employer_engineers/${id}`
export const ROUTE_TO_ENGINEER_UPDATE = '/employer_engineer/:id?'
export const routeToEngineerUpdate = (id) => (id ? `/employer_engineer/${id}` : '/employer_engineer')

// employers
export const ROUTE_TO_EMPLOYERS = '/employers'
export const ROUTE_TO_EMPLOYER_SINGLE = '/employers/:id'
export const routeToEmployerSingle = (id) => `/employers/${id}`

// contact page
export const ROUTE_TO_CONTACT = '/contact'

// verify success page
export const ROUTE_TO_VERIFY_CONGRATS = '/verify_done/:token'

// users
export const ROUTE_TO_USERS = '/users'
export const routeToUserSingle = (id) => `/users/${id}`
export const ROUTE_TO_USER_UPDATE = '/user/:id?'
export const routeToUserUpdate = (id) => (id ? `/user/${id}` : '/user')
export const ROUTE_TO_ADMIN_USER_DAHSBOARD = '/dashboard/super_admin_dashboard/user_dashboard'
export const routeToAdminUserDashboard = (id) => (id ? `/admins/users/dashboard/${id}` : '/dashboard/super_admin_dashboard/user_dashboard')

// system settings
export const ROUTE_TO_SYSTEM_SETTINGS = '/admin/settings'

// admins
export const ROUTE_TO_ADMINS = '/admin'
export const routeToAdminSingle = (id) => `/admin/${id}`
export const ROUTE_TO_ADMIN_UPDATE = '/admin/:id?'
export const routeToAdminUpdate = (id) => (id ? `/admin/${id}` : '/admin')

