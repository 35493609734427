import React from 'react'
import { objectOf, any } from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROUTE_TO_PROFILE } from '../constants/routes'

const ProfileMenu = ({ currentUser }) => {
  return (
    <div className='header__main--profile'>
      <p className='header__main--name'>
        <Link to={ROUTE_TO_PROFILE}>
          {currentUser ? currentUser.name || currentUser.email : ' '}
          <i className='icons i24x24 arrow-down_full' />
        </Link>
      </p>
    </div>
  )
}

ProfileMenu.propTypes = {
  currentUser: objectOf(any),
  //error: string
}

const mapStateToProps = ({ auth }) => ({ currentUser: auth.user })

export default connect(mapStateToProps)(ProfileMenu)
