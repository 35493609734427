import React from 'react'
import { string, func } from 'prop-types'

const Tab = ({ activeTab, label, onClick }) => {
  const onClickHandler = () => {
    onClick(label)
  }
  let className = 'tabs__item'

  if (activeTab === label) {
    className += ' ui-tabs-active'
  }
  return (
    <li className={className}>
      <button type='button' onClick={onClickHandler} className='btns btn-i'>
        {label}
      </button>
    </li>
  )
}

Tab.propTypes = {
  activeTab: string.isRequired,
  label: string.isRequired,
  onClick: func.isRequired
}
export default Tab
