import React from 'react'
import { connect } from 'react-redux'
import { string, func } from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field, reset } from 'redux-form'
import { errorNotificationAction, successNotificationAction } from '../Notifications/actions'
import { sendContactMessageAction } from './actions'
import ProfileMenu from '../../components/ProfileMenu'
import StaticPage from '../../components/StaticPage'
import SiteHeader from '../../components/SiteHeader'
import navData from '../../constants/navData'

const Header = () => {
  return (
    <SiteHeader additionaClassname='pull_to_end'>
      <ProfileMenu />
    </SiteHeader>
  )
}

const ContactUs = ({ role, handleSubmit, sendMessage, successNotification, errorNotification, resetForm }) => {
  const onSubmit = async (values) => {
    const res = await sendMessage(values)
    if (res.value.status === 200) {
      successNotification('Your message was sent.')
      resetForm()
    } else {
      errorNotification('Something went wrong')
    }
  }
  return (
    <StaticPage sidebarData={navData[role]} header={<Header />}>
      <div className='contact__page'>
        <section className='contact__page--section section__border clearfix mTop'>
          <div className='section___column column__left '>
            <form onSubmit={handleSubmit(onSubmit)} className='contact__form'>
              <div className='form__title'>Contact us by completing our quick &amp; easy form</div>
              <div className='form__row row__textarea'>
                <Field component='textarea' className='form__input' name='body_message' placeholder='Message*' />
              </div>
              <div className='form__row row__submit'>
                <input className='form__button btns btn-i' type='submit' name='submit' value='Submit' />
              </div>
            </form>
          </div>
          <div className='section___column column__right'>
            <div className='contact__info'>
              <div className='section___column'>
                <div className='info__icon'>
                  <i className='icons i72x72 icon-email' />
                </div>
                <h3 className='info__title'>Email</h3>
                <div className='info__description'>
                  Need to email us directly? Please allow within 1 business day for response.
                </div>
                <div className='info__text'>
                  Email us at:
                  <a href='mailto:support@thermafy.com'>support@thermafy.com</a>
                </div>
              </div>
              <div className='section___column'>
                <div className='info__icon'>
                  <i className='icons i72x72 icon-phone' />
                </div>
                <h3 className='info__title'>Phone</h3>
                <div className='info__description'>
                  Always available during business hours to help! The best way to get you connected
                </div>
                <div className='info__text'>
                  Call us at:
                  <a href='tel:+44(0)1-573-219350'>+44 (0)1-573-219350</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </StaticPage>
  )
}

ContactUs.propTypes = {
  role: string,
  handleSubmit: func.isRequired,
  sendMessage: func.isRequired,
  successNotification: func,
  errorNotification: func,
  resetForm: func
}

const mapStateToProps = ({ auth }) => {
  const data = {}

  const { user } = auth
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (data) => dispatch(sendContactMessageAction(data)),
  resetForm: () => dispatch(reset('contactForm')),
  // Notifications
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'contactForm',
    enableReinitialize: true
  })
)(ContactUs)
