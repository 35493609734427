/* eslint-disable no-underscore-dangle */
import { SubmissionError } from 'redux-form'

export default ({ response }) => {
  const { data, status } = response
  const errors = {}

  if (data.error_message) {
    errors._error =
      typeof data.error_message === 'object'
        ? data.error_message[Object.keys(data.error_message)[0]]
        : data.error_message
    errors.status = status
  } else if (data.error) {
    errors._error = data.error
    errors.status = status
  } else {
    errors._error = 'Something went wrong. Try later.'
    errors.status = status
  }

  if (Object.keys(errors).length) {
    throw new SubmissionError(errors)
  }
}
