import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { any, arrayOf, bool, func, object, objectOf, string } from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_SURVEYS } from '../../../constants/routes'
import TimePicker from '../../../components/TimePicker'
import DatePicker from '../../../components/DatePicker'
import fieldTrimNormalizer from '../../../utils/fieldTrimNormalizer'

const SurveyFormEngineer = ({
  handleSubmit,
  onSubmit,
  submitting,
  error,
  employers,
  customers,
  initialValues,
  getCustomerDetails,
  getEmployers,
  getCustomers,
  onDelete,
  getEmployer,
  isEdit,
  setFieldVal
}) => {
  const [employersList, setEmployersList] = useState([])
  const [customersList, setCustomersList] = useState([])
  const [showCustomerDetails, setShowCustomerDetails] = useState(false)
  const formattedDate = initialValues.start_date ? moment(initialValues.start_date, 'YYYY-MM-DD').format('DD-MM-YYYY'):new Date()

  const customerDetailsHandler = async (id) => {
    await getCustomerDetails(id)
    setShowCustomerDetails(true)
  }

  const onEmployerSelect = async (id) => {
    await getEmployer(id)
      .then((res) => {
        const refId = _.get(res, 'value.data.user.data.attributes.reference_id')
        if (refId) {
          setFieldVal('survey_reference', refId)
        } else {
          setFieldVal('survey_reference', '')
        }
        setFieldVal()
      })
      .catch(() => {
        setFieldVal('survey_reference', '')
      })
    getCustomers()
  }

  // useEffect(() => {
  //   getEmployers()
  //   getCustomers()
  // }, [])

  useEffect(() => {
    if (employers && employers.length) {
      setEmployersList(employers.map((item) => (
        { id: item.id, name: item.attributes.name }
      )))
    }
  }, [employers])

  useEffect(() => {
    if (customers && customers.length) {
      setCustomersList(customers.map((item) => (
        { id: item.id, name: item.attributes.full_name }
      )))
    }
    return ()=>{

    }
  }, [customers])

  return (
    <form className='formDefault fullWidth' onSubmit={handleSubmit(onSubmit)}>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>
          {isEdit ? 'Edit Survey' : 'Create Survey'}
        </h1>

        <div className='formDefault__header--btns'>
          {isEdit ? (
            <button
              className='btns btn-i btn-delete'
              data-toggle='class'
              data-target='#popups'
              data-classes='userdelete'
              type='button'
              onClick={onDelete}>
              Delete this Survey
            </button>
          ) : null}
        </div>
      </div>

      <div className='formDefault__body'>
        <div className='formDefault__body--columns clearfix'>
          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Customer Info</div>
            <div className='formDefault__row custom-select'>
              {isEdit ? (
                <>
                  <label className='form__label formDefault__label not-allowed'>
                    Employer
                  </label>
                  <div className='formDefault__input not-allowed'>
                    {(initialValues.employer && initialValues.employer.name) || 'Own survey'}
                  </div>
                </>
              ) : (
                <Field
                  component={ReduxFormField}
                  type='select'
                  label='Choose Employer'
                  className='formDefault__select'
                  name='employer'
                  defaultValue={initialValues ? initialValues.id : ''}
                  errorNoSpace
                  id='surveyEngSelect'
                  onChangeHandler={onEmployerSelect}>
                  <option value=''>Own survey</option>
                  {employersList.map(({ id, name }) => {
                    return (
                      <option value={id}>
                        {name}
                      </option>
                    )
                  })}
                </Field>
              )}
            </div>

            <div className='formDefault__row custom-select '>
              {isEdit ? (
                <>
                  <label className='form__label formDefault__label not-allowed'>
                    Full Customer Name (Private or Company)
                  </label>
                  <div className='formDefault__input not-allowed'>
                    {initialValues.full_name}
                  </div>
                </>
              ) : (
                <Field
                  component={ReduxFormField}
                  label='Full Customer Name (Private or Company)'
                  type='select'
                  className='formDefault__select'
                  name='customer_id'
                  errorNoSpace
                  onChangeHandler={customerDetailsHandler}>
                  <option disabled value=''>
                    Select Name
                  </option>
                  {customersList.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              )}
            </div>

            {showCustomerDetails || isEdit ? (
              <div className='not-allowed'>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>
                    Contact Name
                  </label>
                  <div className='formDefault__input'>
                    {initialValues.contact_name}
                  </div>
                </div>

                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>
                    Contact Phone Number
                  </label>
                  <div className='formDefault__input'>
                    {initialValues.contact_phone_number}
                  </div>
                </div>

                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>
                    Customer Email
                  </label>
                  <div className='formDefault__input'>
                    {initialValues.contact_email}
                  </div>
                </div>

                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>
                    Customer Postcode
                  </label>
                  <div className='formDefault__input'>
                    {initialValues.post_code}
                  </div>
                </div>

                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>
                    Customer Address
                  </label>
                  <div className='formDefault__input'>
                    {initialValues.address}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Survey Info</div>
            <div className='formDefault__row not-allowed'>
              <Field
                component={ReduxFormField}
                label='Survey Reference Number'
                className='formDefault__input'
                type='text'
                name='survey_reference'
                id='engineer_survey_reference'
                normalize={fieldTrimNormalizer}
                placeholder='Survey Reference Number'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Survey Description'
                className='formDefault__textarea'
                type='textarea'
                name='description'
                id='engineer_description'
                normalize={fieldTrimNormalizer}
                placeholder='Survey Description'
                errorNoSpace
              />
            </div>
          </div>

          <div className='formDefault__body--column column3'>
            <div className='formDefault__row'>
              <Field
                component={DatePicker}
                label='Survey Date'
                className='formDefault__input'
                defaultValue={formattedDate}
                name='start_date'
                errorNoSpace
              />
            </div>

            <div className='formDefault__row'>
              <Field
                component={TimePicker}
                label='Survey Time'
                className='formDefault__input time'
                defaultValue={initialValues ? initialValues.start_time : ''}
                name='start_time'
                id='timepicker'
                errorNoSpace
              />
            </div>

            <div className='formDefault__row custom-select'>
              <label className='formDefault__label'>Survey STATUS</label>
              <Field
                component='select'
                className='formDefault__select'
                name='is_completed'
                errorNoSpace
              >
                <option disabled='disabled'>Select Status</option>
                <option value=''>IN PROGRESS</option>
                <option value='1'>COMPLETED</option>
              </Field>
            </div>

            <div className='formDefault__row mt-20'>
              <Field
                component='input'
                className='form__checkbox'
                type='checkbox'
                name='thermal'
                id='thermalSurvey'
              />
              <label className='formDefault__label--checkbox' htmlFor='thermal'>
                Thermal
              </label>
            </div>

            <div className='formDefault__row mt-20'>
              <Field
                component='input'
                className='form__checkbox'
                type='checkbox'
                name='epc'
                id='epcSurvey'
              />
              <label className='formDefault__label--checkbox' htmlFor='epc'>
                EPC
              </label>
            </div>
          </div>
        </div>

        {error ? <p className='validation-error-message'>{error}</p> : null}

        <div className='formDefault__row--btns'>
          <button
            className={`btns btn-i btn-yellow ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Save'}
          </button>
          <Link className='btns btn-i btn-trns' to={ROUTE_TO_SURVEYS}>
            Cancel
          </Link>
        </div>
      </div>
    </form>
  )
}

SurveyFormEngineer.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  getCustomers: func.isRequired,
  getEmployers: func.isRequired,
  getEmployer: func.isRequired,
  onDelete: func.isRequired,
  getCustomerDetails: func.isRequired,
  submitting: bool.isRequired,
  isEdit: bool.isRequired,
  error: string,
  initialValues: objectOf(any),
  employers: arrayOf(object),
  customers: arrayOf(object),
  setFieldVal: func
}

export default SurveyFormEngineer
