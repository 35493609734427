import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { useEffect } from 'react'
import { any, func, bool, objectOf, string } from 'prop-types'
import {
  ROUTE_TO_ENGINEERS,
  ROUTE_TO_ROOT,
  routeToEngineerUpdate
} from '../../../constants/routes'
import {
  getEngineerByIdAction
} from '../actions';
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import SiteHeader from '../../../components/SiteHeader'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Engineers',
    link: ROUTE_TO_ENGINEERS
  }
];

const Header = ({ title }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={title}/>
      <ProfileMenu />
    </SiteHeader>
  );
};

Header.propTypes = {
  title: string.isRequired
};

const EngineerDetails = ({ engineer, inProgress, getEngineer, role, match }) => {
  const { id } = match.params;

  useEffect(() => {
    getEngineer(id);
  }, []);

  const {
    name,
    email,
    phone_number,
    address,
    post_code,
    added,
    logined_at,
    surveys_count,
    is_active,
    contact_email
  } = engineer;

  return (
    <div>
      <div className='formDefault fullWidth viewForm'>
        <div className='formDefault__header clearfix'>
          <h1 className='formDefault__header--title'>{name}</h1>
          <div className='formDefault__header--btns'>
            <Link className='btns btn-i btn-edit' to={routeToEngineerUpdate(id)}>
              EDIT ENGINEER
            </Link>
          </div>
        </div>
        {!inProgress ? (
          <div className='formDefault__body'>
            <div className='formDefault__body--columns clearfix'>
              <div className='formDefault__body--column column3'>
                <div className='formDefault__title'>
                  <strong>Address</strong>
                </div>
                <div className='formDefault__bg'>
                  <div className='formDefault__row'>
                    <div className='formDefault__text'>{address}</div>
                  </div>
                  <div className='formDefault__row'>
                    <div className='formDefault__text'>{post_code}</div>
                  </div>
                </div>
                {/* <div className='formDefault__title'>
                  <strong>Postal Address</strong>
                </div>
                <div className='formDefault__bg'>
                  <div className='formDefault__row'>
                    <div className='formDefault__text'>{address}</div>
                  </div>
                  <div className='formDefault__row'>
                    <div className='formDefault__text'>{post_code}</div>
                  </div>
                </div> */}
              </div>

              <div className='formDefault__body--column column3'>
                <div className='formDefault__title'>Contact Details</div>
                <div className='formDefault__bg'>
                  <div className='formDefault__row'>
                    <label className='formDefault__label'>Phone</label>
                    <div className='formDefault__text'>{phone_number}</div>
                  </div>
                  <div className='formDefault__title'>Email's</div>
                  <div className='formDefault__row'>
                    <label className='formDefault__label'>App Login</label>
                    <div className='formDefault__text'>{email}</div>
                  </div>
                  <div className='formDefault__row'>
                    <label className='formDefault__label'>Contact email</label>
                    <div className='formDefault__text'>{contact_email}</div>
                  </div>
                </div>
              </div>

              <div className='formDefault__body--column column3'>
                <div className='formDefault__title'>Additional Info</div>
                <div className='formDefault__bg'>
                  <div className='formDefault__row'>
                    <label className='formDefault__label'>Date Added</label>
                    <div className='formDefault__text'>{moment(added).format('DD/MM/YYYY')}</div>
                  </div>
                  <div className='formDefault__row'>
                    <label className='formDefault__label'>Last Active</label>
                    <div className='formDefault__text'>{moment(logined_at).format('DD/MM/YYYY')}</div>
                  </div>
                  <div className='formDefault__row'>
                    <label className='formDefault__label'>Number of Survey's</label>
                    <div className='formDefault__text'>{surveys_count}</div>
                  </div>
                  <div className='formDefault__row'>
                    <label className='formDefault__label'>Account Status</label>
                    <div className='formDefault__text'>{is_active===true ? 'Active' : 'Suspended'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

EngineerDetails.propTypes = {
  getEngineer: func.isRequired,
  engineer: objectOf(any),
  inProgress: bool,
  role: string,
  match: objectOf(any)
};

const mapStateToProps = ({ engeneers, auth }) => {
  return {
    engineer: engeneers.engineer,
    inProgress: engeneers.inProgress,
    role: auth.user && auth.user.role
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEngineer: (id) => dispatch(getEngineerByIdAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EngineerDetails);
