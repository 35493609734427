import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { string, func, objectOf, any } from 'prop-types'
import DashboardGrid from '../DashboardGrid'
import { getDashboardDataAction } from '../actions'

const EmployerDashboard = ({ name, getData, dashboardData }) => {
  useEffect(() => {
    getData()
  }, [])

  const ownDashboard =
    dashboardData.dashboard && dashboardData.dashboard.data.attributes ? dashboardData.dashboard.data.attributes : null

  return (
    <>
      <div className='page__header clearfix'>
        <h1 className='page__title'>
          <strong>Hello</strong>, Welcome Back {name}!
        </h1>
      </div>
      {ownDashboard ? <DashboardGrid data={ownDashboard} /> : null}
    </>
  )
}

EmployerDashboard.propTypes = {
  name: string,
  getData: func.isRequired,
  dashboardData: objectOf(any)
}

const mapStateToProps = ({ auth, dashboard }) => {
  const data = {
    dashboardData: dashboard.statistic
  }

  const { user } = auth
  if (user) data.name = user.name
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getDashboardDataAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployerDashboard)
