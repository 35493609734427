import React from 'react'
import { bool, objectOf, shape, string, any, func } from 'prop-types'

const ReduxFormField = ({
  input,
  label,
  type,
  placeholder,
  className,
  id,
  errorNoSpace,
  children,
  onClickHandler,
  onChangeHandler,
  defaultValue,
  meta: { touched, error }
}) => {
  if (type === 'select') {
    return (
      <div>
        <label className='form__label formDefault__label'>{label}</label>
        <select
          {...input}
          size="1"
          placeholder={placeholder}
          className={className}
          id={id}
          defaultValue={defaultValue}
          onClick={onClickHandler}
          onChange={(e) => {
            const { value } = e.target
            onChangeHandler && onChangeHandler(value)
            return input.onChange(value)
          }}>
          {children}
        </select>
        <p className={`validation-error-message ${touched && error ? '' : `${errorNoSpace ? 'hidden' : 'invisible'}`}`}>
          {error}
        </p>
      </div>
    )
  }
  if (type === 'textarea') {
    return (
      <div>
        <label className='form__label formDefault__label'>{label}</label>
        <textarea {...input} defaultValue={defaultValue} placeholder={placeholder} className={className} id={id} rows='10' />
        <p className={`validation-error-message ${touched && error ? '' : `${errorNoSpace ? 'hidden' : 'invisible'}`}`}>
          {error}
        </p>
      </div>
    )
  }

  return (
    <div>
      <label className='form__label formDefault__label' htmlFor={id}>
        {label}
      </label>
      <div className='form-field-wrapper'>
        <input {...input} defaultValue={defaultValue} placeholder={placeholder} type={type} className={className} id={id} />
        <p className={`validation-error-message ${touched && error ? '' : `${errorNoSpace ? 'hidden' : 'invisible'}`}`}>
          {error}
        </p>
      </div>
    </div>
  )
}

ReduxFormField.propTypes = {
  input: objectOf(any).isRequired,
  children: objectOf(any),
  label: string.isRequired,
  onClickHandler: func,
  onChangeHandler: func,
  type: string.isRequired,
  placeholder: string,
  defaultValue: string,
  className: string,
  id: string,
  errorNoSpace: bool,
  meta: shape({
    touched: bool,
    error: string,
    warning: string
  })
}

export default ReduxFormField
