export default {
  metricData: [
    {
      "question_id" : 1,
      "name" : "Postcode",
      "options" : null,
      "type" : 1,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 2,
      "name" : "House Name or Number",
      "options" : null,
      "type" : 1,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 3,
      "name" : "Does the property have a current EPC DATE?",
      "options" : [
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "NONE"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 4,
      "name" : "What is its current rating?",
      "options" : [
        "100",
        "99",
        "98",
        "97",
        "96",
        "95",
        "94",
        "93",
        "92",
        "91",
        "90",
        "89",
        "88",
        "87",
        "86",
        "85",
        "84",
        "83",
        "82",
        "81",
        "80",
        "79",
        "78",
        "77",
        "76",
        "75",
        "74",
        "73",
        "72",
        "71",
        "70",
        "69",
        "68",
        "67",
        "66",
        "65",
        "64",
        "63",
        "62",
        "61",
        "60",
        "59",
        "58",
        "57",
        "56",
        "55",
        "54",
        "53",
        "52",
        "51",
        "50",
        "49",
        "48",
        "47",
        "46",
        "45",
        "44",
        "43",
        "42",
        "41",
        "40",
        "39",
        "38",
        "37",
        "36",
        "35",
        "34",
        "33",
        "32",
        "31",
        "30",
        "29",
        "28",
        "27",
        "26",
        "25",
        "24",
        "23",
        "22",
        "21",
        "20",
        "19",
        "18",
        "17",
        "16",
        "15",
        "14",
        "13",
        "12",
        "11",
        "10",
        "9",
        "8",
        "7",
        "6",
        "5",
        "4",
        "3",
        "2",
        "1"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 5,
      "name" : "What is its potential?",
      "options" : [
        "100",
        "99",
        "98",
        "97",
        "96",
        "95",
        "94",
        "93",
        "92",
        "91",
        "90",
        "89",
        "88",
        "87",
        "86",
        "85",
        "84",
        "83",
        "82",
        "81",
        "80",
        "79",
        "78",
        "77",
        "76",
        "75",
        "74",
        "73",
        "72",
        "71",
        "70",
        "69",
        "68",
        "67",
        "66",
        "65",
        "64",
        "63",
        "62",
        "61",
        "60",
        "59",
        "58",
        "57",
        "56",
        "55",
        "54",
        "53",
        "52",
        "51",
        "50",
        "49",
        "48",
        "47",
        "46",
        "45",
        "44",
        "43",
        "42",
        "41",
        "40",
        "39",
        "38",
        "37",
        "36",
        "35",
        "34",
        "33",
        "32",
        "31",
        "30",
        "29",
        "28",
        "27",
        "26",
        "25",
        "24",
        "23",
        "22",
        "21",
        "20",
        "19",
        "18",
        "17",
        "16",
        "15",
        "14",
        "13",
        "12",
        "11",
        "10",
        "9",
        "8",
        "7",
        "6",
        "5",
        "4",
        "3",
        "2",
        "1"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 6,
      "name" : "Total floor area?",
      "options" : null,
      "type" : 1,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 7,
      "name" : "Type of Property",
      "options" : [
        "Flat – Top Floor",
        "Flat – Middle Floor",
        "Flat – Ground Floor",
        "House – Detached",
        "House – Semi Detached",
        "House – Mid Terraced",
        "House – End Terraced",
        "Bungalow – Attached",
        "Bungalow – Detached"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 8,
      "name" : "Home built date range",
      "options" : [
        "before 1900",
        "1900-1929",
        "1930-1949",
        "1950-1966",
        "1967-1975",
        "1976-1982",
        "1983-1990",
        "1991-1995",
        "1996-2002",
        "2003-2006",
        "2007-2011",
        "2012 onwards"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 9,
      "name" : "Number of bedrooms",
      "options" : [
        "2 or fewer",
        "3",
        "4 or more"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 10,
      "name" : "Type of roof",
      "options" : [
        "Loft with < 50 mm insulation",
        "Loft with 100 mm insulation",
        "Loft with 200 mm insulation",
        "Loft with 300 mm insulation",
        "Flat roof",
        "Room in roof added < 1992",
        "Room in roof added ≥1992",
        "Another property above",
        "A room in roof contemporary with the building"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 11,
      "name" : "Loft Hatch Insulated?",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 12,
      "name" : "Main Wall Type",
      "options" : [
        "Solid Brick Wall",
        "Solid Stone Wall",
        "Cavity Wall – not insulated",
        "Cavity Wall – insulated",
        "Cavity Wall – unsure of insulation",
        "Timber Frame Wall",
        "Concrete construction Wall",
        "Unknown construction Wall"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 13,
      "name" : "Heating Type",
      "options" : [
        "Gas boiler installed < 2005",
        "Gas boiler installed between 2005 - 2015",
        "Gas boiler installed > 2015",
        "Oil boiler installed < 2005",
        "Oil boiler installed between 2005 - 2015",
        "Oil boiler installed > 2015",
        "LPG boiler installed < 2005",
        "LPG boiler installed between 2005 - 2015",
        "LPG boiler installed > 2015",
        "Storage Heaters",
        "Coal Heating System",
        "Biomass Heating System",
        "Other Electric System",
        "Air source heat pump"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 14,
      "name" : "What make is the boiler?",
      "options" : [
        "Bosch",
        "Vaillant",
        "Glow worm",
        "Ideal",
        "Potterton",
        "Baxi",
        "Ferroli",
        "Viessmann",
        "Other"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 15,
      "name" : "Boiler Model No?",
      "options" : null,
      "type" : 1,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 16,
      "name" : "Does the boiler have a filter fitted?",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 17,
      "name" : "Are the pipes microbore?",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 18,
      "name" : "Water test number",
      "options" : null,
      "type" : 3,
      "answer" : "N/A",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 19,
      "name" : "What is the boiler flow temperature?",
      "options" : [
        "40-45",
        "45-50",
        "50-55",
        "55-60",
        "60-65",
        "65-70",
        "70-75",
        "75-80"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 20,
      "name" : "Hot Water Tank",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 21,
      "name" : "Is there room for a Hot Water tank?",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 22,
      "name" : "What insulations is on the water tank?",
      "options" : [
        "Factory Fitted 12mm",
        "Factory Fitted 25mm",
        "Factory Fitted 80mm",
        "Water jacket",
        "None"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 23,
      "name" : "Heating Controls",
      "options" : [
        "No time or thermostatic control",
        "Programmer only",
        "Room Thermostat Only",
        "Programmer and one room thermostat",
        "Programmer and 2+ room thermostats",
        "Programmer, thermostats and TRVs",
        "TRVs and Bypass",
        "Programmer, TRVs and Bypass",
        "Programmer, TRVs and boiler energy manager",
        "Time and Temperature Zone Control"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 24,
      "name" : "Living Area Temperature",
      "options" : [
        "17",
        "18",
        "19",
        "20",
        "21",
        "22+"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 25,
      "name" : "Number people in house",
      "options" : [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 26,
      "name" : "Shower Type",
      "options" : [
        "None",
        "Mixer Shower – non combi",
        "Mixer Shower – combi",
        "Electric Shower – no pump",
        "Electric Shower – with pump",
        "Unknown Type"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 27,
      "name" : "Window Type",
      "options" : [
        "Single Glazed < 2003",
        "Single Glazed between 2003 - 2015",
        "Single Glazed > 2015",
        "Double Glazed < 2003",
        "Double Glazed between 2003 - 2015",
        "Double Glazed > 2015",
        "Secondary Glazed < 2003",
        "Secondary Glazed between 2003 - 2015",
        "Secondary Glazed > 2015",
        "Triple Glazed < 2003",
        "Triple Glazed between 2003 - 2015",
        "Triple Glazed > 2015"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 28,
      "name" : "% of Window Type",
      "options" : [
        "0 to 25%",
        "26 to 50%",
        "51 to 75%",
        "76 to 100%"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 29,
      "name" : "Window Frame Type",
      "options" : [
        "Wood",
        "Metal",
        "PVC"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 30,
      "name" : "Are there solar panels",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 31,
      "name" : "Is there Solar Hot Water",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 32,
      "name" : "Is there room for a Heat Pump",
      "options" : [
        "Air source YES",
        "Air source NO",
        "Ground Source YES",
        "Ground Source NO"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 33,
      "name" : "Sloped Roof facing between SE and SW",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 34,
      "name" : "Floor Construction",
      "options" : [
        "Suspended Timber",
        "Suspended Not Timber",
        "Solid",
        "Unknown",
        "Insulated floors"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 35,
      "name" : "Air Bricks present?",
      "options" : [
        "Yes",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 36,
      "name" : "Number of Shower / week",
      "options" : [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 37,
      "name" : "Number of Baths / week",
      "options" : [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 38,
      "name" : "% low energy bulbs",
      "options" : [
        "0 to 25%",
        "26 to 50%",
        "51 to 75%",
        "76 to 95%",
        "95 to 100%"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 39,
      "name" : "Electric meter reading",
      "options" : null,
      "type" : 1,
      "answer" : "N/A",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 40,
      "name" : "Gas meter reading",
      "options" : null,
      "type" : 1,
      "answer" : "N/A",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 41,
      "name" : "Does the house need draught proofing?",
      "options" : [
        "Front Door",
        "Back Door",
        "Windows",
        "All",
        "No"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 42,
      "name" : "Notes",
      "options" : null,
      "type" : 4,
      "answer" : "N/A",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 43,
      "name" : "Customer Concerns",
      "options" : null,
      "type" : 4,
      "answer" : "N/A",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 44,
      "name" : "Do they have smart meter?",
      "options" : [
        "Yes",
        "No",
        "NA"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 45,
      "name" : "Do they use Smart Meter App?",
      "options" : [
        "Yes",
        "No",
        "NA"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 46,
      "name" : "Do they use Smart Heating Controls?",
      "options" : [
        "Yes",
        "No",
        "NA"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 47,
      "name" : "Have you discussed washing clothes at 30 degrees?",
      "options" : [
        "Yes",
        "No",
        "NA"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 48,
      "name" : "Have you discussed turning off appliance's at the sockets?",
      "options" : [
        "Yes",
        "No",
        "NA"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 49,
      "name" : "Have you discussed adjusting trvs?",
      "options" : [
        "Yes",
        "No",
        "NA"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 50,
      "name" : "Have you discussed not using tumble dryer and closing curtains at night?",
      "options" : [
        "Yes",
        "No",
        "NA"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 51,
      "name" : "Age range of adults in house?",
      "options" : [
        "20-40",
        "41-60",
        "60+",
        "Mixed"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 52,
      "name" : "Vulnerable residents",
      "options" : [
        "Elderly",
        "Disabled",
        "Health Issues",
        "Other",
        "NA "
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 53,
      "name" : "Does the house have underfloor heating?",
      "options" : [
        "Yes",
        "No",
        "Partial",
        "Electric"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 54,
      "name" : "Water test value",
      "options" : [
        "0",
        "20",
        "50",
        "100",
        "150"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 55,
      "name" : "How have you provided the floor plan for this survey?",
      "options" : [
        "Sent by email",
        "Created in Heatpunk",
        "Taken a photo"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : 5,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    },
    {
      "question_id" : 56,
      "name" : "What is the priority purchase for the customer?",
      "options" : [
        "Heat Pump",
        "Loft Insulation",
        "New Windows",
        "Solar Panels",
        "Wall Insulation",
        "None"
      ],
      "type" : 2,
      "answer" : "",
      "sub_type" : null,
      "photos" : [
        {
          "local_url": null,
          "remote_url" : null
        }
      ]
    }
  ]
}
