const REQUIRED_MESSAGE = 'Required'

export default (values) => {
  const errors = {}
  if (!values.name?.trim()) {
    errors.name = REQUIRED_MESSAGE
  }
  if (!values.address?.trim()) {
    errors.address = REQUIRED_MESSAGE
  }
  if (!values.post_code?.trim()) {
    errors.post_code = REQUIRED_MESSAGE
  }
  if (!values.phone_number?.trim()) {
    errors.phone_number = REQUIRED_MESSAGE
  }
  if (!values.email?.trim()) {
    errors.email = REQUIRED_MESSAGE
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.contact_email = 'Invalid email address'
  }

  if (!values.contact_email?.trim()) {
    errors.contact_email = REQUIRED_MESSAGE
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)) {
    errors.contact_email = 'Invalid Contact email address'
  }
  return errors
}
