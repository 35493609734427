import React from 'react'
import { objectOf, any } from 'prop-types'
import _ from 'lodash'

const DashboardGrid = ({ data }) => {
  const {
    image_overall,
    image_count_today,
    image_count_week,
    image_count_month,
    jobs_overall,
    jobs_count_today,
    jobs_count_week,
    jobs_count_month,
    reports_overall,
    reports_count_today,
    reports_count_week,
    reports_count_month,
    surveys_overall,
    surveys_count_today,
    surveys_count_week,
    surveys_count_month,
    users_overall,
    users_count_today,
    users_count_week,
    users_count_month,
    audits_overall,
    audits_count_today,
    audits_count_week,
    audits_count_month,
    engineers_overall
  } = data

  return (
    <>
      <section className='dashboard__page--section section__overview'>
        <div className='section__overview--list'>
          {!_.isEmpty(data) ? (
            <>
              {image_overall ? (
                <span className='section__overview--item item--1'>
                  <span className='section__overview--statShort'>
                    <i className='icons i90x90 icon-images_2' />{' '}
                    <span>
                      <span>{image_overall}</span> Images
                    </span>
                  </span>
                  <span className='section__overview--statFull'>
                    <span className='statFull__icons'>
                      <i className='icons i90x90 icon-images_2' />
                    </span>
                    <span className='statFull__text'>
                      <span>
                        <span>{image_count_today}</span> NEW TODAY
                      </span>
                      <span>
                        <span>{image_count_week}</span> THIS WEEK
                      </span>
                      <span>
                        <span>{image_count_month}</span> THIS MONTH
                      </span>
                      <span>
                        <span>{image_overall}</span> OVERALL
                      </span>
                    </span>
                  </span>
                </span>
              ) : null}
              {users_overall ? (
                <span className='section__overview--item item--1'>
                  <span className='section__overview--statShort'>
                    <i className='icons i90x90 icon-users_3' />{' '}
                    <span>
                      <span>{users_overall}</span> Users
                    </span>
                  </span>
                  <span className='section__overview--statFull'>
                    <span className='statFull__icons'>
                      <i className='icons i90x90 icon-users_3' />
                    </span>
                    <span className='statFull__text'>
                      <span>
                        <span>{users_count_today}</span> NEW TODAY
                      </span>
                      <span>
                        <span>{users_count_week}</span> THIS WEEK
                      </span>
                      <span>
                        <span>{users_count_month}</span> THIS MONTH
                      </span>
                      <span>
                        <span>{users_overall}</span> OVERALL
                      </span>
                    </span>
                  </span>
                </span>
              ) : null}
              <div className='section__overview--item item--2'>
                <span className='section__overview--statShort'>
                  <i className='icons i90x90 icon-surveys_3' />{' '}
                  <span>
                    <span>{surveys_overall}</span> SURVEYS REPORTS
                  </span>
                </span>
                <span className='section__overview--statFull'>
                  <span className='statFull__icons'>
                    <i className='icons i90x90 icon-surveys_3' />
                  </span>
                  <span className='statFull__text'>
                    <span>
                      <span>{surveys_count_today}</span> NEW TODAY
                    </span>
                    <span>
                      <span>{surveys_count_week}</span> THIS WEEK
                    </span>
                    <span>
                      <span>{surveys_count_month}</span> THIS MONTH
                    </span>
                    <span>
                      <span>{surveys_overall}</span> OVERALL
                    </span>
                  </span>
                </span>
              </div>
              <div className='section__overview--item item--3'>
                <span className='section__overview--statShort'>
                  <i className='icons i90x90 icon-jobs_3' />{' '}
                  <span>
                    <span>{jobs_overall}</span> JOB REPORTS
                  </span>
                </span>
                <span className='section__overview--statFull'>
                  <span className='statFull__icons'>
                    <i className='icons i90x90 icon-jobs_3' />
                  </span>
                  <span className='statFull__text'>
                    <span>
                      <span>{jobs_count_today}</span> NEW TODAY
                    </span>
                    <span>
                      <span>{jobs_count_week}</span> THIS WEEK
                    </span>
                    <span>
                      <span>{jobs_count_month}</span> THIS MONTH
                    </span>
                    <span>
                      <span>{jobs_overall}</span> OVERALL
                    </span>
                  </span>
                </span>
              </div>
              <span className='section__overview--item item--4'>
                <span className='section__overview--statShort'>
                  <i className='icons i90x90 icon-reports_3' />{' '}
                  <span>
                    <span>{reports_overall}</span> REPORTS OVERALL
                  </span>
                </span>
                <span className='section__overview--statFull'>
                  <span className='statFull__icons'>
                    <i className='icons i90x90 icon-reports_3' />
                  </span>
                  <span className='statFull__text'>
                    <span>
                      <span>{reports_count_today}</span> NEW TODAY
                    </span>
                    <span>
                      <span>{reports_count_week}</span> THIS WEEK
                    </span>
                    <span>
                      <span>{reports_count_month}</span> THIS MONTH
                    </span>
                    <span>
                      <span>{reports_overall}</span> OVERALL
                    </span>
                  </span>
                </span>
              </span>
              <span className='section__overview--item item--1'>
                <span className='section__overview--statShort'>
                  <i className='icons i90x90 icon-reports_3' />{' '}
                  <span>
                    <span>{audits_overall}</span> AUDITS OVERALL
                  </span>
                </span>
                <span className='section__overview--statFull'>
                  <span className='statFull__icons'>
                    <i className='icons i90x90 icon-reports_3' />
                  </span>
                  <span className='statFull__text'>
                    <span>
                      <span>{audits_count_today}</span> NEW TODAY
                    </span>
                    <span>
                      <span>{audits_count_week}</span> THIS WEEK
                    </span>
                    <span>
                      <span>{audits_count_month}</span> THIS MONTH
                    </span>
                    <span>
                      <span>{audits_overall}</span> OVERALL
                    </span>
                  </span>
                </span>
              </span>
              <span className='section__overview--item item--2'>
                <span className='section__overview--statShort'>
                  <i className='icons i90x90 icon-user' />{' '}
                  <span>
                    <span>{engineers_overall}</span> ENGINEERS OVERALL
                  </span>
                </span>
                <span className='section__overview--statFull'>
                  <span className='statFull__icons'>
                    <i className='icons i90x90 icon-user' />
                  </span>
                  <span className='statFull__text'>
                    <span>
                      <span>{reports_count_today}</span> NEW TODAY
                    </span>
                    <span>
                      <span>{reports_count_week}</span> THIS WEEK
                    </span>
                    <span>
                      <span>{reports_count_month}</span> THIS MONTH
                    </span>
                    <span>
                      <span>{reports_overall}</span> OVERALL
                    </span>
                  </span>
                </span>
              </span>
            </>
          ) : (
            <p className='tac'>Sorry, we can`t find any data :(</p>
          )}
        </div>
      </section>
    </>
  )
}

DashboardGrid.propTypes = {
  data: objectOf(any).isRequired
}

export default DashboardGrid
