/* eslint-disable */
export const getUser = () => localStorage.getItem('user') || sessionStorage.getItem('user')

export const setUser = (id, remember) => {
  remember ? localStorage.setItem('user', id) : sessionStorage.setItem('user', id)
}

export const removeUser = () => {
  localStorage.removeItem('user')
  sessionStorage.removeItem('user')
}
