import React from 'react'
import logo_icon from '../assets/img/logo_icon.jpg'
import logo_icon_2x from '../assets/img/logo_icon@2x.jpg'
import logo_icon_3x from '../assets/img/logo_icon@3x.jpg'

export default function PopupLogo() {
  return (
    <>
      <div className='popup__logo'>
        <img src={logo_icon} srcSet={`${logo_icon} 1x, ${logo_icon_2x} 2x, ${logo_icon_3x} 3x`} alt='ThermaFy' />
      </div>
    </>
  )
}
