/* eslint-disable */
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { any, func, bool, objectOf, string } from 'prop-types';

import { ROUTE_TO_JOBS, ROUTE_TO_ROOT, routeToJobUpdate } from '../../../constants/routes';
import { getJobByIdAction, deleteJobByIdAction } from '../actions';
import BreadCrumbs from '../../../components/BreadCrumbs';
import ProfileMenu from '../../../components/ProfileMenu';
import StaticPage from '../../../components/StaticPage';
import SiteHeader from '../../../components/SiteHeader';
import navData from '../../../constants/navData';
import history from '../../../history';
import config from '../../../config';
import DeletePopup from '../../../components/DeletePopup';

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Jobs',
    link: ROUTE_TO_JOBS
  }
];

const Header = ({ role }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role}/>
      <ProfileMenu currentUser={currentUser}/>
    </SiteHeader>
  );
};

Header.propTypes = {
  role: string
};

const JobSingle = ({ job, inProgress, deleteInProgress, getJob, deleteJob, role, match }) => {
  const { id } = match.params;
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  useEffect(() => {
    getJob(id);
  }, []);

  const onDeleteHandler = async () => {
    try {
      await deleteJob(id);
      history.push(ROUTE_TO_JOBS)
    } catch (e) {
      console.log(e);
    }
  };

  if (!job.id) return <div/>

  const {
    customer,
    description,
    job_reference,
    is_completed,
    is_reports,
    start_date,
    start_time,
    created_at,
    engineer,
    employer,
    pdf_url
    } = job;

  const {
    full_name,
    contact_name,
    contact_phone_number,
    contact_email,
    post_code,
    address,
  } = customer


  return (
    <StaticPage sidebarData={navData[role]} header={<Header role={role}/>}>
      <div className="job__page pToppBottom">
        <section className="job__page--section section__job section__border job">
          <div className="formDefault fullWidth viewForm">
            <div className="formDefault__header clearfix">
              <h1 className="formDefault__header--title">Job View</h1>
              <div className="formDefault__header--btns">
                <Link className='btns btn-i btn-edit' to={routeToJobUpdate(id)}>
                  EDIT Job
                </Link>
                <button
                  className='btns btn-i btn-delete'
                  data-toggle="class"
                  data-target="#popups"
                  data-classes="userdelete"
                  type='button'
                  onClick={() => setShowDeletePopup(true)}
                  disabled={deleteInProgress}>
                  {deleteInProgress ? 'Deleting...' : 'Delete this Job'}
                </button>
              </div>
            </div>
            {!inProgress ? (
              <div className="formDefault__body">
                <div className="formDefault__body--columns clearfix">
                  <div className="formDefault__body--column column3">
                    <div className="formDefault__title">Customer Info</div>
                    <div className="formDefault__bg">
                      <div className="formDefault__row">
                        <label className="formDefault__label">Full Customer Name (Private or Company)</label>
                        <div className="formDefault__text">{full_name}</div>
                      </div>
                      <div className="formDefault__row">
                        <label className="formDefault__label">Contact Name</label>
                        <div className="formDefault__text">{contact_name}</div>
                      </div>
                      <div className="formDefault__row">
                        <label className="formDefault__label">Contact Phone Number</label>
                        <div className="formDefault__text">{contact_phone_number}</div>
                      </div>
                      <div className="formDefault__row">
                        <label className="formDefault__label">Customer Email</label>
                        <div className="formDefault__text">{contact_email}</div>
                      </div>
                      <div className="formDefault__row">
                        <label className="formDefault__label">Customer Postcode</label>
                        <div className="formDefault__text">{post_code}</div>
                      </div>
                      <div className="formDefault__row">
                        <label className="formDefault__label">Reference ID</label>
                        <div className="formDefault__text">{job_reference}</div>
                      </div>
                      <div className="formDefault__row">
                        <label className="formDefault__label">Customer Address</label>
                        <div className="formDefault__text">{address}</div>
                      </div>
                    </div>
                    <div className="formDefault__row">
                      <a href={`mailto: ${contact_email}`}>
                      <button type='button' className="btns btn-b btn-yellow btn-email">
                        Email Customer
                      </button>
                      </a>
                    </div>
                  </div>
                  <div className="formDefault__body--column column3">
                    <div className="formDefault__title">Job Info</div>
                    <div className="formDefault__bg">
                      <div className="formDefault__row">
                        <label className="formDefault__label">Job Reference Number</label>
                        <div className="formDefault__text">{job_reference}</div>
                      </div>
                      <div className="formDefault__row">
                        <label className="formDefault__label">Job Description</label>
                        <div className="formDefault__text">{description}</div>
                      </div>
                    </div>

                    {role === 'employer' ? (
                      <>
                        <div className="formDefault__title">Assigned</div>
                        <div className="formDefault__bg">
                          <div className="formDefault__row">
                            <label className="formDefault__label">Assigned to Engineer</label>
                            <div className="formDefault__text">{engineer && engineer.name}</div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="formDefault__title">Employer</div>
                        <div className="formDefault__bg">
                          <div className="formDefault__row">
                            <label className="formDefault__label">Employer</label>
                            <div className="formDefault__text">{employer && employer.name}</div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="formDefault__title">Date/Time</div>
                    <div className="formDefault__bg">
                      <div className="formDefault__row">
                        <label className="formDefault__label">Job Date</label>
                        <div className="formDefault__text">{start_date}</div>
                      </div>
                      <div className="formDefault__row">
                        <label className="formDefault__label">Job Time</label>
                        <div className="formDefault__text">{start_time}</div>
                      </div>
                    </div>
                  </div>
                  <div className="formDefault__body--column column3">
                    <div className="formDefault__title">Additional Info</div>
                    <div className="formDefault__bg">
                      <div className="formDefault__row">
                        <label className="formDefault__label">Job STATUS</label>
                        <div className="formDefault__text">{is_completed ? 'COMPLETED' : 'IN PROGRESS'}</div>
                      </div>
                    </div>
                    {is_reports ? (
                      <a className="formDefault__bg btns btn-b btn-report" href={config.REACT_APP_UPLOADS_URL + pdf_url} target='_blank'>
                        <span className="formDefault__label">View Report</span>
                        <span className="formDefault__text">available</span>
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null }
          </div>
          <div className='customer__page--created'>
            <strong>Created</strong> At {moment(created_at).format('DD/MM/YYYY')}
          </div>
        </section>
      </div>
      <DeletePopup onDelete={onDeleteHandler} onCancel={() => setShowDeletePopup(false)} isOpen={showDeletePopup} target='Job' />
    </StaticPage>
  );
};

JobSingle.propTypes = {
  job: objectOf(any),
  inProgress: bool,
  deleteInProgress: bool,
  role: string,
  getJob: func.isRequired,
  deleteJob: func.isRequired,
  match: objectOf(any)
};

const mapStateToProps = ({ jobs, auth }) => {
  return {
    job: jobs.job,
    inProgress: jobs.inProgress,
    deleteInProgress: jobs.deleteInProgress,
    role: auth.user && auth.user.role
  };
};

const mapDispatchToProps = (dispatch) => ({
  getJob: (id) => dispatch(getJobByIdAction(id)),
  deleteJob: (id) => dispatch(deleteJobByIdAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSingle);
