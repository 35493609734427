import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const defaultComponentOptions = {
  rowsPerPageText: 'Rows per page:',
  rangeSeparatorText: 'of',
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: 'All'
}

const PaginationWrapper = styled.nav`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  ${(props) => props.theme.pagination.style};
`

const Button = styled.button`
  position: relative;
  display: block;
  user-select: none;
  border: none;
  ${(props) => props.theme.pagination.pageButtonsStyle};
  ${(props) => props.isRTL && 'transform: scale(-1, -1)'};
`

const PageList = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
`

// const Span = styled.span`
//   flex-shrink: 1;
//   user-select: none;
// `
const getNumberOfPages = (rowCount, rowsPerPage) => Math.ceil(rowCount / rowsPerPage)

const PaginationCustom = ({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => {
  const numPages = getNumberOfPages(rowCount, rowsPerPage)
  // const lastIndex = currentPage * rowsPerPage
  // const firstIndex = lastIndex - rowsPerPage + 1
  const disabledLesser = currentPage === 1
  const disabledGreater = currentPage === numPages
  const options = { ...defaultComponentOptions }
  // const range =
  //   currentPage === numPages
  //     ? `${firstIndex}-${rowCount} ${options.rangeSeparatorText} ${rowCount}`
  //     : `${firstIndex}-${lastIndex} ${options.rangeSeparatorText} ${rowCount}`

  const handlePrevious = useCallback(() => onChangePage(currentPage - 1), [currentPage, onChangePage])
  const handleNext = useCallback(() => onChangePage(currentPage + 1), [currentPage, onChangePage])
  // const handleFirst = useCallback(() => onChangePage(1), [onChangePage])
  // const handleLast = useCallback(() => onChangePage(getNumberOfPages(rowCount, rowsPerPage)), [
  //   onChangePage,
  //   rowCount,
  //   rowsPerPage
  // ])
  const handleRowsPerPage = useCallback(({ target }) => onChangeRowsPerPage(Number(target.value), currentPage), [
    currentPage,
    onChangeRowsPerPage
  ])

  const pageChangeHandler = useCallback(({ target }) => onChangePage(Number(target.value)), [currentPage, onChangePage])

  const select = (
    <select className='paginationSelect' onChange={handleRowsPerPage} defaultValue={rowsPerPage}>
      <option value={5}>5 rows</option>
      <option value={10}>10 rows</option>
      <option value={15}>15 rows</option>
    </select>
  )
  const pageSelect = () => {
    const optionsArr = []
    for (let i = 0; i < numPages; i += 1) {
      optionsArr.push(<option key={i} value={i + 1}>{i + 1}</option>)
    }
    return optionsArr
  }

  return (
    <PaginationWrapper className='page__pagination'>
      <PageList>
        <div className='blocks__inline select_page'>
          <span className='blocks__inline mr-10'>Page</span>
          <span className='blocks__inline blocks__border'>
            <select className='paginationSelect' onChange={pageChangeHandler} defaultValue={currentPage}>
              {pageSelect()}
            </select>
          </span>
          <span className='blocks__inline'>of {numPages}</span>
        </div>

        {!options.noRowsPerPage && <>{select}</>}

        <span className='blocks__inline blocks__btns--leftright'>
          <Button
            id='pagination-previous-page'
            className='btns btn-i'
            type='button'
            aria-label='Previous Page'
            aria-disabled={disabledLesser}
            onClick={handlePrevious}
            disabled={disabledLesser}>
            <i className='icons i24x24 arrow-left' />
          </Button>
          <Button
            id='pagination-next-page'
            className='btns btn-i'
            type='button'
            aria-label='Next Page'
            aria-disabled={disabledGreater}
            onClick={handleNext}
            disabled={disabledGreater}>
            <i className='icons i24x24 arrow-right' />
          </Button>
        </span>

      </PageList>
    </PaginationWrapper>
  )
}

PaginationCustom.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired
}

export default PaginationCustom
