import React from 'react'
import { string, any, arrayOf } from 'prop-types'

const SiteHeader = ({ children, additionaClassname }) => {
  return (
    <header className={`header__main ${additionaClassname}`}>
      <div className='site__width'>
        {children}
      </div>
    </header>
  )
}

SiteHeader.propTypes = {
  children: arrayOf(any),
  additionaClassname: string
}

export default SiteHeader
