const REQUIRED_MESSAGE = 'Required'
const passwordWrongLength = 'From 6 to 72 symbols, at least 1 uppercase and 1 lowercase letter, at least 1 number'
const equalError = 'Values must be equal'

export const isPasswordsEqual = (password, confirm) => {
  return password === confirm
}
export const isPasswordLengthCorrect = (password) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,72}$/
  return re.test(password)
}

export default (values) => {
  const errors = {}

  if (!values.name) {
    errors.name = REQUIRED_MESSAGE
  } else if (values.name.length > 15) {
    errors.name = 'Must be 15 characters or less'
  }

  if (!values.email) {
    errors.email = REQUIRED_MESSAGE
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (values.password || values.password_confirmation) {
    if (!isPasswordLengthCorrect(values.password)) {
      errors.password_confirmation = passwordWrongLength
    } else if (!isPasswordsEqual(values.password, values.password_confirmation)) {
      errors.password_confirmation = equalError
    }
  }

  return errors
}
