import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/img/logo.png'
import logo_2x from '../assets/img/logo@2x.png'
import logo_3x from '../assets/img/logo@3x.png'
import { ROUTE_TO_ROOT } from '../constants/routes'

export default function AccountHeader() {
  return (
    <header className='header__main signin'>
      <div className='site__width clearfix'>
        <div className='header__main--logo'>
          <Link to={ROUTE_TO_ROOT} className='btns'>
            <img src={logo} srcSet={`${logo} 1x, ${logo_2x} 2x, ${logo_3x} 3x`} alt='ThermaFy' />
          </Link>
        </div>
      </div>
    </header>
  )
}
