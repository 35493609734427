import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { string, bool, func, number, arrayOf, object } from 'prop-types'
import {
  ROUTE_TO_ROOT,
  routeToSurveySingle,
  routeToSurveyUpdate
} from '../../../constants/routes'
import HeaderSearch from '../../../components/HeaderSearch'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import tableHeaders from '../../../utils/tableHeaders'
import navData from '../../../constants/navData'
import Table from '../../../components/Table'
import { getSurveysAction } from '../actions'
import { getEmployersAction } from '../../Employers/actions'
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const Header = ({ onSearch }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current='surveys' />
      <HeaderSearch onSubmit={onSearch} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  onSearch: func.isRequired
}

const Surveys = ({ role, surveys, inProcess, getSurveys, employers, getEmployers, count, user }) => {
  const navigate = useNavigate()
  const [activeEmployer, setActiveEmployer] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    getSurveys({ page, per_page: perPage })
  }, [page, perPage])

  useEffect(() => {
    getEmployers()
  }, [])

  const onEmployerChange = (v) => {
    getSurveys({ employer_id: v })
    setActiveEmployer(v)
  }

  const onRowClickHandler = ({ id }) => navigate(routeToSurveySingle(id))

  const renderColumns = () => {
    if (role === 'employer') return tableHeaders.surveys[role]
    if (role === 'engineer') {
      return tableHeaders.surveys[role](onEmployerChange, activeEmployer, employers)
    }
  }

  return (
    <StaticPage sidebarData={navData[role]} header={<Header onSearch={getSurveys} currentUser={user} />}>
      <div className='customers__page pTop'>
        <div className='page__header clearfix'>
          <div className='page__btns'>
            <Link className='btns btn-i btn-yellow' to={routeToSurveyUpdate()}>
              ADD NEW SURVEY
            </Link>
          </div>
          <h1 className='page__title'>Surveys</h1>
        </div>
      </div>

      <Table
        onChangePage={setPage}
        per_page={perPage}
        page={page}
        count={count}
        data={surveys}
        columns={renderColumns()}
        inProcess={inProcess}
        onClickHandler={onRowClickHandler}
        changePerPageHandler={setPerPage}
      />
    </StaticPage>
  )
}

Surveys.propTypes = {
  role: string,
  count: number.isRequired,
  user: object,
  inProcess: bool,
  employers: arrayOf(object),
  surveys: arrayOf(object),
  getSurveys: func.isRequired,
  getEmployers: func.isRequired,
  category: string
}

const mapStateToProps = ({ auth, surveys, employers }) => {
  const data = {
    ...surveys,
    employers: employers.employers && employers.employers.data
  }

  const { user } = auth
  if (user) data.user = user
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getSurveys: (params) => {
    dispatch(getSurveysAction(params))
  },
  getEmployers: (params) => dispatch(getEmployersAction(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Surveys)
