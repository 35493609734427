import api from '../../utils/api'
import apiSubmissionError from '../../utils/apiSubmissionError'
// import { getUser } from '../../utils/currentUserCRUD'

export const loginApi = (data) => api.post('/managers/authorization/login', data)
                                     .catch(apiSubmissionError)
export const forgotPasswordApi = (email) => api.put('/managers/passwords/forgot', email)
                                               .catch(apiSubmissionError)
export const recoveryPasswordApi = (data) => api.put('/managers/passwords/change', data)
                                                .catch(apiSubmissionError)
export const logoutApi = () => {
  console.log("LOGOUT API")
  return api.put('/managers/authorization/logout')
}

export const getCurrentUserApi = () => {
  return api.get('/managers/users/me')
}
