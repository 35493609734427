import React from 'react'
import { Field } from 'redux-form'
import { string, func } from 'prop-types'
import ReduxFormField from '../../../components/ReduxFormField'
import PopupLogo from '../../../components/PopupLogo'

const InviteEngineer = ({ onSubmit, handleSubmit, error, title, subtitle }) => {
  return (
    <div className='popup__block popup__engineerinvite'>
      <PopupLogo />
      <div className='popup__title'>{title}</div>
      {subtitle ? <div className='popup__subtitle'>{subtitle}</div> : null}
      <form className='formPopup' onSubmit={handleSubmit(onSubmit)}>
        <div className='formPopup__row'>
          <Field
            component={ReduxFormField}
            required
            class='formPopup__input'
            type='text'
            name='email'
            id='email'
            label='Email'
            placeholder='e.g… Peter@xyzweb.je'
          />
        </div>
        <div className='formPopup__row'>
          <Field
            component={ReduxFormField}
            class='formPopup__input'
            type='text'
            label='Full Name'
            name='name'
            id='name'
            placeholder='e.g… peter jones'
          />
        </div>
        <p className='validation-error-message'>{error}</p>
        <div className='formPopup__row formPopup__row--btns'>
          <button type='submit' className='btns btn-i btn-yellow btn-invite'>
            Invite
          </button>
          {/* <button type='button' className='btns btn-i btn-trns btn-cencel'>
            Cancel
          </button> */}
        </div>
      </form>
    </div>
  )
}

InviteEngineer.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  error: string,
  title: string,
  subtitle: string
}

export default InviteEngineer
