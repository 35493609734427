import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { string, number, bool, func, arrayOf, object, objectOf } from 'prop-types'
import { ROUTE_TO_ROOT, ROUTE_TO_USERS, routeToUserUpdate } from '../../../constants/routes'
import { getUsersAction, updateUserAction } from '../actions'
import HeaderSearch from '../../../components/HeaderSearch'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import tableHeaders from '../../../utils/tableHeaders'
import navData from '../../../constants/navData'
import Table from '../../../components/Table'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const Header = ({ onSearch, params }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current='Users' />
      <HeaderSearch onSubmit={onSearch} params={params} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  onSearch: func.isRequired,
  params: objectOf(string)
}

const Users = ({ role, users, inProcess, getUsers, employers, count, updateUserStatus }) => {
  const navigate = useNavigate()
  const [selectedType, setSelectedType] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [toggleIsReady, setToggleIsReady] = useState('')

  useEffect(() => {
    getUsers({ page, per_page: perPage, user_type: selectedType })
  }, [page, perPage])

  const onEmployerChange = (type) => {
    getUsers({ user_type: type })
    setSelectedType(type)
  }

  const toggleHandler = async (userId, event) => {
    event.preventDefault()
    const status = event.target.checked
    console.log("TOGGLE HANDLER", userId, status)
    const data = {user: { is_active: status }}
    const response = await updateUserStatus(userId, data)
    console.log("TOGGLE HANDLER", response.value)

    if(response.value.status === 200){
      setToggleIsReady(response.value.data.user.data.attributes.is_active)
    }
    navigate(ROUTE_TO_USERS)
  }

  const renderColumns = (tableHeaders, onEmployerChange, selectedType) => {
    const users = tableHeaders(onEmployerChange, selectedType)
    return [
      ...users,
      {
        name: 'Action',
        selector: 'attributes.is_active',
        cell: (row) => {
          const label = row.attributes.is_active ? "Active" : "Suspended"
          setToggleIsReady(row.attributes.is_active)
          console.log("Toggle ready", row.attributes.is_active, toggleIsReady)
          const userId = row.attributes.id
          const role = row.attributes.role
          return (
            <label>
              <Toggle
                defaultChecked={row.attributes.is_active}
                icons={false}
                disabled={role==="employer" ? true : false}
                className={label==="Active" ? "react-toggle--checked" : "toggle-suspend"}
                onChange={(event)=>toggleHandler(userId, event)}
              />
              <span>{label}</span>
            </label>
          )
        },
        allowOverflow: true,
        button: true,
        width: '100px'
      }
    ]
  }

  const onRowClickHandler = ({id}) => navigate(routeToUserUpdate(id))

  return (
    <StaticPage
      sidebarData={navData[role]}
      header={<Header onSearch={getUsers} params={{ user_type: selectedType }} />}>
      <div className='users__page pTop'>
        <div className='page__header clearfix'>
          <h1 className='page__title'>Users</h1>
        </div>
      </div>

      <Table
        onChangePage={setPage}
        per_page={perPage}
        page={page}
        changePerPageHandler={setPerPage}
        data={users}
        columns={renderColumns(tableHeaders.users, onEmployerChange, selectedType)}
        inProcess={inProcess}
        count={count}
        onClickHandler={onRowClickHandler}
      />
    </StaticPage>
  )
}

Users.propTypes = {
  role: string,
  count: number.isRequired,
  inProcess: bool,
  users: arrayOf(object),
  employers: arrayOf(object),
  getUsers: func.isRequired,
  updateUserStatus: func.isRequired
}

const mapStateToProps = ({ auth, users, employers }) => {
  const data = {
    users: users.users,
    count: users.count,
    employers: employers.employers && employers.employers.data,
    inProcess: users.inProcess
  }

  const { user } = auth
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: (params) => dispatch(getUsersAction(params)),
  updateUserStatus: (id, data) => dispatch(updateUserAction(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
