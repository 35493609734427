import React, { useState, useEffect } from 'react'
import moment from 'moment'
import DatePickerLib from 'react-date-picker'
import { any, bool, objectOf, shape, string } from 'prop-types'

const DatePicker = ({ input, label, id, errorNoSpace, meta: { touched, error }, defaultValue }) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (defaultValue && typeof defaultValue === 'string') {
      setDate(new Date(moment(defaultValue, 'DD/MM/YYYY').toISOString()));
    }
  }, [])

  return (
    <div>
      <label className='form__label formDefault__label' htmlFor={id}>
        {label}
      </label>
      <div className='form-field-wrapper'>
        <DatePickerLib
          clearIcon={null}
          className='formDefault__input calendar'
          format='dd-MM-yyyy'
          value={date}
          onChange={(value) => {
            setDate(value)
            const newDate = moment(value).toISOString(true) // replaced default input time converting
            return input.onChange(newDate)
          }}
        />
        <p className={`validation-error-message ${touched && error ? '' : `${errorNoSpace ? 'hidden' : 'invisible'}`}`}>
          {error}
        </p>
      </div>
    </div>
  )
}

DatePicker.propTypes = {
  input: objectOf(any).isRequired,
  defaultValue: string,
  label: string.isRequired,
  id: string,
  errorNoSpace: bool,
  meta: shape({
    touched: bool,
    error: string,
    warning: string
  })
}

export default DatePicker
