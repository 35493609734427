import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { any, arrayOf, bool, func, object, objectOf, string } from 'prop-types'
import _ from 'lodash'
import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_JOBS } from '../../../constants/routes'
import TimePicker from '../../../components/TimePicker'
import DatePicker from '../../../components/DatePicker'
import fieldTrimNormalizer from '../../../utils/fieldTrimNormalizer'

const JobFormEngineer = ({
  handleSubmit,
  onSubmit,
  submitting,
  error,
  employers,
  customers,
  initialValues,
  getCustomerDetails,
  getEmployers,
  getCustomers,
  onDelete,
  getEmployer,
  isEdit,
  setFieldVal
}) => {
  const [employersList, setEmployersList] = useState([])
  const [customersList, setCustomersList] = useState([])
  const [showCustomerDetails, setShowCustomerDetails] = useState(false)

  const customerDetailsHandler = (id) => {
    getCustomerDetails(id)
    setShowCustomerDetails(true)
  }

  const onEmployerSelect = async (id) => {
    await getEmployer(id)
      .then((res) => {
        const refId = _.get(res, 'value.data.user.data.attributes.reference_id')
        if (refId) {
          setFieldVal('job_reference', refId)
        } else {
          setFieldVal('job_reference', '')
        }
        setFieldVal()
      })
      .catch(() => {
        setFieldVal('job_reference', '')
      })
    getCustomers()
  }

  useEffect(() => {
    getEmployers()
    getCustomers()
  }, [])

  useEffect(() => {
    if (employers && employers.length) {
      setEmployersList(employers.map((item) => ({ id: item.id, name: item.attributes.name })))
    }
  }, [employers])

  useEffect(() => {
    if (customers && customers.length) {
      setCustomersList(customers.map((item) => ({ id: item.id, name: item.attributes.full_name })))
    }
  }, [customers])

  return (
    <form className='formDefault fullWidth' onSubmit={handleSubmit(onSubmit)}>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>{isEdit ? 'Edit Job' : 'Create Job'}</h1>
        <div className='formDefault__header--btns'>
          {isEdit ? (
            <button
              className='btns btn-i btn-delete'
              data-toggle='class'
              data-target='#popups'
              data-classes='userdelete'
              type='button'
              onClick={onDelete}>
              Delete this Job
            </button>
          ) : null}
        </div>
      </div>
      <div className='formDefault__body'>
        <div className='formDefault__body--columns clearfix'>
          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Customer Info</div>
            <div className='formDefault__row custom-select'>
              {isEdit ? (
                <>
                  <label className='form__label formDefault__label'>Employer</label>
                  <div className='formDefault__input not-allowed'>
                    {(initialValues.employer && initialValues.employer.name) || 'Own job'}
                  </div>
                </>
              ) : (
                <Field
                  component={ReduxFormField}
                  type='select'
                  label='Choose Employer'
                  className='formDefault__select'
                  name='employer'
                  defaultValue={initialValues.id}
                  errorNoSpace
                  onChangeHandler={onEmployerSelect}>
                  <option value=''>Own job</option>
                  {employersList.map(({ id, name }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    )
                  })}
                </Field>
              )}
            </div>
            <div className='formDefault__row custom-select '>
              {isEdit ? (
                <>
                  <label className='form__label formDefault__label'>Full Customer Name (Private or Company)</label>
                  <div className='formDefault__input not-allowed'>{initialValues.full_name}</div>
                </>
              ) : (
                <Field
                  component={ReduxFormField}
                  label='Full Customer Name (Private or Company)'
                  type='select'
                  className='formDefault__select'
                  name='customer_id'
                  errorNoSpace
                  onChangeHandler={customerDetailsHandler}>
                  <option disabled value=''>
                    Select Name
                  </option>
                  {customersList.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              )}
            </div>
            {showCustomerDetails || isEdit ? (
              <>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Contact Name</label>
                  <div className='formDefault__input not-allowed'>{initialValues.contact_name}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Contact Phone Number</label>
                  <div className='formDefault__input not-allowed'>{initialValues.contact_phone_number}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Customer Email</label>
                  <div className='formDefault__input not-allowed'>{initialValues.contact_email}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Customer Postcode</label>
                  <div className='formDefault__input not-allowed'>{initialValues.post_code}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='form__label formDefault__label'>Customer Address</label>
                  <div className='formDefault__input not-allowed'>{initialValues.address}</div>
                </div>
              </>
            ) : null}
          </div>
          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>Job Info</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Job Reference Number'
                className='formDefault__input'
                type='text'
                name='job_reference'
                id='engineer_job_reference'
                normalize={fieldTrimNormalizer}
                placeholder='Job Reference Number'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Job Description'
                className='formDefault__textarea'
                type='textarea'
                name='description'
                id='engineer_description'
                normalize={fieldTrimNormalizer}
                placeholder='Job Description'
                errorNoSpace
              />
            </div>
          </div>
          <div className='formDefault__body--column column3'>
            <div className='formDefault__title'>&nbsp;</div>
            <div className='formDefault__row'>
              <Field
                component={DatePicker}
                label='Job Date'
                className='formDefault__input'
                name='start_date'
                defaultValue={initialValues.start_date}
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={TimePicker}
                label='Job Time'
                className='formDefault__input time'
                name='start_time'
                defaultValue={initialValues.start_time}
                id='timepicker'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row custom-select'>
              <label className='formDefault__label'>Job STATUS</label>
              <Field component='select' className='formDefault__select' name='is_completed' errorNoSpace>
                <option disabled='disabled'>Select Status</option>
                <option value=''>IN PROGRESS</option>
                <option value='1'>COMPLETED</option>
              </Field>
            </div>
          </div>
        </div>
        {error ? <p className='validation-error-message'>{error}</p> : null}
        <div className='formDefault__row--btns'>
          <button
            className={`btns btn-i btn-yellow ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Save'}
          </button>
          <Link className='btns btn-i btn-trns' to={ROUTE_TO_JOBS}>
            Cancel
          </Link>
        </div>
      </div>
    </form>
  )
}

JobFormEngineer.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  getCustomers: func.isRequired,
  getEmployers: func.isRequired,
  getEmployer: func.isRequired,
  onDelete: func.isRequired,
  getCustomerDetails: func.isRequired,
  submitting: bool.isRequired,
  isEdit: bool.isRequired,
  error: string,
  initialValues: objectOf(any),
  employers: arrayOf(object),
  customers: arrayOf(object),
  setFieldVal: func
}

export default JobFormEngineer
