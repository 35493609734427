import React from 'react'
import { Provider } from 'react-redux'
// import { Router } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
// import { CompatRouter } from "react-router-dom-v5-compat"

import Notification from './containers/Notifications'
import App from './containers/App'
// import history from './history'
import store from './store'

import './assets/scss/main.scss'

export default function () {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <Notification style={{ 'z-index': 9999 }} />
      </BrowserRouter>
    </Provider>
  )
}
