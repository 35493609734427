import React from 'react'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { bool, func, shape, string } from 'prop-types'
import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_USERS } from '../../../constants/routes'
import fieldTrimNormalizer from '../../../utils/fieldTrimNormalizer'

const UserForm = ({
  handleSubmit,
  onSubmit,
  submitting,
  error,
  initialValues: { role },
  deleteInProgress,
  setShowDeletePopup
}) => {
  return (
    <form className='formDefault fullWidth' onSubmit={handleSubmit(onSubmit)}>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>User Details</h1>
        <div className='formDefault__header--btns'>
          <button
            className='btns btn-i btn-delete'
            data-toggle='class'
            data-target='#popups'
            data-classes='userdelete'
            type='button'
            onClick={() => setShowDeletePopup(true)}
            disabled={deleteInProgress}>
            {deleteInProgress ? 'Deleting...' : 'Delete this user'}
          </button>
        </div>
      </div>
      <div className='formDefault__body'>
        <div className='formDefault__body--columns clearfix'>
          <div className={`formDefault__body--column column2 ${role === 'engineer' ? 'single-column' : ''}`}>
            <div className='formDefault__title'>Contact Info</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Contact Name'
                className='formDefault__input'
                type='text'
                name='name'
                id='userContactName'
                normalize={fieldTrimNormalizer}
                placeholder='Contact Name'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Contact Phone Number'
                className='formDefault__input'
                type='number'
                name='phone_number'
                id='userContactPhone'
                placeholder='Contact Phone Number'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Contact Email'
                className='formDefault__input'
                type='email'
                name='email'
                id='userContactEmail'
                normalize={fieldTrimNormalizer}
                placeholder='Contact Email '
                errorNoSpace
              />
            </div>
          </div>
          {role === 'employer' ? (
            <div className='formDefault__body--column column2'>
              <div className='formDefault__title'>Company Info</div>
              <div className='formDefault__row'>
                <Field
                  component={ReduxFormField}
                  label='Company Name'
                  className='formDefault__input'
                  type='text'
                  name='company_name'
                  id='userCompanyName'
                  normalize={fieldTrimNormalizer}
                  placeholder='Company Name'
                  errorNoSpace
                />
              </div>
              <div className='formDefault__row'>
                <Field
                  component={ReduxFormField}
                  label='Address'
                  className='formDefault__input'
                  type='text'
                  name='address'
                  id='userAddress'
                  normalize={fieldTrimNormalizer}
                  placeholder='Address'
                  errorNoSpace
                />
              </div>
              <div className='formDefault__row'>
                <Field
                  component={ReduxFormField}
                  label='Post Code'
                  className='formDefault__input'
                  type='number'
                  name='post_code'
                  id='userPostCode'
                  placeholder='Post Code'
                  errorNoSpace
                />
              </div>
            </div>
          ) : null}
        </div>
        {error ? <p className='validation-error-message'>{error}</p> : null}
        <div className='formDefault__row--btns'>
          <button
            className={`btns btn-i btn-yellow ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Save'}
          </button>
          <Link className='btns btn-i btn-trns' to={ROUTE_TO_USERS}>
            Cancel
          </Link>
        </div>
      </div>
    </form>
  )
}

UserForm.propTypes = {
  handleSubmit: func.isRequired,
  setShowDeletePopup: func.isRequired,
  onSubmit: func.isRequired,
  submitting: bool.isRequired,
  deleteInProgress: bool.isRequired,
  error: string,
  initialValues: shape({
    role: string
  })
}

export default UserForm
