import React from 'react'
import { connect } from 'react-redux'
import { string, object } from 'prop-types'
import ProfileMenu from '../../components/ProfileMenu'
import StaticPage from '../../components/StaticPage'
import SiteHeader from '../../components/SiteHeader'
import navData from '../../constants/navData'
import EmployerDashboard from './EmployerDashboard'
import EngineerDashboard from './EngineerDashboard'
import SuperAdminDashboard from './SuperAdminDashboard'

const Header = () => {
  return (
    <SiteHeader additionaClassname='pull_to_end'>
      <ProfileMenu />
    </SiteHeader>
  )
}

const Dashboard = ({ role, user }) => {
  const DashboardToRender = () => {
    if (role === 'employer') return <EmployerDashboard />
    if (role === 'engineer') return <EngineerDashboard />
    if (role === 'super_admin' || role === 'admin') return <SuperAdminDashboard />
  }

  return (
    <StaticPage sidebarData={navData[role]} header={<Header role={role} currentUser={user} pageClass='dashboard' />}>
      <div className='dashboard__page pToppBottom'>{DashboardToRender()}</div>
    </StaticPage>
  )
}

Dashboard.propTypes = {
  role: string,
  // eslint-disable-next-line react/forbid-prop-types
  user: object
}

const mapStateToProps = ({ auth }) => {
  const data = {}
  const { user } = auth
  if (user) data.user = user
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  // getEmployers: (params) => dispatch(getEmployersAction(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
