import { connect } from 'react-redux'
import React, { useEffect, createContext } from 'react'
import { objectOf, any, func } from 'prop-types'
import { Redirect, Switch } from 'react-router-dom'
import { CompatRouter } from "react-router-dom-v5-compat"

import {
  ROUTE_TO_FORGOT_PASSWORD,
  ROUTE_TO_LOGIN,
  ROUTE_TO_ROOT,
  ROUTE_TO_CREATE_ACCOUNT,
  ROUTE_TO_PROFILE,
  ROUTE_TO_CUSTOMERS,
  ROUTE_TO_RECOVERY_PASSWORD,
  ROUTE_TO_CUSTOMER_SINGLE,
  ROUTE_TO_CUSTOMER_UPDATE,
  ROUTE_TO_JOBS,
  ROUTE_TO_JOB_SINGLE,
  ROUTE_TO_JOB_UPDATE,
  ROUTE_TO_ENGINEERS,
  ROUTE_TO_ENGINEER_SINGLE,
  ROUTE_TO_ENGINEER_UPDATE,
  ROUTE_TO_EMPLOYERS,
  ROUTE_TO_EMPLOYER_SINGLE,
  ROUTE_TO_CONTACT,
  ROUTE_TO_SURVEYS,
  ROUTE_TO_SURVEY_SINGLE,
  ROUTE_TO_SURVEY_UPDATE,
  ROUTE_TO_VERIFY_CONGRATS,
  ROUTE_TO_USERS,
  ROUTE_TO_USER_UPDATE,
  ROUTE_TO_SYSTEM_SETTINGS,
  ROUTE_TO_ADMINS,
  ROUTE_TO_ADMIN_UPDATE,
  ROUTE_TO_ADMIN_USER_DAHSBOARD
} from '../../constants/routes'
import StrictlyPublicRoute from '../../components/StrictlyPublicRoute'
import PrivateRoute from '../../components/PrivateRoute'
import RecoveryPassword from '../Auth/RecoveryPassword'
import { getCurrentUserAction } from '../Auth/actions'
import { errorNotificationAction, successNotificationAction } from '../Notifications/actions'
import CreateAccount from '../Managers/CreateAccount'
import ForgotPassword from '../Auth/ForgotPassword'
import ProfilePage from '../Managers/ProfilePage'
import CustomerSingle from '../Customers/Single'
import CustomerUpdate from '../Customers/Update'
import { getToken } from '../../utils/tokenCRUD'
import Customers from '../Customers/List'
import Login from '../Auth/Login'
import Jobs from '../Jobs/List'
import JobSingle from '../Jobs/Single'
import JobUpdate from '../Jobs/Update'
import Engeneers from '../Engineers/List'
import EngineerSingle from '../Engineers/Single'
import EngineerUpdate from '../Engineers/Update'
import Employers from '../Employers/List'
import EmployerSingle from '../Employers/Single'
import ContactUs from '../ContactUs'
import Dashboard from '../Dashboard'
import Surveys from '../Surveys/List'
import SurveySingle from '../Surveys/Single'
import SurveyUpdate from '../Surveys/Update'
import VerifyCongrats from '../Verify/VerifyCongrats'
import Users from '../Users/List'
import UserUpdate from '../Users/Update'
import SystemSettings from '../SystemSettings'
import Admins from '../Admins/List'
import AdminUpdate from '../Admins/Update'
import AdminUserDashboard from '../Dashboard/SuperAdminDashboard'
import { CompatRoute } from "react-router-dom-v5-compat"

const SurveyCategory = createContext();

const App = (props) => {
  const currentUserData = { user: props.user, getCurrentUser: props.getCurrentUser }
  const notificationData = {
    successNotification: props.successNotification,
    errorNotification: props.errorNotification
  }

  useEffect(() => {
    const userInSystem = getToken()
    if (userInSystem && !props.user) props.getCurrentUser()
  }, [])

  return (
    <CompatRouter>
      <Switch>
        <PrivateRoute exact path={ROUTE_TO_ROOT} component={Dashboard} />
        <CompatRoute exact path={ROUTE_TO_LOGIN} component={Login} />
        <StrictlyPublicRoute exact path={ROUTE_TO_FORGOT_PASSWORD} component={ForgotPassword} />
        <StrictlyPublicRoute exact path={ROUTE_TO_CREATE_ACCOUNT} component={CreateAccount} />
        <StrictlyPublicRoute exact path={ROUTE_TO_RECOVERY_PASSWORD} component={RecoveryPassword} />
        <PrivateRoute
          exact
          path={ROUTE_TO_PROFILE}
          component={() =>
            <ProfilePage
              currentUserData={currentUserData}
              notificationData={notificationData}
            />
          }
        />

        {/* customers */}
        <PrivateRoute exact path={ROUTE_TO_CUSTOMERS} component={Customers} />
        <PrivateRoute exact path={ROUTE_TO_CUSTOMER_SINGLE} component={CustomerSingle} />
        <PrivateRoute exact path={ROUTE_TO_CUSTOMER_UPDATE} component={CustomerUpdate} />

        {/* jobs */}
        <PrivateRoute exact path={ROUTE_TO_JOBS} component={Jobs} />
        <PrivateRoute exact path={ROUTE_TO_JOB_SINGLE} component={JobSingle} />
        <PrivateRoute exact path={ROUTE_TO_JOB_UPDATE} component={JobUpdate} />

        {/* Thermal surveys */}
        <PrivateRoute exact path={ROUTE_TO_SURVEYS} component={Surveys} />
        <PrivateRoute exact path={ROUTE_TO_SURVEY_SINGLE} component={SurveySingle} />
        <PrivateRoute exact path={ROUTE_TO_SURVEY_UPDATE} component={SurveyUpdate} />

        {/* users */}
        <PrivateRoute exact path={ROUTE_TO_USERS} component={Users} />
        <PrivateRoute exact path={ROUTE_TO_USER_UPDATE} component={UserUpdate} />
        <PrivateRoute exact path={ROUTE_TO_ADMIN_USER_DAHSBOARD} component={AdminUserDashboard} />

        {/* engineers */}
        <PrivateRoute
          exact
          path={ROUTE_TO_ENGINEERS}
          component={() => <Engeneers currentUserData={currentUserData} notificationData={notificationData} />}
        />
        <PrivateRoute exact path={ROUTE_TO_ENGINEER_SINGLE} component={EngineerSingle} />
        <PrivateRoute exact path={ROUTE_TO_ENGINEER_UPDATE} component={EngineerUpdate} />

        {/* employers */}
        <PrivateRoute
          exact
          path={ROUTE_TO_EMPLOYERS}
          component={() => <Employers currentUserData={currentUserData} notificationData={notificationData} />}
        />
        <PrivateRoute exact path={ROUTE_TO_EMPLOYER_SINGLE} component={EmployerSingle} />

        {/* contact us */}
        <PrivateRoute exact path={ROUTE_TO_CONTACT} component={ContactUs} />

        {/* survays */}
        <PrivateRoute exact path={ROUTE_TO_SURVEYS} component={Surveys} />

        {/* congrats page after email verify */}
        <StrictlyPublicRoute exact path={ROUTE_TO_VERIFY_CONGRATS} component={VerifyCongrats} />

        {/* system settings */}
        <PrivateRoute
          exact
          path={ROUTE_TO_SYSTEM_SETTINGS}
          component={() => <SystemSettings currentUserData={currentUserData} notificationData={notificationData} />}
        />

        {/* admins */}
        <PrivateRoute exact path={ROUTE_TO_ADMINS} component={Admins} />
        <PrivateRoute exact path={ROUTE_TO_ADMIN_UPDATE} component={AdminUpdate} />

        <Redirect to={ROUTE_TO_ROOT} />
      </Switch>
    </CompatRouter>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({ user })
const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(getCurrentUserAction()),
  // Notifications
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message))
})

App.propTypes = {
  user: objectOf(any),
  getCurrentUser: func
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
export { SurveyCategory };
