/* eslint-disable */
import React from 'react';
import { func, string } from 'prop-types';

const TreatmentPlan = ({
                         close,
                         magnetic_filter_fitted,
                         boiler_model,
                         boiler_manufacturer,
                         boiler_installation_date,
                         boiler_commission_date,
                         system_balanced,
                         inhibitor_manufacturer,
                         boiler_service_date,
                         boiler_warranty_submitted,
                         radiators_replaced,
                         inhibitor_description,
                         water_test_result,
                         manual_flush,
                         power_flush,
                         chemical_manufacturer,
                         cleaning_description
                       }) => {
  return (
    <section id='popups' className='popup__main'>
      <div className='popup__block popup__surveyplan'>
        <div style={{ position: 'relative' }}>
          <h1 className='surveyPopup-title'>Treatment Plan</h1>
          <button
            className='surveyPopup__close'
            data-toggle='class'
            data-target='#popups'
            data-classes='surveyplan'
            type='button'
            onClick={close}>
            <i className='icons i24x24 icon-close'/>
          </button>
        </div>

        <div className='surveyPopup'>
          <div className='surveyPopup__columns'>
            <div className='surveyPopup__column'>
              <div className='surveyPopup__block'>
                <div className='surveyPopup__title'>Full system replaced</div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Boiler Manufacturer</div>
                  <div className='surveyPopup__text'>{boiler_manufacturer || '-'}</div>
                </div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Model</div>
                  <div className='surveyPopup__text'>{boiler_model || '-'}</div>
                </div>
                <div className='surveyPopup__dates'>
                  <div>
                    <div className='surveyPopup__subtitle'>Installation Date</div>
                    <div className='surveyPopup__text'>{boiler_installation_date || '-'}</div>
                  </div>
                  <div>
                    <div className='surveyPopup__subtitle'>Commission Date</div>
                    <div className='surveyPopup__text'>{boiler_commission_date || '-'}</div>
                  </div>
                </div>
              </div>
              <div className='surveyPopup__block'>
                <div className='surveyPopup__title'>Warranty Submitted</div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Service Date</div>
                  <div className='surveyPopup__text'>{boiler_warranty_submitted ? boiler_service_date : '-'}</div>
                </div>
              </div>
              <div className='surveyPopup__block'>
                <div className='surveyPopup__title'>System re piped</div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Radiators replaced</div>
                  <div className='surveyPopup__text'>{radiators_replaced || '-'}</div>
                </div>
              </div>
            </div>
            <div className='surveyPopup__column'>
              <div className='surveyPopup__block'>
                <div className={`surveyPopup__text checkbox ${magnetic_filter_fitted ? 'checked' : ''}`}>
                  Magnetic Filter Fitted
                </div>
              </div>
              <div className='surveyPopup__block'>
                <div className={`surveyPopup__text checkbox ${system_balanced ? 'checked' : ''}`}>System Balanced</div>
              </div>
              <div className='surveyPopup__block surveyPopup__block-inhibitor'>
                <div className='surveyPopup__title'>Inhibitor Added</div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Manufacturer</div>
                  <div className='surveyPopup__text'>{inhibitor_manufacturer || '-'}</div>
                </div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Description</div>
                  <div className='surveyPopup__text'>{inhibitor_description || '-'}</div>
                </div>
              </div>
              <div className='surveyPopup__block'>
                <div className='surveyPopup__title'>Water Test</div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Result</div>
                  <div className='surveyPopup__text'>{water_test_result ? water_test_result : '-'}</div>
                </div>
              </div>
            </div>

            <div className='surveyPopup__column'>
              <div className='surveyPopup__block  surveyPopup__block-padding'>
                <div className='surveyPopup__title'>Manual Flush</div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Result</div>
                  <div className='surveyPopup__text'>{manual_flush ? 'Success' : '-'}</div>
                </div>
              </div>
              <div className='surveyPopup__block surveyPopup__block-padding'>
                <div className='surveyPopup__title'>Power Flush</div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Result</div>
                  <div className='surveyPopup__text'>{power_flush ? 'Success' : '-'}</div>
                </div>
              </div>
              <div className='surveyPopup__block surveyPopup__block-padding'>
                <div className='surveyPopup__title'>Chemical clean</div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Manufacturer</div>
                  <div className='surveyPopup__text'>{chemical_manufacturer ? chemical_manufacturer : '-'}</div>
                </div>
                <div className='surveyPopup__data'>
                  <div className='surveyPopup__subtitle'>Description</div>
                  <div className='surveyPopup__text'>{cleaning_description ? cleaning_description : '-'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TreatmentPlan.propTypes = {
  close: func.isRequired,
  magnetic_filter_fitted: string,
  boiler_model: string,
  boiler_manufacturer: string,
  boiler_installation_date: string,
  boiler_commission_date: string,
  system_balanced: string,
  inhibitor_manufacturer: string,
  created_at: string
};

export default TreatmentPlan;
