import typeToReducer from 'type-to-reducer'
import { DELETE_JOB_BY_ID, GET_JOB_BY_ID, GET_JOBS } from './constants'

export const initialState = {
  inProcess: false,
  deleteInProgress: false,
  updateInProgress: false,
  per_page: null,
  total_pages: null,
  count: 0,
  job: {},
  jobs: [],
  errors: null
}

export default typeToReducer(
  {
    [GET_JOBS]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          jobs: action.payload.data.jobs.data,
          per_page: action.payload.data.per_page,
          total_pages: action.payload.data.total_pages,
          count: action.payload.data.count,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [GET_JOB_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          inProcess: false,
          job: action.payload.data.job && action.payload.data.job.data.attributes
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          job: {},
          inProcess: false
        }
      }
    },
    [DELETE_JOB_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          deleteInProgress: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          deleteInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          deleteInProgress: false
        }
      }
    }
  },
  initialState
)
