import typeToReducer from 'type-to-reducer'
import { GET_STATISTIC, GET_STATISTIC_ADMIN, GET_STATISTIC_ADMIN_USER } from './constants'

export const initialState = {
  inProcess: false,
  errors: null,
  statistic: {}
}

export default typeToReducer(
  {
    [GET_STATISTIC]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          inProcess: false,
          statistic: action.payload.data
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [GET_STATISTIC_ADMIN]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          inProcess: false,
          statistic: action.payload.data
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [GET_STATISTIC_ADMIN_USER]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          inProcess: false,
          statistic: action.payload.data
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    }
  },
  initialState
)
