import React, { useState } from 'react'
import moment from 'moment'
import { bool, number, shape, string } from 'prop-types'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const AuditItem = ({ auditNo, audit }) => {
  const { is_completed, created_at, user_name, radiator_before } = audit?.data?.attributes
  let report_params = []
  report_params = audit?.data?.attributes?.report_params

  const [showDetails, setShowDetails] = useState(false)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <div className='audits__list--item'>
      <div className='audits__list--header'>
        <button className='btns btn-i btn-audit' type='button' onClick={() => setShowDetails(!showDetails)}>
          Audit №{auditNo}
          <i className={`icons i24x24 ${showDetails ? 'arrow-up_yellow' : 'arrow-down_yellow'}`} />
        </button>
        <span className='badge-circle'>{report_params?.length}</span>
      </div>

      {showDetails ? (
        <div className='audits__list--body'>
          <div className='formDefault__body--columns clearfix'>
            <div className='formDefault__body--column column4'>
              <div className='formDefault__title'>Assigned</div>
              <div className='formDefault__bg'>
                <div className='formDefault__row'>
                  <label className='formDefault__label'>Assigned to Engineer</label>
                  <div className='formDefault__text'>{user_name}</div>
                </div>
              </div>
            </div>
            <div className='formDefault__body--column column4'>
              <div className='formDefault__title'>Date/Time</div>
              <div className='formDefault__bg'>
                <div className='formDefault__row'>
                  <label className='formDefault__label'>Audit Date</label>
                  <div className='formDefault__text'>{moment(created_at).format('DD/MM/YYYY')}</div>
                </div>
                <div className='formDefault__row'>
                  <label className='formDefault__label'>Audit Time</label>
                  <div className='formDefault__text'>{moment(created_at).format('LT')}</div>
                </div>
              </div>
            </div>
            <div className='formDefault__body--column column4'>
              <div className='formDefault__title'>Additional Info</div>
              <div className='formDefault__bg'>
                <div className='formDefault__row'>
                  <label className='formDefault__label'>Audit STATUS</label>
                  <div className='formDefault__text'>{is_completed ? 'COMPLETED' : 'IN PROCESS'}</div>
                </div>
              </div>
            </div>
            <div className='formDefault__body--column column4'>
              <div className='formDefault__title'>Radiator</div>
              <div className='formDefault__bg'>
                <div className='formDefault__row'>
                  <label className='formDefault__label'>Before</label>
                  <img className='formDefault__text thermal_img' src={radiator_before} alt="Radiator Before" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={"desktop" || this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {report_params ? report_params.map((report, index) => {
                return (
                  <div key={index}>
                    <p>{report['room_name']}</p>
                    <img className='thermal_img' src={report['image_url']} alt="" />
                    <p>Efficiency - {report['efficiency'] && report['efficiency'].toFixed(2)}</p>
                    <p>Avg Temp - {report['avg_temp']}</p>
                    <p>Delta Temp - {report['delta_temp']}</p>
                  </div>
                )
              }): null}
            </Carousel>
          </div>
        </div>
      ) : null}
    </div>
  )
}

AuditItem.propTypes = {
  auditNo: number.isRequired,
  audit: shape({
    is_completed: bool.isRequired,
    created_at: string.isRequired,
    user_name: string.isRequired
  })
}

export default AuditItem
