import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { any, func, bool, objectOf, string, instanceOf } from 'prop-types'
import {
  ROUTE_TO_ENGINEERS,
  ROUTE_TO_ROOT
} from '../../../constants/routes'
import {
  getEngineerByIdAction
} from '../actions';
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import navData from '../../../constants/navData'
import Tabs from '../../../components/Tabs'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Engineers',
    link: ROUTE_TO_ENGINEERS
  }
];

const Header = ({ engineerName }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={engineerName}/>
      <ProfileMenu />
    </SiteHeader>
  );
};

Header.propTypes = {
  engineerName: string.isRequired
};

const EngineerSingle = ({ engineer, inProgress, getEngineer, role, match }) => {
  const { id } = match.params;

  useEffect(() => {
    getEngineer(id);
  }, []);

  if (!engineer.id) return (<div/>)

  const { name } = engineer

  const tabsTitle = [
    {id: engineer.id, props: { component: 'Engineer', label:'Info', value: '1'}}
  ]

  return (
    <StaticPage sidebarData={navData[role]} header={<Header engineerName={engineer.name} />}>
      <div className='customer__page pToppBottom'>
        <Tabs children={tabsTitle} />
      </div>
    </StaticPage>
  );
};

EngineerSingle.propTypes = {
  engineer: objectOf(any),
  inProgress: bool,
  role: string,
  getEngineer: func.isRequired,
  match: objectOf(any),
  tabsTitle: instanceOf(Array)
};

const mapStateToProps = ({ engeneers, auth }) => {
  return {
    engineer: engeneers.engineer,
    inProgress: engeneers.inProgress,
    role: auth.user && auth.user.role
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEngineer: (id) => dispatch(getEngineerByIdAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EngineerSingle);
