import { connect } from 'react-redux'
import { compose } from 'redux'
import Popup from 'reactjs-popup'
import { Field, reduxForm } from 'redux-form'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import React, { Component, useEffect, useState } from 'react'
import { any, func, bool, objectOf, string } from 'prop-types'
import ReduxFormField from '../../../components/ReduxFormField'

import {
  ROUTE_TO_ROOT,
  ROUTE_TO_SURVEYS,
  ROUTE_TO_SURVEY_SINGLE,
  routeToSurveySingle,
  routeToSurveyUpdate,
  routeToUpdateEPCDetails
} from '../../../constants/routes'

import {
  getSurveyByIdAction,
  deleteSurveyByIdAction
} from '../actions'

import fieldTrimNormalizer from '../../../utils/fieldTrimNormalizer'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import navData from '../../../constants/navData'
import InviteForm from '../../Engineers/InviteForm'
import TreatmentPlan from './TreatmentPlan'
import EPCMetric from './EPCMetric'
import config from '../../../config'
import DeletePopup from '../../../components/DeletePopup'
import validate from '../Update/validate'
import UpdateEPCForm from './UpdateEPCForm'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Surveys',
    link: ROUTE_TO_SURVEYS
  }
]

const Header = ({ role }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role}/>
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  role: string
}

const SurveyDetails = ({ survey, inProgress, deleteInProgress, getSurvey, deleteSurvey, role, match }) => {
  const { id, activeTab } = match.params
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [optionsList, setOptionsList] = useState(null)
  const [showEpcEdit, setShowEpcEdit] = useState(false)
  const [metricTypeValue, setMetricTypeValue] = useState('input')
  const [selectMetricTypeOptions, setSelectMetricTypeOptions] = useState(null)

  useEffect(() => {
    getSurvey(id)
  }, [])

  const onEditClickHandler = (metric) => {
    setShowEpcEdit(true)
  }

  const handleSubmit = (event) => {
    console.log("EPC submit", match)
  }

  const selectChangeHandlerHandler = (metric) => {
    console.log("Metrics..", metric)
    setOptionsList(metric["options"])
  }

  const metricTypeHandler = (type) => {
    console.log("Metric Type Handler", type)
    const metricType = [
      {type: 1, value: 'text'},
      {type: 2, value: 'select'},
      {type: 4, value: 'textarea'},
      {type: 5, value: 'file'}
    ]

    metricType.map((mt, index) => {
      if (mt["type"] == type) {
        console.log("Metric Type Value", mt["value"])
        setMetricTypeValue(mt["value"])
        return mt["value"]
      }
    })
  }

  if (!survey.id) return (<div/>)

  const responsive = {
    superLargeDesktop: {
      //the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const {
    customer,
    description,
    survey_reference,
    is_completed,
    is_reports,
    start_date,
    start_time,
    created_data,
    engineer,
    employer,
    audits,
    pdf_url,
    treatment_plan,
    metrics
  } = survey

  const {
    full_name,
    contact_name,
    contact_phone_number,
    contact_email,
    post_code,
    reference_id,
    address
  } = customer || ""

  const epcMetrics = metrics?.data?.attributes?.report_params
  const epcId = metrics?.data?.attributes?.id

  return (
    <div className="formDefault fullWidth viewForm">
      <div className="formDefault__body">
        <div className="survey__page--audits">
          <div className="audits__list">
            <div className='formDefault__header clearfix p1 m0'>
              <h1 className='formDefault__header--title'>
                { epcMetrics ?
                    'Metrics of EPC Evaluation'
                  :
                    'Needs to perform EPC Evaluation!!!'
                }
              </h1>

              <div className='formDefault__header--btns p0'>
                { epcMetrics && !showEpcEdit ?
                    <button
                      type='button'
                      className='btns btn-i btn-yellow edit-epc-btn'
                      onClick={() => setShowEpcEdit(true)}>
                      Edit EPC
                    </button>
                  : null
                }
              </div>
            </div>
            <EPCMetric
              survey={survey}
              epcId={epcId}
              epcMetrics={epcMetrics}
              error={error}
              showEpcEdit={showEpcEdit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

SurveyDetails.propTypes = {
  survey: objectOf(any),
  inProgress: bool,
  deleteInProgress: bool,
  role: string,
  getSurvey: func.isRequired,
  match: objectOf(any)
}

const mapStateToProps = ({ surveys, auth }) => {
  return {
    survey:     surveys.survey,
    inProgress: surveys.inProgress,
    role:       auth.user && auth.user.role
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSurvey:    (id) => dispatch(getSurveyByIdAction(id)),
  deleteSurvey: (id) => dispatch(deleteSurveyByIdAction(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyDetails)
