import _ from 'lodash'

const blankFieldError = 'Field cannot be blank'
const passwordWrongLength = 'From 6 to 72 symbols'

export const isPasswordLengthCorrect = (password) => {
  const re = /^.{6,72}$/
  return re.test(password)
}

export default (values) => {
  const { email, password } = values
  const errors = {}

  const trimmedEmail = _.trim(email)
  if (!trimmedEmail) {
    errors.email = blankFieldError
  }

  const trimmedPassword = _.trim(password)
  if (!trimmedPassword) {
    errors.password = blankFieldError
  } else if (!isPasswordLengthCorrect(trimmedPassword)) {
    errors.password = passwordWrongLength
  }

  return errors
}
