/* eslint-disable */
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { any, func, bool, objectOf, string } from 'prop-types'
import {
  ROUTE_TO_CUSTOMERS,
  ROUTE_TO_ROOT,
  routeToCustomerUpdate
} from '../../../constants/routes'
import {
  getCustomerByIdAction,
  deleteCustomerByIdAction
} from '../actions'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import navData from '../../../constants/navData'
import DeletePopup from '../../../components/DeletePopup'
import { useNavigate } from 'react-router-dom-v5-compat'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Customers',
    link: ROUTE_TO_CUSTOMERS
  }
];

const Header = ({ customer }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={customer}/>
      <ProfileMenu />
    </SiteHeader>
  );
};

Header.propTypes = {
  customer: string.isRequired
};

const CustomerSingle = ({ customer, inProgress, deleteInProgress, getCustomer, deleteCustomer, role, match }) => {
  const navigate = useNavigate()
  const { id } = match.params;
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  useEffect(() => {
    getCustomer(id);
  }, []);

  const onDeleteHandler = async () => {
    try {
      await deleteCustomer(id);
      navigate(ROUTE_TO_CUSTOMERS)
    } catch (e) {
      console.log(e);
    }
  };

  const {
    full_name,
    address,
    reference_id,
    post_code,
    contact_name,
    contact_phone_number,
    contact_email,
    created_at
  } = customer;

  console.log(moment(created_at).format('DD/MM/YYYY'))

  return (
    <StaticPage sidebarData={navData[role]} header={<Header customer={contact_name}/>}>
      <div className='customer__page pToppBottom'>
        <section className='customer__page--section section__customer section__border customer'>
          <div className='formDefault fullWidth viewForm'>
            <div className='formDefault__header clearfix'>
              <h1 className='formDefault__header--title'>Customer View</h1>
              <div className='formDefault__header--btns'>
                <Link className='btns btn-i btn-edit' to={routeToCustomerUpdate(id)}>
                  EDIT
                </Link>
                <button
                  className='btns btn-i btn-delete'
                  data-toggle='class'
                  data-target='#popups'
                  data-classes='userdelete'
                  onClick={() => setShowDeletePopup(true)}
                  disabled={deleteInProgress}>
                  {deleteInProgress ? 'Deleting...' : 'Delete'}
                </button>
              </div>
            </div>
            {!inProgress ? (
              <div className='formDefault__body'>
                <div className='formDefault__body--columns clearfix'>
                  <div className='formDefault__body--column column2'>
                    <div className='formDefault__title'>Customer Info</div>
                    <div className='formDefault__bg'>
                      <div className='formDefault__row'>
                        <label className='formDefault__label'>Full Customer Name (Private or Company)</label>
                        <div className='formDefault__text'>{full_name}</div>
                      </div>
                      <div className='formDefault__row'>
                        <label className='formDefault__label'>Customer Address</label>
                        <div className='formDefault__text'>{address}</div>
                      </div>
                      {/*<div className='formDefault__row'>*/}
                      {/*  <label className='formDefault__label'>Reference ID</label>*/}
                      {/*  <div className='formDefault__text'>{reference_id}</div>*/}
                      {/*</div>*/}
                      <div className='formDefault__row'>
                        <label className='formDefault__label'>Customer PostCode</label>
                        <div className='formDefault__text'>{post_code}</div>
                      </div>
                    </div>
                  </div>
                  <div className='formDefault__body--column column2'>
                    <div className='formDefault__title'>Contact Info</div>
                    <div className='formDefault__bg'>
                      <div className='formDefault__row'>
                        <label className='formDefault__label'>Contact Name</label>
                        <div className='formDefault__text'>{contact_name}</div>
                      </div>
                      <div className='formDefault__row'>
                        <label className='formDefault__label'>Contact Phone Number</label>
                        <div className='formDefault__text'>{contact_phone_number}</div>
                      </div>
                      <div className='formDefault__row'>
                        <label className='formDefault__label'>Contact Email </label>
                        <div className='formDefault__text'>{contact_email}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          </div>
          <div className='customer__page--created'>
            <strong>Created</strong> At {moment(created_at).format('DD/MM/YYYY')}
          </div>
        </section>
      </div>
      <DeletePopup target='Customer' descr='If you delete this customer, it will remove all his jobs and surveys' isOpen={showDeletePopup} onDelete={onDeleteHandler} onCancel={() => setShowDeletePopup(false)} />
    </StaticPage>
  );
};

CustomerSingle.propTypes = {
  customer: objectOf(any),
  inProgress: bool,
  deleteInProgress: bool,
  role: string,
  getCustomer: func.isRequired,
  deleteCustomer: func.isRequired,
  match: objectOf(any)
};

const mapStateToProps = ({ customers, auth }) => {
  return {
    customer: customers.customer,
    inProgress: customers.inProgress,
    deleteInProgress: customers.deleteInProgress,
    role: auth.user && auth.user.role
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCustomer: (id) => dispatch(getCustomerByIdAction(id)),
  deleteCustomer: (id) => dispatch(deleteCustomerByIdAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSingle);
