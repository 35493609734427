import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { string, bool, func, arrayOf, object, number } from 'prop-types'

import { ROUTE_TO_ROOT, routeToJobSingle, routeToJobUpdate } from '../../../constants/routes'
import HeaderSearch from '../../../components/HeaderSearch'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import tableHeaders from '../../../utils/tableHeaders'
import navData from '../../../constants/navData'
import Table from '../../../components/Table'
import { getJobsAction } from '../actions'
import history from '../../../history'
import { getEmployersAction } from '../../Employers/actions'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const Header = ({ onSearch, currentUser }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current='Jobs' />
      <HeaderSearch onSubmit={onSearch} />
      <ProfileMenu currentUser={currentUser} />
    </SiteHeader>
  )
}

Header.propTypes = {
  onSearch: func.isRequired
}

const Jobs = ({ role, jobs, inProcess, getJobs, employers, getEmployers, count, user }) => {
  const [activeEmployer, setActiveEmployer] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    getJobs({ page, per_page: perPage, employer_id: activeEmployer })
  }, [page, perPage])

  useEffect(() => {
    getEmployers()
  }, [])

  const onEmployerChange = (v) => {
    getJobs({ employer_id: v })
    setActiveEmployer(v)
  }

  const onRowClickHandler = ({ id }) => history.push(routeToJobSingle(id))

  // eslint-disable-next-line consistent-return
  const renderColumns = () => {
    if (role === 'employer') return tableHeaders.jobs[role]
    if (role === 'engineer') {
      return tableHeaders.jobs[role](onEmployerChange, activeEmployer, employers)
    }
  }

  const paginationHandler = (params) => getJobs(params)

  return (
    <StaticPage sidebarData={navData[role]} header={<Header onSearch={getJobs} currentUser={user} />}>
      <div className='customers__page pTop'>
        <div className='page__header clearfix'>
          <div className='page__btns'>
            <Link className='btns btn-i btn-yellow' to={routeToJobUpdate()}>
              ADD NEW Job
            </Link>
          </div>
          <h1 className='page__title'>Jobs</h1>
        </div>
      </div>
      <Table
        onChangePage={setPage}
        per_page={perPage}
        page={page}
        changePerPageHandler={setPerPage}
        count={count}
        paginationHandler={paginationHandler}
        data={jobs}
        columns={renderColumns()}
        inProcess={inProcess}
        onClickHandler={onRowClickHandler}
      />
    </StaticPage>
  )
}

Jobs.propTypes = {
  role: string,
  inProcess: bool,
  user: object,
  employers: arrayOf(object),
  jobs: arrayOf(object),
  count: number.isRequired,
  getJobs: func.isRequired,
  getEmployers: func.isRequired
}

const mapStateToProps = ({ auth, jobs, employers }) => {
  const data = {
    ...jobs,
    employers: employers.employers && employers.employers.data
  }

  const { user } = auth
  if (user) data.user = user
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getJobs: (params) => dispatch(getJobsAction(params)),
  getEmployers: (params) => dispatch(getEmployersAction(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Jobs)
