import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { any, func, bool, objectOf, string } from 'prop-types'
import {
  ROUTE_TO_ROOT,
  ROUTE_TO_SURVEYS,
  routeToSurveyUpdate
} from '../../../constants/routes'
import {
  getSurveyByIdAction,
  deleteSurveyByIdAction
} from '../actions'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import SiteHeader from '../../../components/SiteHeader'
import TreatmentPlan from './TreatmentPlan'
import config from '../../../config'
import DeletePopup from '../../../components/DeletePopup'
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Surveys',
    link: ROUTE_TO_SURVEYS
  }
];

const Header = ({ role }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role}/>
      <ProfileMenu />
    </SiteHeader>
  );
};

Header.propTypes = {
  role: string
};

const SurveyDetails = ({ survey, inProgress, deleteInProgress, getSurvey, deleteSurvey, role, match }) => {
  const navigate = useNavigate()
  const [planPopupShow, setPlanPopupShow] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const closePlanPopupHandler = () => setPlanPopupShow(false)

  const { id } = match.params;
  useEffect(() => {
    getSurvey(id);
  }, []);

  const onDeleteHandler = async () => {
    try {
      await deleteSurvey(id);
      navigate(ROUTE_TO_SURVEYS);
    } catch (e) {
      console.log(e);
    }
  };

  if (!survey.id) return (<div/>)

  const {
    customer,
    description,
    survey_reference,
    is_completed,
    is_reports,
    start_date,
    start_time,
    created_data,
    engineer,
    employer,
    pdf_url,
    treatment_plan
  } = survey;

  const {
    full_name,
    contact_name,
    contact_phone_number,
    contact_email,
    post_code,
    reference_id,
    address
  } = customer || "";

  return (
    <div>
      <div className="formDefault fullWidth viewForm">
        <div className="formDefault__header clearfix">
          <h1 className="formDefault__header--title">Survey View</h1>
          <div className="formDefault__header--btns">
            <Link className='btns btn-i btn-edit' to={routeToSurveyUpdate(id)}>
              EDIT SURVEY
            </Link>
            <button
              className='btns btn-i btn-delete'
              data-toggle="class"
              data-target="#popups"
              data-classes="userdelete"
              type='button'
              onClick={() => setShowDeletePopup(true)}
              disabled={deleteInProgress}>
              {deleteInProgress ? 'Deleting...' : 'Delete this Survey'}
            </button>
          </div>
        </div>
        <Popup
          open={planPopupShow}
          onClose={closePlanPopupHandler}
          modal
          closeOnDocumentClick>
          <TreatmentPlan {...treatment_plan} close={closePlanPopupHandler} />
        </Popup>

        {!inProgress ? (
          <div className="formDefault__body">
            <div className="formDefault__body--columns clearfix">
              <div className="formDefault__body--column column3">
                <div className="formDefault__title">Customer Info</div>
                <div className="formDefault__bg">
                  <div className="formDefault__row">
                    <label className="formDefault__label">Full Customer Name (Private or Company)</label>
                    <div className="formDefault__text">{full_name}</div>
                  </div>
                  <div className="formDefault__row">
                    <label className="formDefault__label">Contact Name</label>
                    <div className="formDefault__text">{contact_name}</div>
                  </div>
                  <div className="formDefault__row">
                    <label className="formDefault__label">Contact Phone Number</label>
                    <div className="formDefault__text">{contact_phone_number}</div>
                  </div>
                  <div className="formDefault__row">
                    <label className="formDefault__label">Customer Email</label>
                    <div className="formDefault__text">{contact_email}</div>
                  </div>
                  <div className="formDefault__row">
                    <label className="formDefault__label">Customer Postcode</label>
                    <div className="formDefault__text">{post_code}</div>
                  </div>
                  <div className="formDefault__row">
                    <label className="formDefault__label">Reference ID</label>
                    <div className="formDefault__text">{reference_id}</div>
                  </div>
                  <div className="formDefault__row">
                    <label className="formDefault__label">Customer Address</label>
                    <div className="formDefault__text">{address}</div>
                  </div>
                </div>
                <div className="formDefault__row">
                  <a href={`mailto: ${contact_email}`}>
                    <button type='button' className="btns btn-b btn-yellow btn-email">
                      Email Customer
                    </button>
                  </a>
                </div>
              </div>
              <div className="formDefault__body--column column3">
                <div className="formDefault__title">Survey Info</div>
                <div className="formDefault__bg">
                  <div className="formDefault__row">
                    <label className="formDefault__label">Survey Reference Number</label>
                    <div className="formDefault__text">{survey_reference}</div>
                  </div>
                  <div className="formDefault__row">
                    <label className="formDefault__label">Survey Description</label>
                    <div className="formDefault__text">{description}</div>
                  </div>
                </div>

                {role === 'employer' ? (
                  <>
                    <div className="formDefault__title">Assigned</div>
                    <div className="formDefault__bg">
                      <div className="formDefault__row">
                        <label className="formDefault__label">Assigned to Engineer</label>
                        <div className="formDefault__text">{engineer && engineer.name}</div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="formDefault__title">Employer</div>
                    <div className="formDefault__bg">
                      <div className="formDefault__row">
                        <label className="formDefault__label">Employer</label>
                        <div className="formDefault__text">{employer && employer.name}</div>
                      </div>
                    </div>
                  </>
                )}
                <div className="formDefault__title">Date/Time</div>
                <div className="formDefault__bg">
                  <div className="formDefault__row">
                    <label className="formDefault__label">Survey Date</label>
                    <div className="formDefault__text">{moment(start_date).format('DD/MM/YYYY')}</div>
                  </div>
                  <div className="formDefault__row">
                    <label className="formDefault__label">Survey Time</label>
                    <div className="formDefault__text">{start_time}</div>
                  </div>
                </div>
              </div>
              <div className="formDefault__body--column column3">
                <div className="formDefault__title">Additional Info</div>
                <div className="formDefault__bg">
                  <div className="formDefault__row">
                    <label className="formDefault__label">Survey STATUS</label>
                    <div className="formDefault__text">{is_completed ? 'COMPLETED' : 'IN PROGRESS'}</div>
                  </div>
                </div>
                {is_reports ? (
                  <a className="formDefault__bg btns btn-b btn-plan" href={config.REACT_APP_UPLOADS_URL + pdf_url} target='_blank' rel='noopener noreferrer'>
                    <span className="formDefault__label">Download Report</span>
                    <span className="formDefault__text">available</span>
                  </a>
                ) : null}
                <button className="formDefault__bg btns btn-b btn-plan" data-toggle="class" data-target="#popups"
                        data-classes="surveyplan" type='button' onClick={() => setPlanPopupShow(true)}>
                  <span className="formDefault__text">Treatment Plan Review </span>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className='customer__page--created'>
        <strong>Created</strong> At {moment(created_data).format('DD/MM/YYYY')}
      </div>
      <DeletePopup isOpen={showDeletePopup} onCancel={() => setShowDeletePopup(false)} onDelete={onDeleteHandler} target='Survey' />
    </div>
  );
};

SurveyDetails.propTypes = {
  survey: objectOf(any),
  inProgress: bool,
  deleteInProgress: bool,
  role: string,
  getSurvey: func.isRequired,
  deleteSurvey: func.isRequired,
  match: objectOf(any)
};

const mapStateToProps = ({ surveys, auth }) => {
  return {
    survey: surveys.survey,
    inProgress: surveys.inProgress,
    deleteInProgress: surveys.deleteInProgress,
    role: auth.user && auth.user.role
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSurvey: (id) => dispatch(getSurveyByIdAction(id)),
  deleteSurvey: (id) => dispatch(deleteSurveyByIdAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetails);
