const REQUIRED_MESSAGE = 'Required'

export default (values) => {
  const errors = {}
  if (!values.full_name?.trim()) {
    errors.full_name = REQUIRED_MESSAGE
  }
  if (!values.address?.trim()) {
    errors.address = REQUIRED_MESSAGE
  }
  if (!values.post_code?.trim()) {
    errors.post_code = REQUIRED_MESSAGE
  }
  // if (!values.contact_name?.trim()) {
  //   errors.contact_name = REQUIRED_MESSAGE
  // }
  if (!values.contact_phone_number?.trim()) {
    errors.contact_phone_number = REQUIRED_MESSAGE
  }

  if (!values.contact_email?.trim()) {
    errors.contact_email = REQUIRED_MESSAGE
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)) {
    errors.contact_email = 'Invalid email address'
  }
  return errors
}
