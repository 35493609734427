import { createAction } from 'redux-actions'
import {
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  DELETE_CUSTOMER_BY_ID,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER
} from './constants'
import {
  getCustomersApi,
  getCustomerByIdApi,
  deleteCustomerByIdApi,
  createCustomerApi,
  updateCustomerApi
} from './api'

// export const getCustomersAction = createAction(
//   GET_CUSTOMERS, async (params) => {
//     debugger;
//     getCustomersApi(params)
//   })
export const getCustomersAction = createAction(
  GET_CUSTOMERS, async (params) => getCustomersApi(params)
)

export const getCustomerByIdAction = createAction(
  GET_CUSTOMER_BY_ID, async (id) => getCustomerByIdApi(id)
)

export const deleteCustomerByIdAction = createAction(
  DELETE_CUSTOMER_BY_ID, async (id) => deleteCustomerByIdApi(id)
)

export const createCustomerAction = createAction(
  CREATE_CUSTOMER, async (data) => createCustomerApi(data)
)

export const updateCustomerAction = createAction(
  UPDATE_CUSTOMER, async (id, data) => updateCustomerApi(id, data)
)
