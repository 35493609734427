const REQUIRED_MESSAGE = 'Required'

export default (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = REQUIRED_MESSAGE
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}
