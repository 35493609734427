import React from 'react'
import { Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { bool, func, string, shape, number } from 'prop-types'

import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_ADMINS } from '../../../constants/routes'

const AdminForm = ({ handleSubmit, onSubmit, onDelete, submitting, error, initialValues: { id } }) => {
  return (
    <form className='formDefault fullWidth' onSubmit={handleSubmit(onSubmit)}>
      <div className='formDefault__header clearfix'>
        <h1 className='formDefault__header--title'>Admin Details</h1>
        <div className='formDefault__header--btns'>
          <button
            type='button'
            className='btns btn-i btn-delete'
            onClick={(e) => {
              e.preventDefault()
              onDelete(id)
            }}>
            Delete this Admin
          </button>
        </div>
      </div>
      <div className='formDefault__body'>
        <div className='formDefault__body--columns clearfix'>
          <div className='formDefault__body--column column2'>
            <div className='formDefault__title'>Personal info</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Full Name'
                className='formDefault__input'
                type='text'
                name='name'
                id='name'
                placeholder='Full Name'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Email'
                className='formDefault__input'
                type='email'
                name='email'
                id='email'
                placeholder='Email'
                errorNoSpace
              />
            </div>
          </div>

          <div className='formDefault__body--column column2'>
            <div className='formDefault__title'>Password Reset</div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='New password'
                className='formDefault__input'
                type='password'
                name='password'
                id='password'
                placeholder='************'
                errorNoSpace
              />
            </div>
            <div className='formDefault__row'>
              <Field
                component={ReduxFormField}
                label='Repeat New password'
                className='formDefault__input'
                type='password'
                name='password_confirmation'
                id='password_confirmation'
                placeholder='************'
                errorNoSpace
              />
            </div>
          </div>
        </div>
        {error ? <p className='validation-error-message'>{error}</p> : null}
        <div className='formDefault__row--btns'>
          <button
            className={`btns btn-i btn-yellow ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Save'}
          </button>
          <Link className='btns btn-i btn-trns' to={ROUTE_TO_ADMINS}>
            Cancel
          </Link>
        </div>
      </div>
    </form>
  )
}

AdminForm.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  submitting: bool.isRequired,
  onDelete: func,
  error: string,
  initialValues: shape({
    id: number
  })
}

export default AdminForm
