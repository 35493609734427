import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { any, bool, func, objectOf, string } from 'prop-types'

import { successNotificationAction } from '../../Notifications/actions'
import AccountFormWrapper from '../../../components/AccountFormWrapper'
import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_LOGIN } from '../../../constants/routes'
import { recoveryPasswordAction } from '../actions'
import validate from './validate'
import { useNavigate } from "react-router-dom-v5-compat"

const RecoveryPassword = ({ handleSubmit, onSubmit, submitting, error, successNotification, location: { search } }) => {
  const navigate = useNavigate();

  const onFormSubmit = async ({ password, password_confirmation }) => {
    const token = search && search.substr(7)

    await onSubmit({ user: { password: password.trim(), password_confirmation: password_confirmation.trim(), token } })
    successNotification('Your password has been changed')
    // history.push(ROUTE_TO_LOGIN)
    navigate(ROUTE_TO_LOGIN)
  }

  return (
    <AccountFormWrapper>
      <div className='signin__page--title'>SET NEW PASSWORD</div>
      <form onSubmit={handleSubmit(onFormSubmit)} className='signin__page--form'>
        <div className='form__row row__input'>
          <Field
            component={ReduxFormField}
            className='form__input'
            type='password'
            name='password'
            id='new_password'
            label='Password'
            placeholder='Password'
          />
        </div>
        <div className='form__row row__input'>
          <Field
            component={ReduxFormField}
            className='form__input'
            type='password'
            name='password_confirmation'
            id='password_confirmation'
            label='Repeat new password'
            placeholder='Repeat new password'
          />
        </div>
        <p className='validation-error-message'>{error}</p>
        <div className='form__row row__submit'>
          <button
            className={`form__button btns btn-i ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Submit'}
          </button>
        </div>
      </form>
    </AccountFormWrapper>
  )
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data) => dispatch(recoveryPasswordAction(data)),
  successNotification: (message) => dispatch(successNotificationAction(message))
})

RecoveryPassword.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  successNotification: func.isRequired,
  submitting: bool.isRequired,
  location: objectOf(any),
  search: string,
  error: string
}

export default compose(
  reduxForm({
    form: 'recovery_password',
    validate
  }),
  connect(null, mapDispatchToProps)
)(RecoveryPassword)
