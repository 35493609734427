import typeToReducer from 'type-to-reducer'
import { GET_PRIVACY, GET_TERMS, SET_PRIVACY, SET_TERMS } from './constants'

export const initialState = {
  inProcess: false,
  getPrivacyInProgress: false,
  getTermsInProgress: false,
  privacy: {},
  terms: {},
  errors: []
}

export default typeToReducer(
  {
    [GET_PRIVACY]: {
      START: (state, action) => {
        return {
          ...state,
          getInProgress: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          privacy: action.payload.data.privacy_policy,
          getPrivacyInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          getInProgress: false
        }
      }
    },
    [SET_PRIVACY]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          inProcess: false
        }
      }
    },
    [GET_TERMS]: {
      START: (state) => {
        return {
          ...state,
          getTermsInProgress: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          terms: action.payload.data.terms,
          getTermsInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          getTermsInProgress: false
        }
      }
    },
    [SET_TERMS]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          inProcess: false
        }
      }
    }
  },
  initialState
)
