import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { func, objectOf, any } from 'prop-types'
import logo from '../../assets/img/logo.png'
import logo_2x from '../../assets/img/logo@2x.png'
import logo_3x from '../../assets/img/logo@3x.png'
import { sendConfirmAction } from './actions'
import Spinner from '../../components/Spinner'
import { ROUTE_TO_ROOT } from '../../constants/routes'
import history from '../../history'

function VerifyCongrats({ sendConfirm, match }) {
  const [verifyComplete, setVerifyComplete] = useState(false)

  const { token } = match.params

  const sendConfirmHandler = async () => {
    const res = await sendConfirm({ token })
    if (res.value.status === 200) {
      const { role } = res.action.payload.data.user.data.attributes
      setVerifyComplete(true)
      if (role === 'super_admin') {
        setTimeout(() => {
          history.push(ROUTE_TO_ROOT)
        }, 2000)
      }
    } else {
      setVerifyComplete(false)
    }
  }

  useEffect(() => {
    sendConfirmHandler()
  }, [])

  return (
    <div className='verify_page'>
      <img src={logo} srcSet={`${logo} 1x, ${logo_2x} 2x, ${logo_3x} 3x`} alt='ThermaFy' />
      {verifyComplete ? (
        <>
          <h2>Congratulations!</h2>
          <p>Your email address has been verified. You can now access ThermaFy with your email and password</p>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
VerifyCongrats.propTypes = {
  sendConfirm: func.isRequired,
  match: objectOf(any)
}
const mapDispatchToProps = (dispatch) => ({
  sendConfirm: (params) => dispatch(sendConfirmAction(params))
})

export default connect(null, mapDispatchToProps)(VerifyCongrats)
