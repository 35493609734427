import React from 'react'
import { string, shape } from 'prop-types'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom-v5-compat"

const SidebarItem = ({ item }) => {
  const location = useLocation()
  const { route, title, icon } = item

  return (
    <li className='sidebar__nav--item'>
      <Link
        className={`sidebar__nav--link btns btn-b ${location.pathname === route ? 'active' : ''}`}
        to={route}>
          <i className={`icons i24x24 ${icon}`} />
          {title}
      </Link>
    </li>
  )
}

SidebarItem.propTypes = {
  item: shape({
    route: string,
    title: string,
    icon: string
  })
}

export default SidebarItem
