import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { string, bool, func, arrayOf, object, number } from 'prop-types'
import Popup from 'reactjs-popup'
import { reduxForm, change } from 'redux-form'
import { compose } from 'redux'
import {
  ROUTE_TO_ROOT,
  routeToEngineerSingle
} from '../../../constants/routes'
import HeaderSearch from '../../../components/HeaderSearch'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import tableHeaders from '../../../utils/tableHeaders'
import navData from '../../../constants/navData'
import DeleteRowButton from '../../../components/DeleteRowButton'
import {
  getEngeneersAction,
  createEngineerAction,
  deleteEngineerByIdAction
} from '../actions'
import Table from '../../../components/Table'
import InviteForm from '../InviteForm'
import PopupLogo from '../../../components/PopupLogo'
import validate from '../InviteForm/validate'
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const Header = ({ role, onSearch, currentUser }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role} />
      <HeaderSearch onSubmit={onSearch} />
      <ProfileMenu currentUser={currentUser} />
    </SiteHeader>
  )
}

Header.propTypes = {
  role: string,
  currentUser: object,
  onSearch: func.isRequired
}

const Engineers = ({
  role,
  engineers,
  inProcess,
  getEngeneers,
  user,
  addEngineer,
  notificationData,
  error,
  handleSubmit,
  deleteEngineer,
  setFieldVal,
  count
}) => {
  const navigate = useNavigate()
  const { successNotification, errorNotification } = notificationData
  const [deletePopupShow, setDeletePopupShow] = useState(false)
  const [addEngineerPopupShow, setAddEngineerPopupShow] = useState(false)
  const [reinvitePopupShow, setReinvitePopupShow] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [reinviteItem, setRenviteItem] = useState({})

  useEffect(() => {
    getEngeneers({ page, per_page: perPage })
  }, [page, perPage])

  const sendInvite = async (values) => {
    const res = await addEngineer(values)
    if (res.value.status === 200) {
      successNotification('An invitation has been sent.')
      getEngeneers()
      setAddEngineerPopupShow(false)
    } else {
      errorNotification('Something went wrong')
    }
  }

  const deleteMaybe = (row) => {
    setIdToDelete(row.id)
    setDeletePopupShow(true)
  }

  const deleteEngineerHandler = async () => {
    const res = await deleteEngineer({ engineer_id: idToDelete })
    if (res.value.status === 200) {
      successNotification('User was deleted.')
      getEngeneers()
      setDeletePopupShow(false)
    }
  }

  const reinviteMaybe = (row) => {
    if (row && row.attributes && row.attributes.added === 'Not Approved') {
      setFieldVal('email', row.attributes.email)
      setReinvitePopupShow(true)
    }
  }

  const inviteEngineeer = (row) => {
    if (row && row.attributes) {
      setFieldVal('email', row.attributes.email)
      setFieldVal('name', row.attributes.name)
      setAddEngineerPopupShow(true)
    }
  }

  const columns = (tableHeader) => [
    ...tableHeader,
    {
      cell: (row) => (
        <div className='table__cell cell__delete'>
          <DeleteRowButton onClickHandler={() => deleteMaybe(row)} />
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: '56px'
    },
    {
      cell: (row) => (
        <div className='table__cell cell__invite'>
          <button type='button' className='btns btn-b' onClick={() => inviteEngineeer(row)}>
            <i className='icons i24x24 icon-users_2' />
          </button>
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: '50px'
    }
  ]

  const onRowClickHandler = ({id}) => navigate(routeToEngineerSingle(id))

  return (
    <StaticPage sidebarData={navData[role]} header={<Header role={role} currentUser={user} onSearch={getEngeneers} />}>
      <div className='customers__page pTop'>
        <div className='page__header clearfix'>
          <div className='page__btns'>
            {
              <button type='button' onClick={() => setAddEngineerPopupShow(true)} className='btns btn-i btn-yellow'>
                ADD NEW Engineer
              </button>
            }
            {/* invite popup */}
            <Popup
              open={addEngineerPopupShow}
              onClose={() => setAddEngineerPopupShow(false)}
              modal
              closeOnDocumentClick>
              <InviteForm title='Add new Engineer' handleSubmit={handleSubmit} onSubmit={sendInvite} error={error} />
            </Popup>
            {/* reinvite */}
            <Popup
              open={reinvitePopupShow}
              onClose={() => {
                setReinvitePopupShow(false)
                setFieldVal('email', '')
              }}
              modal
              closeOnDocumentClick>
              <InviteForm
                initialValues={{ ...reinviteItem }}
                handleSubmit={handleSubmit}
                onSubmit={sendInvite}
                title='Reinvite Engineer'
                subtitle='This user has not approved access to the your company. Invite this user one more time?'
                error={error}
              />
            </Popup>
          </div>
          <h1 className='page__title'>Engineers</h1>
        </div>
      </div>

      <Table
        onChangePage={setPage}
        per_page={perPage}
        page={page}
        changePerPageHandler={setPerPage}
        data={engineers}
        columns={tableHeaders.engineers}
        inProcess={inProcess}
        count={count}
        onClickHandler={onRowClickHandler}
      />

      <Popup modal open={deletePopupShow} closeOnDocumentClick>
        <div className='popup__block popup__engineerdelete'>
          <PopupLogo />
          <div className='popup__title'>Delete Engineer</div>
          <div className='formPopup'>
            <div className='formPopup__row formPopup__row--btns'>
              <button type='button' className='btns btn-i btn-red btn-delete' onClick={() => deleteEngineerHandler()}>
                Delete
              </button>
              <button
                onClick={() => setDeletePopupShow(false)}
                type='button'
                className='btns btn-i btn-trns btn-cencel'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </StaticPage>
  )
}

Engineers.propTypes = {
  role: string,
  user: object,
  inProcess: bool,
  error: string,
  notificationData: object,
  count: number.isRequired,
  engineers: arrayOf(object),
  getEngeneers: func.isRequired,
  deleteEngineer: func.isRequired,
  addEngineer: func.isRequired,
  handleSubmit: func.isRequired,
  setFieldVal: func
}

const mapStateToProps = ({ auth, engeneers }) => {
  const data = {
    count: engeneers.count,
    engineers: engeneers.engeneers.data,
    inProcess: engeneers.inProcess
  }

  const { user } = auth
  if (user) data.user = user
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getEngeneers: (params) => dispatch(getEngeneersAction(params)),
  addEngineer: (data) => dispatch(createEngineerAction(data)),
  deleteEngineer: (params) => dispatch(deleteEngineerByIdAction(params)),
  setFieldVal: (field, val) => dispatch(change('inviteEngineer', field, val))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'inviteEngineer',
    enableReinitialize: true,
    validate
  })
)(Engineers)
