import {
  ROUTE_TO_CUSTOMERS,
  ROUTE_TO_JOBS,
  ROUTE_TO_ENGINEERS,
  ROUTE_TO_EMPLOYERS,
  ROUTE_TO_ROOT,
  ROUTE_TO_CONTACT,
  ROUTE_TO_SURVEYS,
  ROUTE_TO_USERS,
  ROUTE_TO_SYSTEM_SETTINGS,
  ROUTE_TO_ADMINS
} from './routes'

export default {
  employer: [
    {
      title: 'Dashboard',
      route: ROUTE_TO_ROOT,
      icon: 'icon-dashboard'
    },
    {
      title: 'Customers',
      route: ROUTE_TO_CUSTOMERS,
      icon: 'icon-users_2'
    },
    {
      title: 'Jobs',
      route: ROUTE_TO_JOBS,
      icon: 'icon-jobs'
    },
    {
      title: 'Surveys',
      route: ROUTE_TO_SURVEYS,
      icon: 'icon-surveys'
    },
    {
      title: 'Engineers',
      route: ROUTE_TO_ENGINEERS,
      icon: 'icon-user'
    },
    {
      title: 'Contact Us',
      route: ROUTE_TO_CONTACT,
      icon: 'icon-contact'
    }
  ],
  engineer: [
    {
      title: 'Dashboard',
      route: ROUTE_TO_ROOT,
      icon: 'icon-dashboard'
    },
    {
      title: 'Customers',
      route: ROUTE_TO_CUSTOMERS,
      icon: 'icon-users_2'
    },
    {
      title: 'Jobs',
      route: ROUTE_TO_JOBS,
      icon: 'icon-jobs'
    },
    {
      title: 'Surveys',
      route: ROUTE_TO_SURVEYS,
      icon: 'icon-surveys'
    },
    {
      title: 'Employers',
      route: ROUTE_TO_EMPLOYERS,
      icon: 'icon-user'
    },
    {
      title: 'Contact Us',
      route: ROUTE_TO_CONTACT,
      icon: 'icon-contact'
    }
  ],
  super_admin: [
    {
      title: 'Dashboard',
      route: ROUTE_TO_ROOT,
      icon: 'icon-dashboard'
    },
    {
      title: 'Admins',
      route: ROUTE_TO_ADMINS,
      icon: 'icon-admins'
    },
    {
      title: 'Users',
      route: ROUTE_TO_USERS,
      icon: 'icon-user'
    },
    {
      title: 'System Settings',
      route: ROUTE_TO_SYSTEM_SETTINGS,
      icon: 'icon-system'
    }
  ],
  admin: [
    {
      title: 'Dashboard',
      route: ROUTE_TO_ROOT,
      icon: 'icon-dashboard'
    },
    {
      title: 'Users',
      route: ROUTE_TO_USERS,
      icon: 'icon-user'
    }
  ]
}
