import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { string, func, objectOf, any } from 'prop-types'
import DashboardGrid from '../DashboardGrid'
import { getDashboardDataAction } from '../actions'

const EngineerDashboard = ({ name, getData, dashboardData }) => {
  useEffect(() => {
    getData()
  }, [])

  const ownDashboard =
    dashboardData.dashboard && dashboardData.dashboard.data.attributes ? dashboardData.dashboard.data.attributes : null

  const employersDashboards =
    dashboardData.user_employers && dashboardData.user_employers.data ? dashboardData.user_employers.data : null

  //const handleSubmit=()=>{}
  //const { handleSubmit, pristine, reset, submitting } = this.props;

  return (
    <>

    <div className='page__header clearfix'>
      <h1 className='page__title'>
        <strong>Hello</strong>, Welcome Back {name}!
      </h1>
    </div>

    {/* Filter date
      <form className='formDefault fullWidth'>
        <div className='formDefault__body'>
          <div className='formDefault__row'>
            <Field
              component={DatePicker}
              label='From Date'
              className='formDefault__input'
              defaultValue=''
              name='from_date'
              errorNoSpace
            />
          </div>

          <div className='formDefault__row'>
            <Field
              component={DatePicker}
              label='To Date'
              className='formDefault__input'
              defaultValue=''
              name='to_date'
              errorNoSpace
            />
          </div>
        </div>
      </form>
    */}

    <section className='section__overview--title'>Statistics of my work</section>

    {ownDashboard ? <DashboardGrid data={ownDashboard} /> : null}
    {employersDashboards
      ? employersDashboards.map((item, index) => {
          return (
            <>
              <section key={index} className='section__overview--title'>Statistics of work with {item.attributes.name}</section>
              <DashboardGrid key={index} data={item.attributes} />
            </>
          )
        })
      : null}
    </>
  )
}

EngineerDashboard.propTypes = {
  name: string,
  getData: func.isRequired,
  dashboardData: objectOf(any),
  handleSubmit: func.isRequired
}

const mapStateToProps = ({ auth, dashboard }) => {
  const data = {
    dashboardData: dashboard.statistic
  }

  const { user } = auth
  if (user) data.name = user.name
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getDashboardDataAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(EngineerDashboard)
