import React, { useEffect, useState } from 'react'
import { object, func, objectOf, any, bool } from 'prop-types'
import { connect } from 'react-redux'
import StaticPage from '../../components/StaticPage'
import navData from '../../constants/navData'

import SiteHeader from '../../components/SiteHeader'
import BreadCrumbs from '../../components/BreadCrumbs'
import ProfileMenu from '../../components/ProfileMenu'
import Tabs from '../../components/Tabs'
import { getPrivacyAction, getTermsAction, setPrivacyAction, setTermsAction } from './actions'
import { ROUTE_TO_ROOT } from '../../constants/routes'
import ChangeSettingsForm from './Form'
import Spinner from '../../components/Spinner'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const SystemSettings = ({
  currentUserData,
  notificationData,
  getPrivacy,
  getTerms,
  setPrivacy,
  setTerms,
  terms,
  privacy,
  getPrivacyInProgress,
  getTermsInProgress,
  user
}) => {
  const getData = () => {
    getPrivacy()
    getTerms()
  }
  useEffect(() => {
    getData()
  }, [])

  const [termsVal, setTermsVal] = useState({})
  const [privacyVal, setPrivacyVal] = useState({})

  useEffect(() => {
    setPrivacyVal(privacy)
  }, [privacy])

  useEffect(() => {
    setTermsVal(terms)
  }, [terms])

  if (!currentUserData.user) return false
  const { successNotification, errorNotification } = notificationData
  const currentUser = currentUserData.user
  const ROLE = currentUser.role

  // Relate required header parts
  const Header = () => {
    return (
      <SiteHeader>
        <BreadCrumbs data={breadCrumbsData} current='System Settings' />
        <ProfileMenu />
      </SiteHeader>
    )
  }

  const privacySubmitHandler = async () => {
    try {
      const res = await setPrivacy({
        privacy_policy: {
          body: privacyVal
        }
      })
      if (res.value.status === 200) {
        successNotification('Privacy was updated')
      } else {
        errorNotification('Something went wrong.')
      }
    } catch (e) {
      console.error(e)
    }
  }

  const termsSubmitHandler = async () => {
    try {
      const res = await setTerms({
        terms: {
          body: termsVal
        }
      })
      if (res.value.status === 200) {
        successNotification('Terms was updated')
      } else {
        errorNotification('Something went wrong.')
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <StaticPage sidebarData={navData[ROLE]} header={<Header />}>
      <Tabs>
        <div className='tabs__content ui-tabs-panel ui-corner-bottom ui-widget-content' label='Privacy policy'>
          {!getPrivacyInProgress ? (
            <ChangeSettingsForm
              value={privacyVal}
              title='Privacy Policy'
              name='privacy'
              handleChange={setPrivacyVal}
              handleSubmit={privacySubmitHandler}
            />
          ) : (
            <Spinner />
          )}
        </div>
        <div label='Terms & Conditions'>
          {!getTermsInProgress ? (
            <ChangeSettingsForm
              value={termsVal}
              title='Terms & Conditions'
              name='terms'
              handleChange={setTermsVal}
              handleSubmit={termsSubmitHandler}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </Tabs>
    </StaticPage>
  )
}
const mapStateToProps = ({ auth, systemSettings }) => {
  const data = {
    ...systemSettings
  }

  const { user } = auth
  if (user) data.user = user
  if (user) data.role = user.role

  return data
}
const mapDispatchToProps = (dispatch) => ({
  getTerms: () => dispatch(getTermsAction()),
  getPrivacy: () => dispatch(getPrivacyAction()),
  setTerms: (data) => dispatch(setTermsAction(data)),
  setPrivacy: (data) => dispatch(setPrivacyAction(data))
})
SystemSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentUserData: object,
  // eslint-disable-next-line react/forbid-prop-types
  notificationData: object,
  getTerms: func.isRequired,
  getPrivacy: func.isRequired,
  setPrivacy: func.isRequired,
  setTerms: func.isRequired,
  terms: objectOf(any),
  privacy: objectOf(any),
  getPrivacyInProgress: bool,
  getTermsInProgress: bool,
  user: objectOf(any)
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemSettings)
