import typeToReducer from 'type-to-reducer'
import { FORGOT_PASSWORD, LOGIN, LOGOUT, RECOVERY_PASSWORD, GET_CURRENT_USER } from './constants';

export const initialState = {
  inProcess: false,
  user: null,
  token: null,
  errors: null
}

export default typeToReducer(
  {
    [LOGIN]: {
      START: (state, action) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          user: action.payload.data.user,
          token: action.payload.data.token,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [LOGOUT]: {
      START: (state, action) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          user: null,
          token: null,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [FORGOT_PASSWORD]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [RECOVERY_PASSWORD]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [GET_CURRENT_USER]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          user: action.payload.data.user.data ? action.payload.data.user.data.attributes : {},
          inProcess: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
  },
  initialState
)
