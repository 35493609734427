import React, { useState } from 'react'
import { bool, number, shape, string } from 'prop-types'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import UpdateEPCForm from './UpdateEPCForm'

const EPCMetric = ({ survey, epcId, epcMetrics, error, showEpcEdit }) => {
  console.log("EPC props", showEpcEdit)
  const [showEpcDetails, setShowEpcDetails] = useState(false)
  const [showEpcImages, setShowEpcImages] = useState(false)
  console.log("EPC METRIC", showEpcEdit, showEpcDetails)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  let epcImages = []

  const renderEpcImages = (epcMetrics) => {
    epcMetrics && epcMetrics.map((metric, index) => {
      return (
        (metric['photos'] && metric['photos'].length !== 0) ? (
          handleMultipleImages(metric)
        ) : null
      )
    })
    console.log("EPC Images", epcImages)
  }

  const handleMultipleImages = (metric) => {
    (metric['photos'] && metric['photos'].length > 1) ? (
      metric['photos'].map((photo, index) => {
        epcImages.push({
          'name': metric['name'],
          'url': photo['remote_url']
        })
        return epcImages
      })
    ) : (
      epcImages.push({
        'name': metric['name'],
        'url': metric['photos'][0]['remote_url']
      })
    )
  }

  return (
    <div className='audits__list--item p1'>
      <div className='audits__list--header'>
        <button className='btns btn-i btn-audit p-tb1-lr0' type='button' onClick={() => setShowEpcDetails(!showEpcDetails)}>
          EPC Details
          <i className={`icons i24x24 ${showEpcDetails ? 'arrow-up_yellow' : 'arrow-down_yellow'}`} />
        </button>
      </div>

      { showEpcDetails ? (
        <div className='audits__list--body p1-5'>
          <div className='formDefault__body--columns clearfix'>
            <div className='formDefault__body--column'>
              { error ?
                  <p className='validation-error-message'>{error}</p>
                : null
              }

              { showEpcEdit ?
                  <UpdateEPCForm
                    survey={survey}
                    epcId={epcId}
                    epcMetrics={epcMetrics}
                  />
                :
                  epcMetrics && epcMetrics.map((metric, index) => {
                    return (
                      <div key={index} id={metric["name"]} className='formDefault__row'>
                        <label className= 'formDefault__text'>
                          {metric["name"]} : <b>{metric["answer"]}</b>
                        </label>
                      </div>
                    )
                  })
              }
            </div>
          </div>
        </div>
      ) : null}

      <div className='audits__list--header'>
        <button className='btns btn-i btn-audit p-tb1-lr0' type='button' onClick={() => setShowEpcImages(!showEpcImages)}>
          EPC Images
          <i className={`icons i24x24 ${showEpcImages ? 'arrow-up_yellow' : 'arrow-down_yellow'}`} />
        </button>
      </div>

      {renderEpcImages(epcMetrics)}

      { showEpcImages ? (
        (epcImages && epcImages.length !== 0) ? (
          <div>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={"desktop" || this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {epcImages.map((metric, index)=>{
                return(
                  <div key={index} id={`epc-img-${epcId}-${index}`} className='epc-img'>
                    <div className='epc-img--title'>
                      <b>{metric['name']}</b>
                    </div>
                    <img className='epc-img--thumb' src={metric['url']} alt={metric['name']} />
                  </div>
                )
              })}
            </Carousel>
          </div>
        )
        : (
            <div className='epc-img--title'>
              <b>No EPC Images</b>
            </div>
          )
      ) : null}
    </div>
  )
}

EPCMetric.propTypes = {
  EPCMetricNo: number.isRequired,
  EPCMetric: shape({
    is_completed: bool.isRequired,
    created_at: string.isRequired,
    user_name: string.isRequired
  })
}

export default EPCMetric
