import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import React, { useEffect } from 'react'
import { objectOf, bool, any, func, string } from 'prop-types'
import {
  errorNotificationAction,
  successNotificationAction
} from '../../Notifications/actions'
import {
  getEngineerByIdAction,
  updateEngineerAction
} from '../actions'
import {
  ROUTE_TO_ENGINEERS,
  ROUTE_TO_ROOT
} from '../../../constants/routes'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import navData from '../../../constants/navData'
import EngineerForm from './EngineerForm'
import validate from './validate'
import { useNavigate } from 'react-router-dom-v5-compat'
import handleSubmit from 'redux-form/lib/handleSubmit'

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Engineers',
    link: ROUTE_TO_ENGINEERS
  }
]

const Header = ({ engineerName }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={engineerName} />
      <ProfileMenu />
    </SiteHeader>
  )
}

Header.propTypes = {
  engineerName: string.isRequired
}

const EngineerUpdate = ({
  match,
  engineer,
  getEngineer,
  updateEngineer,
  successNotification,
  errorNotification,
  role,
  updateInProcess,
  initialValues,
  currentUser,
  error,
  ...formProps
}) => {
  const navigate = useNavigate()
  const { id } = match.params

  useEffect(() => {
    getEngineer(id)
  }, [currentUser])

  const onSubmit = async({name, address, post_code, phone_number, email, contact_email}) => {
    console.log("INDEX ON SUBMIT")
    const data = {
      engineer: {
        name,
        address,
        post_code,
        phone_number,
        email,
        contact_email
      }
    }

    id ? await updateEngineer(id, data) : successNotification('In process')
    successNotification(`Engineer successfully ${id ? 'updated' : 'WIP'}`)
    navigate(ROUTE_TO_ENGINEERS)
  }

  console.log("ENGINEER UPDATE INDEX",  !!id)
  return (
    <StaticPage
      sidebarData={navData[role]}
      header={<Header engineerName={engineer.name} />}
    >
      <div className='survey__page pToppBottom'>
        <section className='survey__page--section section__survey section__border survey'>
          <EngineerForm
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            {...formProps}
            initialValues={initialValues}
            engineer={engineer}
            isEdit={!!id}
            error={error}
          />
        </section>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({
  getEngineer,
  engeneers,
  auth,
  form,
}) => {

  const {
    engineer: {
      name,
      address,
      post_code,
      phone_number,
      email,
      contact_email,
      added,
      logined_at,
      surveys_count,
      is_active
    },
    inProcess,
    updateInProcess
  } = engeneers

  const initialValues = {
    name,
    address,
    post_code,
    phone_number,
    email,
    contact_email,
    added,
    logined_at,
    surveys_count,
    is_active
  }

  return {
    engineer: engeneers.engineer,
    initialValues: initialValues,
    role: auth.user && auth.user.role,
    currentUser: auth.user
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEngineer: (id) => dispatch(getEngineerByIdAction(id)),
  updateEngineer: (id, data) => dispatch(updateEngineerAction(id, data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
})

EngineerUpdate.propTypes = {
  match: objectOf(any),
  currentUser: objectOf(any),
  initialValues: objectOf(any),
  getEngineer: func.isRequired,
  updateEngineer: func,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  inProcess: bool.isRequired,
  role: string,
  updateInProcess: bool,
  error: string
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'engineer_update',
    //shouldError: () => true,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate
  })
)(EngineerUpdate)
