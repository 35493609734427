import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { string, number, bool, func, arrayOf, object } from 'prop-types'
import {
  ROUTE_TO_ROOT,
  routeToCustomerSingle,
  routeToCustomerUpdate
} from '../../../constants/routes'
import HeaderSearch from '../../../components/HeaderSearch'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import tableHeaders from '../../../utils/tableHeaders'
import navData from '../../../constants/navData'
import { getCustomersAction } from '../actions'
import Table from '../../../components/Table'
import { getEmployersAction } from '../../Employers/actions'
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  }
]

const Header = ({ onSearch, currentUser }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current='Customers' />
      <HeaderSearch onSubmit={onSearch} />
      <ProfileMenu currentUser={currentUser} />
    </SiteHeader>
  )
}

Header.propTypes = {
  onSearch: func.isRequired,
  currentUser: object
}

const Customers = ({ role, customers, inProcess, getCustomers, employers, getEmployers, count, user }) => {
  const navigate = useNavigate()
  const [activeEmployer, setActiveEmployer] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    getCustomers({ page, per_page: perPage })
  }, [page, perPage])

  useEffect(() => {
    getEmployers()
  }, [])

  const onEmployerChange = (v) => {
    // Must set special filter 'self' because backend create different filters
    if (v === 'own') {
      getCustomers({ self: true })
    } else {
      getCustomers({ employer_id: v })
    }
    setActiveEmployer(v)
  }

  // const onRowClickHandler = ({ id }) => history.push(routeToCustomerSingle(id))
  const onRowClickHandler = ({ id }) => navigate(routeToCustomerSingle(id))

  // eslint-disable-next-line consistent-return
  const renderColumns = () => {
    if (role === 'employer') return tableHeaders.customers[role]
    if (role === 'engineer') {
      return tableHeaders.customers[role](onEmployerChange, activeEmployer, employers)
    }
  }

  return (
    <StaticPage sidebarData={navData[role]} header={<Header onSearch={getCustomers} currentUser={user} />}>
      <div className='customers__page pTop'>
        <div className='page__header clearfix'>
          <div className='page__btns'>
            <Link className='btns btn-i btn-yellow' to={routeToCustomerUpdate()}>
              ADD NEW Customer
            </Link>
          </div>
          <h1 className='page__title'>Customers</h1>
        </div>
      </div>
      <Table
        onChangePage={setPage}
        per_page={perPage}
        page={page}
        changePerPageHandler={setPerPage}
        data={customers}
        columns={renderColumns()}
        inProcess={inProcess}
        count={count}
        onClickHandler={onRowClickHandler}
      />
    </StaticPage>
  )
}

Customers.propTypes = {
  role: string,
  count: number.isRequired,
  inProcess: bool,
  customers: arrayOf(object),
  employers: arrayOf(object),
  getCustomers: func.isRequired,
  getEmployers: func.isRequired,
  user: object
}

const mapStateToProps = ({ auth, customers, employers }) => {
  const data = {
    customers: customers.customers,
    count: customers.count,
    employers: employers.employers && employers.employers.data,
    inProcess: customers.inProcess,
    user: auth
  }

  const { user } = auth
  if (user) data.role = user.role

  return data
}

const mapDispatchToProps = (dispatch) => ({
  getCustomers: (params) => dispatch(getCustomersAction(params)),
  getEmployers: (params) => dispatch(getEmployersAction(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Customers)
