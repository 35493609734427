import React from 'react'
import { Redirect } from 'react-router-dom'
import { string, elementType, bool, func } from 'prop-types'
import { ROUTE_TO_LOGIN } from '../constants/routes'
import { getToken } from '../utils/tokenCRUD'
import { CompatRoute } from "react-router-dom-v5-compat"

function PrivateRoute({ component, render, path, exact }) {
  const accessAllowed = getToken()

  if (!accessAllowed) {
    return <Redirect to={ROUTE_TO_LOGIN} />
  }

  return component ? (
    <CompatRoute path={path} component={component} />
  ) : (
    <CompatRoute path={path} render={render} />
  )
}

PrivateRoute.propTypes = {
  component: elementType,
  render: func,
  path: string.isRequired,
  exact: bool
}

export default PrivateRoute
