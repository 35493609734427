import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { bool, func, string } from 'prop-types'
import { successNotificationAction } from '../../Notifications/actions'
import AccountFormWrapper from '../../../components/AccountFormWrapper'
import { ROUTE_TO_ROOT, ROUTE_TO_PROFILE } from '../../../constants/routes'
import { setUser } from '../../../utils/currentUserCRUD'
import { setToken } from '../../../utils/tokenCRUD'
import { loginAction } from '../actions'
import LoginForm from './LoginForm'
import validate from './validate'
import { useNavigate } from "react-router-dom-v5-compat";

const Login = ({ handleSubmit, onSubmit, submitting, error }) => {
  const navigate = useNavigate()

  const onFormSubmit = async ({ rememberMe, ...values }) => {
    const response = await onSubmit({
      user: { email: values.email.trim(), password: values.password.trim() }
    })
    const { token, user } = response.value.data

    if (token) setToken(token, rememberMe)
    if (user) {
      setUser(user.id, rememberMe)
      if (!user.name) {
        // history.push(ROUTE_TO_PROFILE)
        navigate(ROUTE_TO_PROFILE)
        return false
      }
    }
    //history.push(ROUTE_TO_ROOT)
    navigate(ROUTE_TO_ROOT)
  }

  return (
    <AccountFormWrapper>
      <div className='signin__page--title'>Log into Your Account</div>
      <LoginForm
        submitting={submitting}
        handleSubmit={handleSubmit}
        onFormSubmit={onFormSubmit}
        error={error}
      />
    </AccountFormWrapper>
  )
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data) => dispatch(loginAction(data)),
  successNotification: (message) => dispatch(successNotificationAction(message))
})

Login.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  submitting: bool.isRequired,
  error: string
}

export default compose(
  reduxForm({
    form: 'login',
    validate
  }),
  connect(null, mapDispatchToProps)
)(Login)
