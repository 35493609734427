import React from 'react'
import { Field } from 'redux-form'
import { bool, string, func } from 'prop-types'
// import { Link } from 'react-router-dom'
import { Link } from "react-router-dom-v5-compat"
import { ROUTE_TO_FORGOT_PASSWORD, ROUTE_TO_CREATE_ACCOUNT } from '../../../../constants/routes'
import ReduxFormField from '../../../../components/ReduxFormField'

const LoginForm = ({ submitting, handleSubmit, onFormSubmit, error }) => {
  return (
    <form className='signin__page--form' onSubmit={handleSubmit(onFormSubmit)}>
      <div className='form__row form__validated-row row__input'>
        <Field
          component={ReduxFormField}
          label='*Username or Email'
          className='form__input'
          type='text'
          name='email'
          id='email'
          placeholder='Email'
        />
      </div>
      <div className='form__row form__validated-row row__input'>
        <Field
          component={ReduxFormField}
          className='form__input'
          type='password'
          name='password'
          id='password'
          label='*Password'
          placeholder='************'
        />
      </div>
      <div className='form__row row__mix clearfix mt-20'>
        <Field component='input' className='form__checkbox' type='checkbox' name='rememberMe' id='rememberMe' />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className='form__label--checkbox' htmlFor='rememberMe'>
          Remember me
        </label>
        <Link to={ROUTE_TO_FORGOT_PASSWORD} className='form__link btns btn-i'>
          Forgot Password?
        </Link>
      </div>
      <p className='validation-error-message'>{error}</p>
      <div className='form__row row__submit'>
        <button
          className={`form__button btns btn-i ${submitting ? 'btn-submitting' : ''}`}
          type='submit'
          name='submit'
          disabled={submitting}>
          {submitting ? 'Processing...' : 'Login'}
        </button>
      </div>
      <div className='form__row row__submit'>
        <Link className='create_acc_link' to={ROUTE_TO_CREATE_ACCOUNT}>
          {' '}
          Create account?{' '}
        </Link>
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  handleSubmit: func.isRequired,
  onFormSubmit: func.isRequired,
  submitting: bool.isRequired,
  error: string
}

export default LoginForm
