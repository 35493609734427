import ReactSpinner from 'react-spinner-material'
import React from 'react'

export default function Spinner() {
  return (
    <div className='spinner-wrapper'>
      <ReactSpinner radius={60} color='#969696' stroke={3} visible />
    </div>
  )
}
