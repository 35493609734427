import typeToReducer from 'type-to-reducer'
import {
  CREATE_ENGENEER,
  DELETE_ENGINEER_BY_ID,
  GET_ENGENEERS,
  GET_ENGINEER_BY_ID,
  UPDATE_ENGINEER
} from './constants'

export const initialState = {
  inProcess: false,
  deleteInProgress: false,
  updateInProgress: false,
  per_page: null,
  total_pages: null,
  count: 0,
  engeneers: [],
  engineer: {},
  errors: null
}

export default typeToReducer(
  {
    [GET_ENGENEERS]: {
      START: (state) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          engeneers: action.payload.data.engineers,
          inProcess: false,
          per_page: action.payload.data.per_page,
          total_pages: action.payload.data.total_pages,
          count: action.payload.data.count
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          inProcess: false
        }
      }
    },
    [DELETE_ENGINEER_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          deleteInProgress: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          deleteInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          deleteInProgress: false
        }
      }
    },
    [CREATE_ENGENEER]: {
      START: (state) => {
        return {
          ...state,
          updateInProgress: true
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          updateInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          updateInProgress: false
        }
      }
    },
    [GET_ENGINEER_BY_ID]: {
      START: (state, action) => {
        return {
          ...state,
          inProcess: true
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          inProcess: false,
          engineer: action.payload.data.engineer.data.attributes
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          engineer: {},
          inProcess: false
        }
      }
    },
    [UPDATE_ENGINEER]: {
      START: (state, action) => {
        return {
          ...state,
          updateInProgress: true
        }
      },
      SUCCESS: (state, action) => {
        console.log("UPDATE ENGINEER", state, action)
        return {
          ...state,
          updateInProgress: false
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: action.payload.errors,
          updateInProgress: false
        }
      }
    }
  },
  initialState
)
