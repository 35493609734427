import _ from 'lodash'

const blankFieldError = 'Field cannot be blank'
const passwordWrongLength = 'From 6 to 72 symbols'

export const isPasswordLengthCorrect = (password) => {
  const re = /^.{6,72}$/
  return re.test(password)
}

export default ({ password, password_confirmation }) => {
  const errors = {}

  const trimmedPassword = _.trim(password)
  if (!trimmedPassword) {
    errors.password = blankFieldError
  } else if (!isPasswordLengthCorrect(trimmedPassword)) {
    errors.password = passwordWrongLength
  }

  const trimmedPasswordConfirmed = _.trim(password_confirmation)
  if (!trimmedPasswordConfirmed) {
    errors.password_confirmation = blankFieldError
  } else if (!isPasswordLengthCorrect(trimmedPasswordConfirmed)) {
    errors.password_confirmation = passwordWrongLength
  }

  return errors
}
