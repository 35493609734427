import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { any, func, bool, objectOf, string } from 'prop-types'
import {
  ROUTE_TO_ROOT,
  ROUTE_TO_SURVEYS
} from '../../../constants/routes'
import {
  getSurveyByIdAction,
  deleteSurveyByIdAction
} from '../actions'
import BreadCrumbs from '../../../components/BreadCrumbs'
import ProfileMenu from '../../../components/ProfileMenu'
import StaticPage from '../../../components/StaticPage'
import SiteHeader from '../../../components/SiteHeader'
import navData from '../../../constants/navData'
import Tabs from '../../../components/Tabs'
import { useNavigate } from "react-router-dom-v5-compat"

const breadCrumbsData = [
  {
    title: 'Dashboard',
    link: ROUTE_TO_ROOT
  },
  {
    title: 'Surveys',
    link: ROUTE_TO_SURVEYS
  }
];

const Header = ({ role }) => {
  return (
    <SiteHeader>
      <BreadCrumbs data={breadCrumbsData} current={role}/>
      <ProfileMenu />
    </SiteHeader>
  );
};

Header.propTypes = {
  role: string
};

const SurveySingle = ({ survey, inProgress, deleteInProgress, getSurvey, deleteSurvey, role, match }) => {
  const navigate = useNavigate()
  const { id } = match.params

  useEffect(() => {
    getSurvey(id)
  }, [])

  const onDeleteHandler = async () => {
    try {
      await deleteSurvey(id)
      navigate(ROUTE_TO_SURVEYS)
    } catch (e) {
      console.log(e)
    }
  }

  if (!survey.id) return (<div/>)

  const tabsTitle = [
    {id: survey.id, props: { component: 'Survey', label:'Info', value: '1'}},
    {id: survey.id, props: { component: 'Survey', label:'Thermal', value: '2'}},
    {id: survey.id, props: { component: 'Survey', label:'EPC', value: '3'}},
    {id: survey.id, props: { component: 'Survey', label:'Water Test', value: '4'}}
  ]

  return (
    <StaticPage sidebarData={navData[role]} header={<Header role={role}/>}>
      <div className="survey__page pToppBottom">
        <Tabs children={tabsTitle} />
      </div>
    </StaticPage>
  );
};

SurveySingle.propTypes = {
  survey: objectOf(any),
  inProgress: bool,
  deleteInProgress: bool,
  role: string,
  getSurvey: func.isRequired,
  deleteSurvey: func.isRequired,
  match: objectOf(any)
};

const mapStateToProps = ({ surveys, auth }) => {
  return {
    survey: surveys.survey,
    inProgress: surveys.inProgress,
    deleteInProgress: surveys.deleteInProgress,
    role: auth.user && auth.user.role
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSurvey: (id) => dispatch(getSurveyByIdAction(id)),
  deleteSurvey: (id) => dispatch(deleteSurveyByIdAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveySingle);
