import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bool, func, string } from 'prop-types'

import { successNotificationAction } from '../../Notifications/actions'
import AccountFormWrapper from '../../../components/AccountFormWrapper'
import ReduxFormField from '../../../components/ReduxFormField'
import { ROUTE_TO_LOGIN } from '../../../constants/routes'
import { forgotPasswordAction } from '../actions'
import validate from './validate'
import { useNavigate } from "react-router-dom-v5-compat";

const ForgotPassword = ({ handleSubmit, onSubmit, submitting, error, successNotification }) => {
  const navigate = useNavigate()

  const onFormSubmit = async ({ forgot_email }) => {
    await onSubmit({ email: forgot_email.trim() })
    successNotification('We sent you an email with details on how to create a new password')
    // history.push(ROUTE_TO_LOGIN)
    navigate(ROUTE_TO_LOGIN)
  }

  return (
    <AccountFormWrapper>
      <div className='signin__page--title'>Log into Your Account</div>
      <div className='signin__page--subtitle'>
        Enter your email address that you used to register. We will send you an email with a link to reset your password
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)} className='signin__page--form'>
        <div className='form__row form__validated-row row__input'>
          <Field
            component={ReduxFormField}
            className='form__input'
            type='email'
            name='forgot_email'
            id='forgot_email'
            label='Email'
            placeholder='Email'
          />
        </div>
        <p className='validation-error-message'>{error}</p>
        <div className='form__row row__submit'>
          <button
            className={`form__button btns btn-i ${submitting ? 'btn-submitting' : ''}`}
            type='submit'
            name='submit'
            disabled={submitting}>
            {submitting ? 'Processing...' : 'Submit'}
          </button>
        </div>
      </form>
    </AccountFormWrapper>
  )
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (email) => dispatch(forgotPasswordAction(email)),
  successNotification: (message) => dispatch(successNotificationAction(message))
})

ForgotPassword.propTypes = {
  handleSubmit: func.isRequired,
  onSubmit: func.isRequired,
  successNotification: func.isRequired,
  submitting: bool.isRequired,
  error: string
}

export default compose(
  reduxForm({
    form: 'forgot_password',
    validate
  }),
  connect(null, mapDispatchToProps)
)(ForgotPassword)
